const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('connEditProfileController', [
  '$scope',
  '$state',
  'auth',
  function connEditProfileController(
    $scope,
    $state,
    auth
  ) {
    $scope.titleCanBeEdited = () => _.get($scope, 'versions.list', []).length === 1;
    $scope.promise = $scope.conn.$promise.then(() => ($scope.connTitle = $scope.conn.title));

    $scope.changeTitle = (title) => {
      if (_.isString(title) && _.isString($scope.connTitle)) {
        if ($scope.connTitle.charAt(0) === title.charAt(0)) {
          $scope.connTitle = title;
          $scope.conn.title = title;
          $scope.connSaver();
        }

        $scope.connTitle = title;
        $scope.conn.title = title;
      }
    };
    $scope.user = auth.user;
  },
]);
