const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('toolbarController', [
  '$scope',
  '$state',
  'auth',
  'preview',
  function toolbarController(
    $scope,
    $state,
    auth,
    preview
  ) {
    $scope.redirectTo = (to) => $state.go(to);

    if (auth.user.isVendor) {
      $scope.state = 'isv.connector.list';
    } else {
      $scope.state = 'provider.connector.list';
    }

    $scope.showToolbar = !$state.current.blankView;

    $scope.isPreviewMode = () => preview.isPreviewMode();

    $scope.$on('$stateChangeSuccess', () => {
      $scope.showToolbar = !$state.current.blankView;
    });

    $scope.user = auth.user;
  },
]);
