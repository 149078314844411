const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('strongPassword', [
  function strongPassword() {
    return {
      require: 'ngModel',
      link(scope, elm, attrs, ctrl) {
        ctrl.$parsers.push((value) => {
          if (
            value.search(/[A-Z]/) < 0 ||
            value.search(/[0-9]/) < 0 ||
            value.search(/[~!@#$%^&*?]/) < 0
          ) {
            ctrl.$setValidity('strongPassword', false);
            return value;
          }

          ctrl.$setValidity('strongPassword', true);
          return value;
        });
      },
    };
  },
]);
