const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('companyListController', [
  '$scope',
  '$mdDialog',
  '$state',
  '$q',
  'auth',
  function companyListController(
    $scope,
    $mdDialog,
    $state,
    $q,
    auth
  ) {
    $scope.companies = [];
    $scope.user = auth.user;
    $scope.processing = false;

    $scope.promise = auth.fetchCompanies().then(() => {
      $scope.companies = auth.user.companies;

      if (auth.user.companies.length === 1) {
        return $state.go('company.details', { companyId: auth.user.companies[0].id });
      }

      return $q.when();
    });

    $scope.companyDetails = (company) => {
      $state.go('company.details', { companyId: company.id });
    };

    $scope.getCompanyLogoLabel = (company) => company.title.substring(0, 1).toUpperCase() +
        company.title.substring(1, 2).toLowerCase();

    $scope.startAddCompany = ($event) => {
      if ($scope.processing) {
        return;
      }

      $scope.processing = true;

      $mdDialog
        .show({
          clickOutsideToClose: true,
          targetEvent: $event,
          onRemoving: () => {
            auth.fetchCompanies().then(() => {
              $scope.companies = auth.user.companies;
            });
          },
          onComplete: () => {
            $scope.processing = false;
          },
          controller: 'companyCreateDialogController',
          templateUrl: 'dist/templates/dialogs/createCompany.html',
        });
    };
  },
]);
