const angular = require('angular');


const app = angular.module('sweeft-spa');


app.config([
  '$resourceProvider', '$httpProvider',
  ($resourceProvider, $httpProvider) => {
    $resourceProvider.defaults.stripTrailingSlashes = false;
    $httpProvider.defaults.withCredentials = true;
  },
]);
