const _ = require('lodash');
const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('apiUrl', [
  '$q',
  'errorMessages',
  'asyncValidatorFactory',
  function apiUrlValidation(
    $q,
    errorMessages,
    asyncValidatorFactory
  ) {
    const checkUrl = /^(https:\/\/)(www\.)?([\w-\.]{2,})(\.[a-z]{2,}\/?)(\/[\S]+)?$/i;

    return asyncValidatorFactory({
      name: 'apiUrlValidation',
      scope: {
        conn: '=sweeftApp',
        messageId: '=',
      },
      validate(url, scope) {
        return $q((resolve, reject) =>
          scope.conn.$promise.then((connector) => {
            if (!checkUrl.test(url)) {
              return reject(new Error('URL is not valid'));
            }
            if (connector.master) {
              return connector.checkApiUrl(url)
                .then(() => resolve())
                .catch((data) => {
                  const resData = _.get(data, 'data', {});
                  const errorMessage = resData.error || resData.message;

                  if (errorMessage) {
                    return reject(new Error(errorMessage));
                  }

                  return reject(new Error('Url validation failed due to server error'));
                });
            }

            return reject();
          }))
          .catch((err) => {
            const error = err || new Error('Something went wrong during url validation');

            if (scope.messageId) {
              errorMessages.add(scope.messageId, error.message);
            }

            return $q.reject(error);
          });
      },
    });
  },
]);
