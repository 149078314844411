const angular = require('angular');
const _ = require('lodash');
const moment = require('moment');


const app = angular.module('sweeft-spa');


app.factory('Localization', [
  'config',
  'baseModel',
  function LocalizationModel(
    config,
    Base
  ) {
    class Localization extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/ui-localization/:id';

        this.actions = _.extend({}, this.actions, {
          check: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/ui-localization/check-locale`,
          },

          makeKeys: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/ui-localization/:id/make-keys`,
          },

          upload: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/ui-localization/upload`,
          },

          download: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/ui-localization/:id/download`,
            responseType: 'text',
            transformResponse: (data) => ({ blob: [data] }),
          },
        });

        return class extends this.factory() {
          init() {
            this.makeReadableUpdated();
          }

          makeReadableUpdated() {
            this.updatedReadable = '';
            if (_.isString(this.updated) && this.updated.length > 0) {
              this.updatedReadable = moment.utc(this.updated).local().fromNow();
            }
            return this.updatedReadable;
          }

          setUpdatedTime(newUpdatedTime) {
            this.updated = newUpdatedTime;
            this.makeReadableUpdated();
          }
        };
      }
    }

    return new Localization();
  },
]);
