const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditProvidersControllerList', [
  '$scope',
  '$state',
  '$stateParams',
  '$mdToast',
  '$document',
  '$q',
  'Connector',
  'Invitation',
  function connEditProvidersControllerList(
    $scope,
    $state,
    $stateParams,
    $mdToast,
    $document,
    $q,
    Connector,
    Invitation
  ) {
    const sandbox = $scope.getSandboxStatus();
    const versions = Connector.getVersions({ connId: $stateParams.connId });
    const invitations = Invitation.query({ app: $stateParams.connId });

    sandbox.then($scope.checkSandboxStatus);

    $scope.promise = $q.all([sandbox, versions.$promise, invitations.$promise]);
    $scope.invitations = invitations;
    $scope.versions = versions;

    $scope.showInvitationDetails = (invitation) => {
      $state.go('isv.connector.edit.providers.edit', { invitationId: invitation.id });
    };
  },
]);
