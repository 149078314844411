const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');

app.factory('Invitation', [
  '$resource',
  '$http',
  'config',
  'baseModel',
  function InvitationModel(
    $resource,
    $http,
    config,
    Base
  ) {
    function serialize(obj) {
      return _.pick(obj, [
        'app',
        'company_name',
        'message',
      ]);
    }

    const stringify = _.ary(JSON.stringify, 1);

    class Invitation extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/invitation/:id/';

        this.paramDefaults = {
          id: '@id',
        };

        this.actions = _.extend({}, this.actions, {
          verify: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/invitation/verify/`,
            transformRequest: [
              function acceptToJSON(code) {
                return JSON.stringify({ invitation_code: code });
              },
            ],
            transformResponse: [
              function fromJSON(data, headers, status) {
                if (status === 404) {
                  return data;
                }

                return JSON.parse(data);
              }],
          },
          save: {
            method: 'POST',
            transformRequest: [serialize, stringify],
          },
          update: {
            method: 'PUT',
            transformRequest: [serialize, stringify],
          },
        });

        return class extends this.factory() {
          init() {
            this.$statuses = {
              INVITED: 0,
              ACCEPTED: 1,
            };

            this.$textStatuses = {
              [this.$statuses.INVITED]: 'Invited',
              [this.$statuses.ACCEPTED]: 'Accepted',
            };
          }

          getStatus() {
            return this.$textStatuses[this.status];
          }

          isInvited() {
            return this.status === this.$statuses.INVITED;
          }

          isAccepted() {
            return this.status === this.$statuses.ACCEPTED;
          }

          assign(uri, invitationCode) {
            return $http({
              method: 'POST',
              url: `${config.apiBaseUrl}/api/v1/invitation/${this.id}/activate/`,
              transformRequest: [
                function acceptToJSON() {
                  return JSON.stringify({ company: uri, invitation_code: invitationCode });
                },
              ],
              transformResponse: [
                function fromJSON(data) {
                  if (data.data) {
                    return JSON.parse(data);
                  } else if (data.status === 401) {
                    return data;
                  }

                  return data;
                }],
            });
          }
        };
      }
    }

    return new Invitation();
  },
]);
