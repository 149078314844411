const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('companyInfoController', [
  '$scope',
  '$mdDialog',
  '$mdToast',
  '$state',
  '$stateParams',
  'auth',
  'dialog',
  'Company',
  function companyInfoController(
    $scope,
    $mdDialog,
    $mdToast,
    $state,
    $stateParams,
    auth,
    dialog,
    Company
  ) {
    $scope.processing = false;
    $scope.selected = [];
    $scope.email = auth.user.email;

    function getCompany() {
      return Company.get({
        id: $stateParams.companyId,
      }, (company) => {
        $scope.company = company;
      });
    }

    if (!auth.user.companies || !auth.user.companies.length) {
      $scope.promise = $state.go('company.list');
    } else {
      $scope.promise = getCompany().$promise;
    }

    $scope.activeText = (isActive) => {
      if (isActive) {
        return 'Active';
      }
      return 'Invited';
    };

    $scope.add = (event) => {
      $mdDialog.show({
        clickOutsideToClose: true,
        controller: 'companyAddUserController',
        controllerAs: 'ctrl',
        focusOnOpen: false,
        targetEvent: event,
        locals: { companyInstance: $scope.company },
        templateUrl: 'dist/templates/dialogs/addUser.html',
      }).then(getCompany);
    };

    $scope.delete = () => {
      const text = ($scope.selected.length === 1) ? 'user' : 'users';

      dialog.confirm(`Are you sure you want to remove ${text} from company?`, {
        parent: angular.element(document.querySelector('#infoCompanyCard')),
        title: `Remove ${text} from company`,
      }).then(() => {
        const emails = [];

        _.forEach($scope.selected, (item) => {
          if (item.email !== auth.user.email) {
            emails.push(item.email);
          }
        });

        Company.deleteUser({ id: $scope.company.id }, { emails }, () => {
          $scope.selected = [];
          getCompany();
        });
      });
    };

    $scope.changeCompanyName = (event) => $mdDialog.show({
      clickOutsideToClose: true,
      controller: 'companyChangeNameController',
      controllerAs: 'ctrl',
      locals: { company: $scope.company },
      focusOnOpen: false,
      targetEvent: event,
      templateUrl: 'dist/templates/dialogs/changeCompanyName.html',
    });
  },
]);
