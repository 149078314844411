const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.factory('BaseFallball', [
  'baseModel',
  function BaseFallballModel(
    Base
  ) {
    class BaseFallball extends Base {
      /* istanbul ignore next */
      constructor() {
        super();
        this.token = '';

        _.forEach(this.actions, (value, key) => {
          delete this.actions[key].transformResponse;
          _.set(this.actions, `${key}.headers.Authorization`, () => `Token ${this.token}`);
        });
      }
    }

    return BaseFallball;
  },
]);
