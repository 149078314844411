const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('FederatedConnector', [
  '$resource',
  'config',
  'baseModel',
  'StateModel',
  function FederatedConnectorModel(
    $resource,
    config,
    Base,
    StateModel
  ) {
    class FederatedConnector extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        const status = {
          REQUESTED: 0,
          APPROVED: 1,
          INSTALLING: 2,
          INSTALLED: 3,
          ERROR: 4,
        };

        this.url = '/api/v1/federated-applications/:id/';

        this.actions = _.extend({}, this.actions, {
          install: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/federated-applications/:id/install/`,
          },
          restartState: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/federated-applications/:id/restart-state/`,
          },
          setConfiguration: {
            method: 'PUT',
            url: `${config.apiBaseUrl}/api/v1/federated-applications/:id/set-configuration/`,
          },
        });

        class FederatedConnectorResource extends this.factory() {
          init() {
            this.$status = status;
          }

          getStatus() {
            const typeMap = {
              [this.$status.REQUESTED]: 'Requested',
              [this.$status.APPROVED]: 'Approved',
              [this.$status.INSTALLING]: 'Installing..',
              [this.$status.INSTALLED]: 'Installed',
              [this.$status.ERROR]: 'Error',
            };

            return typeMap[this.status];
          }

          getStates() {
            return _.map(this.states, (state) => new StateModel(state));
          }
        }

        FederatedConnectorResource.$status = status;

        return FederatedConnectorResource;
      }
    }

    return new FederatedConnector();
  },
]);
