const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('UnauthorizedHandler', [
  '$location',
  function UnauthorizedHandler() {
    return function handler(response, inject) {
      const $state = inject('$state');
      const auth = inject('auth');

      let setResponseError = _.noop;

      if (response.data) {
        setResponseError = (prm) => (prm.message = response.data.error);
      }

      auth.clearCredentials();

      if ($state.current.name !== 'login') {
        const params = {
          stateName: $state.current.name,
          stateParams: $state.params,
        };
        setResponseError(params);
        $state.go('login', params);
      }
    };
  },
]);
