const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('unsupportedWarning', [
  '$rootScope',
  '$window',
  function unsupportedWarning(
    $rootScope,
    $window
  ) {
    return {
      restrict: 'E',
      scope: {},
      templateUrl: 'dist/directives/unsupportedWarning/unsupportedWarning.html',
      link(scope) {
        const data = {};
        const agent = $window.navigator.userAgent;

        data.ISIE = agent.indexOf('Trident') > 0 || agent.indexOf('MSIE') > 0;

        if (data.ISIE) {
          scope.unsupportedWarning = true;
        }
      },
    };
  },
]);
