const angular = require('angular');
const st = require('stacktrace-js');


const app = angular.module('sweeft-spa');


app.factory('$exceptionHandler', [
  '$injector',
  '$log',
  function $exceptionHandler(
    $injector,
    $log
  ) {
    return (...args) => {
      const exception = args[0];
      const auth = $injector.get('auth');
      const ErrorReport = $injector.get('ErrorReport');
      const sourceMapCache = $injector.get('sourceMapCache');

      $log.error.apply($log, args);

      const options = {
        sourceCache: sourceMapCache,
      };

      if (auth.isAuthenticated()) {
        st.fromError(exception, options).then((sf) => {
          const error = new ErrorReport({
            stack: sf,
          });

          return error.$save().$promise;
        });
      }
    };
  },
]);
