const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('changePasswordAccountController', [
  '$scope',
  'helpers',
  'User',
  'auth',
  function changePasswordAccountController(
    $scope,
    helpers,
    User,
    auth
  ) {
    $scope.helper = helpers;

    const success = () => {
      $scope.helper.process = false;
      auth.setCredentials();
      $scope.helper.hide();
    };

    const error = (user) => {
      $scope.helper.process = false;
      $scope.passwordIncorrect = user.data.error;
      $scope.changePasswordForm.password.$setValidity('password', false);
    };

    $scope.passwordChange = () => {
      $scope.changePasswordForm.password.$setValidity('password', true);
    };


    $scope.changePassword = () => {
      if ($scope.helper.process || $scope.changePasswordForm.$invalid) {
        return;
      }

      $scope.helper.process = true;

      User
        .changePassword({ old_password: $scope.password, new_password: $scope.newPassword })
        .$promise
        .then(success)
        .catch(error);
    };
  },
]);
