const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Company', [
  '$resource',
  'config',
  'baseModel',
  function CompanyModel(
    $resource,
    config,
    Base
  ) {
    class Company extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/company/:id/';

        this.actions = _.extend({}, this.actions, {
          checkUnique: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/company/check_unique/`,
          },

          addUser: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/company/:id/add-user/`,
          },

          deleteUser: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/company/:id/delete-user/`,
          },

          updateCompany: {
            method: 'PUT',
          },
        });

        return class extends this.factory() {
          init() {
            this.$types = {
              ISV: 'isv',
              ISP: 'isp',
            };

            this.$roles = {
              ADMIN: 1,
              DEVELOPER: 2,
            };
          }

          getType() {
            const typeMap = {
              [this.$types.ISV]: 'Service Vendor',
              [this.$types.ISP]: 'Service Provider',
            };

            return typeMap[this.type];
          }
        };
      }
    }

    return new Company();
  },
]);
