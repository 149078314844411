const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('changeNameAccountController', [
  '$scope',
  'helpers',
  'User',
  'auth',
  function changeNameAccountController(
    $scope,
    helpers,
    User,
    auth
  ) {
    $scope.helper = helpers;
    $scope.name = auth.user.name;

    const success = () => {
      auth.user.name = $scope.name;
      auth.setCredentials();
      $scope.helper.process = false;
      $scope.helper.hide();
    };

    const error = () => {
      $scope.helper.process = false;
    };

    $scope.changeName = () => {
      if ($scope.helper.process || $scope.changeNameForm.$invalid) {
        return;
      }

      $scope.helper.process = true;

      User.saveName({ id: auth.user.id }, { name: $scope.name })
        .$promise
        .then(success)
        .catch(error);
    };
  },
]);
