const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('changeEmailAccountController', [
  '$scope',
  '$state',
  'helpers',
  'User',
  'auth',
  'mirror',
  function changeEmailAccountController(
    $scope,
    $state,
    helpers,
    User,
    auth,
    mirror
  ) {
    $scope.helper = helpers;
    $scope.model = {
      new_email: null,
      password: null,
    };
    $scope.error = null;
    $scope.emailError = null;
    $scope.passwordError = null;
    $scope.success = null;

    $scope.form = {
      changeEmailForm: null,
    };

    const success = () => {
      $scope.helper.process = false;
      auth.setCredentials();
      $scope.success = `Confirmation link was sent to your new email address. 
        Please check your mail to finish.`;
    };

    $scope.checkError = {
      isEmailError: (err) => _.includes([
        'Email already registered',
        'New email is not valid',
      ], err.data.error),
      isPasswordError: (err) => _.includes([
        'Incorrect password',
      ], err.data.error),
    };

    const error = (err) => {
      $scope.helper.process = false;

      if ($scope.checkError.isEmailError(err)) {
        $scope.form.changeEmailForm.email.$setValidity('emailValidity', false);
        $scope.emailError = err.data.error;
      } else if ($scope.checkError.isPasswordError(err)) {
        $scope.form.changeEmailForm.password.$setValidity('passwordValidity', false);
        $scope.passwordError = err.data.error;
      } else {
        $scope.error = err.data.error;
      }
    };

    mirror({ success, error }, $scope);

    $scope.resetEmailValidity = () => {
      $scope.form.changeEmailForm.email.$setValidity('emailValidity', true);
    };

    $scope.resetPasswordValidity = () => {
      $scope.form.changeEmailForm.password.$setValidity('passwordValidity', true);
    };

    $scope.changeEmail = () => {
      if ($scope.helper.process || $scope.form.changeEmailForm.$invalid) {
        return;
      }

      $scope.helper.process = true;
      $scope.error = null;
      $scope.emailError = null;
      $scope.passwordError = null;

      User
        .changeEmail({ email: $scope.model.new_email, password: $scope.model.password })
        .$promise
        .then(success)
        .catch(error);
    };
  },
]);
