const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('forgotPasswordController', [
  '$scope',
  '$state',
  '$mdDialog',
  'User',
  function forgotPasswordController(
    $scope,
    $state,
    $mdDialog,
    User
  ) {
    const dialogParent = angular.element(document.querySelector('#forgotApp'));

    $scope.inputBusy = false;
    $scope.email = '';

    $scope.submit = () => {
      if ($scope.processing) {
        return;
      }

      $scope.processing = true;

      User.forgotPassword({ email: $scope.email }).$promise.then(() => {
        const dialog = $mdDialog.alert()
          .parent(dialogParent)
          .theme('customTheme')
          .title('Forgot Password')
          .textContent(`Please check your Email - we have sent an email to ${$scope.email}`)
          .ok('Ok');

        $mdDialog.show(dialog).then(() => {
          $state.go('login');
        });
      })
      .catch((response) => {
        let errtext = 'Something went wrong';

        if (response.data.error) {
          errtext = response.data.error;
        }

        $mdDialog.show(
          $mdDialog
          .alert()
          .parent(angular.element(document.querySelector('#resetPasswordApp')))
          .theme('customTheme')
          .clickOutsideToClose(true)
          .textContent(errtext)
          .ok('Ok')
        );
      })
      .finally(() => {
        $scope.processing = false;
      });
    };
  },
]);
