const angular = require('angular');


const app = angular.module('sweeft-spa');

/* istanbul ignore next */
app.factory('BaseFederatedPackage', [
  '$resource',
  '$q',
  'config',
  'Upload',
  'baseModel',
  function BaseFederatedPackageModel(
    $resource,
    $q,
    config,
    Upload,
    Base
  ) {
    class BaseFederatedPackage extends Base {
      factory() {
        class BaseFederatedPackageResource extends super.factory() {
          uploadUrl() {
            return '';
          }

          savePackage() {
            if (this.$meta.package) {
              return Upload.upload({
                method: 'PUT',
                url: `${config.apiBaseUrl}${this.uploadUrl()}`.replace(/:id/, this.id),
                data: {
                  package: this.$meta.package,
                },
              });
            }

            return $q.when();
          }
        }

        return BaseFederatedPackageResource;
      }

    }

    return BaseFederatedPackage;
  },
]);
