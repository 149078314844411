const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connectorApproveListController', [
  '$scope',
  '$q',
  '$timeout',
  'polling',
  'FederatedConnector',
  'ConnectorApi',
  'dialog',
  'auth',
  'colors',
  'stateResult',
  'mirror',
  function connectorApproveListController(
    $scope,
    $q,
    $timeout,
    polling,
    FederatedConnector,
    ConnectorApi,
    dialog,
    auth,
    colors,
    stateResult,
    mirror
  ) {
    $scope.stateResult = stateResult;

    $scope.user = auth.user;
    $scope.stateInfo = {};

    function replace(arr, obj, newObj) {
      const index = _.indexOf(arr, _.find(arr, obj));
      if (newObj) {
        arr.splice(index, 1, newObj);
      } else {
        arr.splice(index, 1);
      }
    }

    function getFMConn(id) {
      return FederatedConnector.get({ id }).$promise;
    }

    $scope.checkFMConnStatus = (fmConn) => {
      function checkIteration() {
        return getFMConn(fmConn.id)
          .then((refreshedFMConn) => {
            refreshedFMConn.states = refreshedFMConn.getStates();

            if (refreshedFMConn.status === refreshedFMConn.$status.INSTALLED) {
              $timeout(_.noop, 10000).then(() => {
                replace($scope.fmConns, { id: fmConn.id });
              });
              polling.stop(fmConn.id);
            } else if (refreshedFMConn.status === refreshedFMConn.$status.ERROR) {
              replace($scope.fmConns, { id: fmConn.id }, refreshedFMConn);
              polling.stop(fmConn.id);
            } else {
              replace($scope.fmConns, { id: fmConn.id }, refreshedFMConn);
            }
          });
      }
      mirror({
        checkIteration,
      }, $scope);

      fmConn.states = fmConn.getStates();
      replace($scope.fmConns, { id: fmConn.id }, fmConn);

      if (fmConn.status === fmConn.$status.INSTALLING) {
        polling.add(checkIteration, 5000, { alias: fmConn.id });
      } else if (fmConn.status === fmConn.$status.INSTALLED) {
        $timeout(_.noop, 10000).then(() => {
          replace($scope.fmConns, { id: fmConn.id });
        });
      }
    };

    function checkFMConnsStatus(conns) {
      $scope.fmConns = conns;
      _.forEach($scope.fmConns, $scope.checkFMConnStatus);
    }

    $scope.promise = FederatedConnector.query({
      status__in: [
        FederatedConnector.$status.APPROVED,
        FederatedConnector.$status.INSTALLING,
        FederatedConnector.$status.ERROR,
      ],
    }).$promise.then(checkFMConnsStatus);

    $scope.checkStatuses = (fmConn) => fmConn.id + fmConn.status + fmConn.connector.status;

    $scope.isApproved = (fmConn) => fmConn.status === fmConn.$status.APPROVED;

    $scope.stateIsInProgress = (s) => s.status === s.state.IN_PROGRESS;
    $scope.stateIsFailed = (s) => s.status === s.state.FAIL;
    $scope.stateIsOK = (s) => s.status === s.state.OK;

    $scope.stateStyle = (s) => ({
      color: s.status === s.state.NONE ? 'rgba(0, 0, 0, 0.57)' : '',
      'font-weight': s.status === s.state.IN_PROGRESS ? 'bold' : '',
    });

    $scope.stateResultStyle = (s) => ({
      color: s.status === s.state.FAIL ? colors.failed : '',
    });

    $scope.stateIcon = (s) => {
      const failed = $scope.stateIsFailed(s);
      const color = failed ? colors.failed : colors.theme;
      const icon = failed ? 'warning' : 'check_circle';

      return {
        style: { color },
        icon,
      };
    };

    $scope.showStateResult = (s) => {
      $scope.stateInfo[s.name] = !$scope.stateInfo[s.name];
    };

    $scope.showResult = (s) => $scope.stateInfo[s.name];

    $scope.restartState = (fmConn, state) => {
      fmConn.creating = true;
      fmConn.status = fmConn.$status.INSTALLING;
      $scope.stateResult.clearCache();

      _.forEach(fmConn.states, (item) => {
        if (item.name === state.name) {
          item.status = state.state.IN_PROGRESS;
          state.result = '';
          item.showResult = false;
        }
      });

      FederatedConnector.restartState(
          { id: fmConn.id },
          { state: state.name }
        )
        .$promise
        .then(() => {
          getFMConn(fmConn.id).then($scope.checkFMConnStatus);
        })
        .catch(() => {
          fmConn.creating = false;
          dialog.show('Sorry, something went wrong');
        });
    };

    $scope.installApp = (fmConn, hub) => {
      fmConn.creating = true;
      FederatedConnector.install(
          { id: fmConn.id },
          { osa: hub }
        )
        .$promise
        .then(() => {
          getFMConn(fmConn.id).then($scope.checkFMConnStatus);
        })
        .catch(() => {
          fmConn.creating = false;
          dialog.show('Sorry, something went wrong');
        });
    };
  },
]);
