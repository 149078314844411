const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Configuration', [
  '$resource',
  'config',
  'baseModel',
  function ConfigurationModel(
    $resource,
    config,
    Base
  ) {
    class ConfigurationResource extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/configuration/:id/';

        this.actions = _.extend({}, this.actions, {
          resetConnectorURL: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/configuration/:id/reset-url/`,
          },
          patch: {
            method: 'PATCH',
            url: `${config.apiBaseUrl}/api/v1/configuration/:id/`,
          },
        });

        return this.factory();
      }
    }

    return new ConfigurationResource();
  },
]);
