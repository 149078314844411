const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Connector', [
  '$resource',
  '$q',
  '$http',
  'config',
  'baseModel',
  'ConnectorResource',
  'Configuration',
  'Upload',
  'mirror',
  function ConnectorModel(
    $resource,
    $q,
    $http,
    config,
    Base,
    ConnectorResource,
    Configuration,
    Upload,
    mirror
  ) {
    function serialize(obj) {
      if (obj.logo) {
        obj.logo = decodeURI(obj.logo.replace(/^.*\/\/[^/]*/, ''));

        if (obj.logo.indexOf('/media') === 0) {
          obj.logo = obj.logo.replace(/^\/media\//, '');
        }
      }

      return _.omit(obj, [
        'addons',
        'resources',
        'plans',
        'connector',
        'configuration',
      ]);
    }

    function processUnlimited(obj) {
      const newObj = angular.copy(obj);

      newObj.resources = _.map(newObj.resources, (res) => {
        const newRes = angular.copy(res);
        if (res.limit === 'unlimited') {
          newRes.limit = -1;
        }

        return newRes;
      });

      return newObj;
    }

    function wrapResources(model) {
      model.resources = _.map(model.resources, (resource) => {
        const tempResource = new ConnectorResource(resource);

        if (resource.unit && resource.unit.id) {
          tempResource.unit = tempResource.unit.id;
        }

        return tempResource;
      });

      return model;
    }

    const stringify = _.ary(JSON.stringify, 1);

    class Connector extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        const modelUrl = '/api/v1/application/:connId/';

        this.url = '/api/v1/application/:connId/:version';

        this.paramDefaults = {
          version: '@version',
          connId: '@id',
        };

        this.state = {
          IN_DEVELOPMENT: 1,
          ON_CERTIFICATION: 2,
          PUBLISHED: 3,
        };

        this.actions = _.extend({}, this.actions, {
          update: {
            method: 'PUT',
            transformRequest: [serialize, stringify],
          },

          save: {
            method: 'POST',
            transformRequest: [serialize, stringify],
          },

          delete: {
            method: 'DELETE',
            url: `${config.apiBaseUrl}/api/v1/application/:connId`,
          },

          generate: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/generate`,
          },

          publish: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/create-version`,
          },

          setPubStatus: {
            method: 'PATCH',
            url: `${config.apiBaseUrl}/api/v1/application/:versionId/`,
          },

          preview: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/preview`,
            transformRequest: [processUnlimited, stringify],
          },

          usageCollection: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application-preview/:connId/collect-usage`,
          },

          providerPreview: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/isp-preview/:version`,
            transformRequest: [processUnlimited, stringify],
          },

          publication: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/validation`,
          },

          abortValidation: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/abort-validation`,
          },

          instanceConns: {
            method: 'GET',
            isArray: true,
            url: `${config.apiBaseUrl}/api/v1/application/:instanceId/list-applications/`,
            transformResponse: $http.defaults.transformResponse.concat([
              (data) => data.objects,
            ]),
          },

          getSandboxStatus: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/sandbox-status`,
          },

          checkTitle: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application/check-title/`,
          },

          checkId: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application/check-id/`,
          },

          idSuggestion: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application/id-suggestion/`,
          },

          getVersions: {
            method: 'GET',
            isArray: true,
            url: `${config.apiBaseUrl}/api/v1/application/:connId/versions/`,
          },

          getLastVersion: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application/:connId/versions/`,
            transformResponse: $http.defaults.transformResponse.concat([
              (data) => _.findLast(data, { pub_status: 'published' }),
            ]),
          },
        });

        return class extends this.factory() {
          init() {
            this.sandboxStatus = {
              INSTALLING: 1,
              INSTALLED: 2,
              ERROR: 3,
            };

            mirror({
              serialize,
              processUnlimited,
            }, this);
          }

          static get(...args) {
            const connector = super.get(...args);

            connector.$promise = connector.$promise.then(wrapResources);

            return connector;
          }

          static getLatestVersion(versions) {
            return _(versions)
              .sortBy('release')
              .filter({ master: false })
              .last();
          }

          $get(...args) {
            return super.$get(...args)
              .then(wrapResources);
          }

          saveLogo() {
            if (this.$meta.logo) {
              return Upload.upload({
                method: 'PUT',
                url: `${config.apiBaseUrl}${modelUrl}`.replace(/:connId/, this.id),
                data: {
                  logo: this.$meta.logo,
                },
              }).then(() => this.$get());
            }

            return undefined;
          }

          checkApiUrl(url) {
            return $http.post(`${config.apiBaseUrl}/api/v1/configuration`
              + `/${this.configuration.id}/check-url/`, { url });
          }

          getConfiguration() {
            return $q((resolve) => {
              const getConfiguration = () => {
                Configuration.get({
                  id: this.configuration.id,
                }).$promise.then((configuration) => {
                  this.configuration = configuration;

                  resolve(configuration);
                });
              };

              if (this.$promise) {
                this.$promise.then(getConfiguration);
              } else {
                getConfiguration();
              }
            });
          }

          isPublished() {
            return this.status === this.state.PUBLISHED;
          }
        };
      }
    }

    return new Connector();
  },
]);
