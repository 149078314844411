const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connProviderInstallationsController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  '$mdDialog',
  '$mdToast',
  'InstallationRequest',
  function connProviderInstallationsController(
    $scope,
    $state,
    $stateParams,
    $q,
    $mdDialog,
    $mdToast,
    InstallationRequest
  ) {
    const setVersions = function setVersions(requests) {
      requests.forEach((request) => {
        const connector = request.app;
        request.connectorVersion = connector.master ? connector.release - 1 : connector.release;
      });
    };

    $scope.processing = false;
    $scope.deleting = false;

    $scope.promise = InstallationRequest.query({ app__app_id: $stateParams.connId }).$promise
      .then((requests) => {
        setVersions(requests);
        $scope.requests = requests;
      });

    $scope.createRequest = ($event) => {
      if ($scope.processing) {
        return;
      }

      $scope.processing = true;

      InstallationRequest.save({ app: $scope.conn.configuration.app }).$promise
        .then(() => InstallationRequest.query({ app__app_id: $stateParams.connId }).$promise)
        .then((requests) => {
          const alert = $mdDialog
            .alert()
            .targetEvent($event)
            .theme('customTheme')
            .clickOutsideToClose(true)
            .title('Request sent')
            .textContent('You will be notified once the vendor approves the request.')
            .ok('Ok');

          $mdDialog.show(alert);
          setVersions(requests);
          $scope.requests = _.unionBy($scope.requests, requests, 'id');
        })
        .catch(() => {
          const toast = $mdToast
            .simple()
            .textContent('Sorry, something went wrong')
            .position('bottom right')
            .hideDelay(5000)
            .theme('customTheme');

          $mdToast.show(toast);
        })
        .finally(() => ($scope.processing = false));
    };

    $scope.requestStyle = (item) => {
      const status = _.lowerCase(item.getStatus());

      return `request-status--${status}`;
    };
  },
]);
