const statuses = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
};

const types = {
  0: 'Installation',
  1: 'Upgrade',
};


const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('InstallationRequest', [
  '$http',
  '$resource',
  'config',
  'baseModel',
  function InstallationRequestModel(
    $http,
    $resource,
    config,
    Base
  ) {
    const createStatusUpdateAction = function createStatusUpdateAction(status) {
      return {
        method: 'PATCH',
        url: `${config.apiBaseUrl}/api/v1/request/:id/`,
        transformRequest: [
          data => {
            data.status = status;
            return data;
          },
        ].concat($http.defaults.transformRequest),
      };
    };

    class InstallationRequest extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/request/:id/';

        this.actions = _.extend({}, this.actions, {
          approve: createStatusUpdateAction(statuses.APPROVED),
          reject: createStatusUpdateAction(statuses.REJECTED),
        });

        return class extends this.factory() {
          init() {
            this.statuses = statuses;
            this.types = types;
          }

          isStatus(status) {
            return this.status === status;
          }

          getStatus() {
            return _.findKey(this.statuses, val => val === this.status);
          }

          getType() {
            return this.types[this.type];
          }
        };
      }
    }

    return new InstallationRequest();
  },
]);
