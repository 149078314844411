const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


const templateUrl = 'dist/directives/resource/resourceList.html';


app.directive('resourceList', [
  function resourceList() {
    return {
      restrict: 'E',
      scope: {
        resources: '=',
        canChange: '&',
        delete: '&',
        save: '&',
        handler: '&',
        type: '=',
      },
      templateUrl,
      link(scope) {
        scope.options = scope.handler({ m: scope.type });
        scope.list = angular.copy(scope.resources);

        scope.addResource = () => {
          scope.list.push(scope.options.add());
        };

        scope.addResource();

        const resourceSync = () => {
          scope.resources = _.filter(scope.list, (r) => !_.isUndefined(r.id));
        };

        scope.pattern = {
          id: /^[a-zA-Z_][a-zA-Z0-9_\ \-]*$/i,
          title: /^[0-9a-z\(.+\) \-]*$/i,
          url: /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})/i,
        };

        scope.resourceSave = (res, form, isLast) => {
          if (isLast) {
            scope.addResource();
          }

          if (form && !form.$valid) {
            return;
          }

          scope.save({ $resource: res }).then(() => {
            resourceSync();
          });
        };

        scope.resourceDelete = (res) => {
          const index = scope.list.indexOf(res);
          const afterId = index > 1 ? scope.list[index - 1].id : 0;
          scope.list.splice(index, 1);
          resourceSync();
          scope.delete({ $resource: res, $id: afterId }).then((r) => {
            if (r) {
              scope.list.splice(index, 0, res);
              resourceSync();
            }

            return resourceSync();
          });
        };

        scope.getIcon = (res, icon) => res === icon;

        scope.$on('$destroy', () => resourceSync());
      },
    };
  },
]);


app.run([
  '$templateCache',
  '$http',
  function templateCache(
    $templateCache,
    $http
  ) {
    $http.get(templateUrl, { cache: $templateCache });
  },
]);
