const angular = require('angular');


const app = angular.module('sweeft-spa');


app.filter('utcToLocal', [
  '$filter',
  function utcToLocal(
    $filter
  ) {
    return (utcDateString = new Date().toISOString(), format, utc) => {
      let date = utcDateString;

      if (!/T.+[Z+-]/.test(date)) {
        date += 'Z';
      }

      const getTimeZoneOffset = () => {
        if (!utc) {
          return '';
        }

        let tz = $filter('date')(date, 'Z');
        let diff = tz.charAt(0);

        if (tz.indexOf('0000') !== -1) {
          diff = '±';
        }

        tz = tz.slice(1).replace(/(0?)(\d{1,2})(\d\d)/, '$2:$3');

        return ` (UTC: ${diff}${tz})`;
      };

      return $filter('date')(date, format) + getTimeZoneOffset();
    };
  },
]);
