const SimpleMDE = require('simplemde');
const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


const transformButtons = {
  'fa-bold': 'format_bold',
  'fa-italic': 'format_italic',
  'fa-header': 'title',
  'fa-quote-left': 'format_quote',
  'fa-list-ul': 'format_list_bulleted',
  'fa-list-ol': 'format_list_numbered',
  'fa-link': 'insert_link',
  'fa-picture-o': 'insert_photo',
  'fa-eye': 'visibility',
  'fa-columns': 'content_copy',
  'fa-arrows-alt': 'fullscreen',
  'fa-question-circle': 'help',
};

function fa2md(toolbar) {
  _.forEach(toolbar, (btnEl) => {
    const btn = angular.element(btnEl);
    _.forEach(transformButtons, (transVal, transRule) => {
      if (btn.hasClass(transRule)) {
        btn.append(angular.element('<i class="material-icons">').text(transVal));
        return false;
      }

      return true;
    });
  });
}

app.directive('simplemde', [
  'mirror',
  function simpleMde(mirror) {
    return {
      restrict: 'A',
      require: 'ngModel',
      controller: [
        '$scope',
        function simpleMdeController($scope) {
          return {
            get: () => $scope.simplemde.instance,
          };
        },
      ],
      link: (scope, element, attrs, ngModel) => {
        const prerendered = attrs.prerendered === 'true';

        let simpleMdeOptions = {
          autoDownloadFontAwesome: false,
          spellChecker: false,
          renderingConfig: {
            markedOptions: {
              sanitize: true,
            },
          },
        };

        if (!prerendered) {
          simpleMdeOptions = {
            autoDownloadFontAwesome: false,
            toolbar: false,
            status: false,
          };
        }

        if (!simpleMdeOptions.element) {
          simpleMdeOptions.element = element[0];
        }

        const mde = new SimpleMDE(simpleMdeOptions);

        mde.codemirror.on('change', () => {
          scope.$applyAsync(() => ngModel.$setViewValue(mde.value()));
        });

        ngModel.$render = () => {
          mde.value(_.unescape(ngModel.$modelValue));
        };

        if (!prerendered) {
          ngModel.$render = () => {
            mde.value(_.unescape(ngModel.$modelValue));
            mde.togglePreview();
          };
        } else {
          fa2md(mde.gui.toolbar.querySelectorAll('button'));
        }

        scope.simplemde = {
          instance: mde,
        };

        mirror({ fa2md, mde, prerendered, ngModel }, scope);
      },
    };
  },
]);
