const angular = require('angular');


const app = angular.module('sweeft-spa');

app.controller('companyCreateDialogController', [
  '$scope',
  '$mdDialog',
  '$location',
  '$state',
  'auth',
  'Company',
  function companyCreateController(
    $scope,
    $mdDialog,
    $location,
    $state,
    auth,
    Company
  ) {
    $scope.companyData = {};
    $scope.user = auth.user;
    $scope.processing = false;

    $scope.cancel = () => $mdDialog.cancel();

    $scope.continue = () => {
      $mdDialog.hide();
    };

    $scope.userType = auth.user.type;

    function createNewCompany(data) {
      $scope.processing = true;
      const company = new Company(data);

      return company.$save()
        .then(() => {
          $scope.cancel();

          if (auth.user.isVendor) {
            $state.go('isv.connector.list', { firstCompany: true });
          } else {
            $state.go('provider.connector.list', { firstCompany: true });
          }
        })
        .catch(() => {
          $scope.processing = false;

          $mdDialog.show(
            $mdDialog
              .alert()
              .parent(angular.element(document.querySelector('#newCompanyCard')))
              .clickOutsideToClose(true)
              .theme('customTheme')
              .content('Something went wrong')
              .ok('Ok')
          );
        });
    }

    $scope.createNewCompany = ($event) => {
      if (!$scope.processing) {
        if (!$scope.companyAddForm.$valid) {
          $scope.processing = false;

          return $mdDialog.show(
            $mdDialog
              .alert()
              .parent(angular.element(document.querySelector('#newCompanyCard')))
              .clickOutsideToClose(true)
              .theme('customTheme')
              .content('Please fill in valid values')
              .ok('Got it!')
              .targetEvent($event)
          );
        }

        return createNewCompany($scope.companyData);
      }

      return null;
    };
  },
]);
