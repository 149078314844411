const angular = require('angular');


const app = angular.module('sweeft-spa');


app.factory('ForbiddenHandler', [
  function ForbiddenHandler() {
    return function errorHandler(response, inject) {
      const $mdDialog = inject('$mdDialog');
      const $state = inject('$state');
      const auth = inject('auth');
      const currentState = $state.current;

      if (currentState.name.startsWith('isv.connector.edit')) {
        $mdDialog.show(
          $mdDialog.alert()
            .theme('customTheme')
            .title('Access forbidden')
            .textContent('You don\'t have permission to access this object')
            .ok('Return to the list'))
          .then(() => auth.fetchCompanies())
          .then(() => {
            if (auth.user.isVendor) {
              $state.go('isv.connector.list');
            } else {
              $state.go('provider.connector.list');
            }
          });
      }
    };
  },
]);
