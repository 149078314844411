const angular = require('angular');


const app = angular.module('sweeft-spa');


app.service('sourceMapCache', [
  function sourceMapCacheService() {
    const cache = {};

    return cache;
  },
]);
