const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('form', [
  '$parse',
  function form(
    $parse
  ) {
    return {
      require: 'form',
      restrict: 'E',
      link(scope, element, attrs, formController) {
        formController.$submit = $parse(attrs.ngSubmit);
      },
    };
  },
]);
