const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('labRemoveController', [
  '$scope',
  '$state',
  '$timeout',
  'SandboxService',
  'mirror',
  function labRemoveController(
    $scope,
    $state,
    $timeout,
    SandboxService,
    mirror
  ) {
    $scope.stateSandboxStatus();

    $scope.promise = $scope.getConfiguration().then((configuration) => {
      $scope.configuration = configuration;
      $scope.sandbox = new SandboxService($scope.conn.sandbox);
    });

    function success() {
      const index = _.indexOf(
        $scope.navigation,
        _.find($scope.navigation, { type: 'sandbox' })
      );

      $scope.navigation.splice(index, 1);
      $scope.conn.sandbox = null;

      return $state.go('isv.connector.edit.guide', { connId: $scope.conn.id });
    }

    function error(res) {
      $scope.processing = false;
      $scope.error = res.data.error || res.statusText;
    }

    mirror({ success, error }, $scope);

    $scope.apiUrlChanged = () => $scope.configuration && !$scope.configuration.is_mock;

    $scope.remove = () => {
      if ($scope.processing) {
        return;
      }

      $scope.processing = true;

      $timeout(_.noop, 1000).then(() =>
        $scope.sandbox.$remove()
          .then(success)
          .catch(error)
      );
    };
  },
]);
