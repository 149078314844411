const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('authController', [
  '$scope',
  '$state',
  '$location',
  '$mdDialog',
  '$stateParams',
  'auth',
  'mirror',
  function authController(
    $scope,
    $state,
    $location,
    $mdDialog,
    $stateParams,
    auth,
    mirror
  ) {
    const credentials = {};
    const presetCredentials = {};
    const messageId = $stateParams.message;
    const dialogMap = {
      session_expired: 'sessionExpired.html',
      session_terminated: 'sessionTerminated.html',
    };

    mirror({
      credentials,
      presetCredentials,
      messageId,
      dialogMap,
    }, $scope);

    let dialog;

    $scope.user = auth.user;

    $scope.credentials = credentials;

    $scope.processing = false;
    $scope.showpassword = false;

    function dialogController(scope, dlg) {
      scope.ok = () => dlg.hide();
    }

    function alertDialog(tmplUrl) {
      return $mdDialog
        .show({
          templateUrl: `dist/templates/dialogs/${tmplUrl}`,
          controller: [
            '$scope',
            '$mdDialog',
            dialogController,
          ],
        });
    }

    function checkPresetCredentials() {
      const givenParams = $location.search();
      if (givenParams.email && givenParams.api_key) {
        presetCredentials.email = givenParams.email;
        presetCredentials.api_key = givenParams.api_key;

        auth.loginAs(presetCredentials);
      }
    }

    mirror({
      alertDialog,
      dialogController,
      checkPresetCredentials,
    }, $scope);

    checkPresetCredentials();

    if (messageId && dialogMap[messageId]) {
      dialog = alertDialog(dialogMap[messageId]);
    }

    $scope.login = ($event) => {
      if ($scope.processing) {
        return;
      }

      if (!$scope.loginForm.$valid) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .parent(angular.element(document.querySelector('#loginApp')))
            .clickOutsideToClose(true)
            .content('Please fill in valid values')
            .ok('Got it!')
            .targetEvent($event)
        );

        return;
      }

      $scope.processing = true;

      auth
        .login(credentials)
        .then(() => {
          // for django redirect logic
          let nextUrl = location.hash.split('next=')[1];

          if (nextUrl) {
            nextUrl = decodeURIComponent(nextUrl);
            window.location.replace(`${window.location.origin}${nextUrl}`);
          } else if (!$state.params.stateName) {
            if (auth.user.isProvider) {
              $state.go('hub.list');
            } else if (auth.user.isVendor) {
              $state.go('isv.connector.list');
            } else {
              $state.go('provider.connector.list');
            }
          } else {
            $state.go($state.params.stateName, $state.params.stateParams);
          }
        })
        .catch((response) => {
          $mdDialog.show(
            $mdDialog
              .alert()
              .parent(angular.element(document.querySelector('#loginApp')))
              .clickOutsideToClose(true)
              .theme('customTheme')
              .content(response.data.message)
              .ok('Ok')
              .targetEvent($event)
          );
        })
        .finally(() => {
          $scope.processing = false;

          if (dialog) {
            $mdDialog.cancel(dialog);
          }
        });
    };

    $scope.logout = () => {
      auth.logout().then(() => {
        $state.go('login');
      });
    };
  },
]);
