const angular = require('angular');

const app = angular.module('sweeft-spa');


app.factory('Reseller', [
  'BaseFallball',
  'config',
  function ResellerModel(
    BaseFallball,
    config
  ) {
    class Reseller extends BaseFallball {
      /* istanbul ignore next */
      constructor() {
        super();
        this.baseUrl = config.fallballBaseUrl;
        this.url = '/resellers/:name/';

        const reseller = this;

        return class extends this.factory() {
          static setToken(token) {
            reseller.token = token;
          }
        };
      }
    }

    return new Reseller();
  },
]);
