const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ConnectorResource', [
  '$resource',
  'config',
  'baseModel',
  function ConnectorResourceModel(
    $resource,
    config,
    Base
  ) {
    const stringify = _.ary(JSON.stringify, 1);

    class ConnectorResource extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/resource/:id/';

        this.actions = _.extend({}, this.actions, {
          units: {
            method: 'GET',
            isArray: true,
            url: `${config.apiBaseUrl}/api/v1/resource/units/`,
          },
          update: {
            method: 'PUT',
            transformRequest: [
              (resource) => {
                const tempResource = _.extend({}, resource);
                if (tempResource.unit && tempResource.unit.id) {
                  tempResource.unit = tempResource.unit.id;
                }

                return tempResource;
              },
              stringify,
            ],
          },
        });

        return class extends this.factory() {
          isValid() {
            const pattern = {
              id: /^[A-Z_][A-Z0-9_\ \-]*$/i,
            };

            return this.name &&
              this.name.length > 0 &&
              pattern.id.test(this.name);
          }
        };
      }
    }

    return new ConnectorResource();
  },
]);
