const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('preventOn', [
  '$compile',
  function preventOn(
    $compile
  ) {
    return {
      restrict: 'A',
      scope: {
        preventOn: '=',
      },
      link(scope, element) {
        const $blocker = angular.element('<div class="prevent-event"></div>');
        const stateClass = 'is-show';

        element.css('position', 'relative');

        $blocker.on('click', (e) => {
          e.stopPropagation();
        });

        $compile($blocker)(scope);
        element.append($blocker);

        scope.$watch('preventOn', (show) => {
          if (show) {
            $blocker.addClass(stateClass);
          } else {
            $blocker.removeClass(stateClass);
          }
        });
      },
    };
  },
]);
