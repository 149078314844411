const angular = require('angular');


const app = angular.module('sweeft-spa');


app.service('preview', [
  '$rootScope',
  '$state',
  'auth',
  function previewService($rootScope, $state, auth) {
    return {
      isPreviewMode: () => (
        auth.isAuthenticated() &&
        auth.user.isVendor &&
        ($state.includes('provider.**') || $state.is('invite'))
      ),
    };
  },
]);
