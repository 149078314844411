const angular = require('angular');


const app = angular.module('sweeft-spa');

app.controller('logoutController', [
  '$scope',
  '$state',
  'auth',
  function logoutController(
    $scope,
    $state,
    auth
  ) {
    auth.logout().then(() => {
      $state.reload(true);
    });
  },
]);
