const angular = require('angular');
const _ = require('lodash');
const isAbsoluteUrl = require('is-absolute-url');


const app = angular.module('sweeft-spa');


app.factory('baseModel', [
  '$q',
  '$resource',
  '$http',
  'config',
  function baseModel(
    $q,
    $resource,
    $http,
    config
  ) {
    class Base {
      /* istanbul ignore next */
      constructor() {
        this.url = '';
        this.baseUrl = config.apiBaseUrl;
        this.paramDefaults = undefined;
        this.params = undefined;

        this.actions = {
          query: {
            method: 'GET',
            isArray: true,
            transformResponse: $http.defaults.transformResponse.concat([
              function getObjects(data) {
                return data.objects;
              },
            ]),
          },

          get: {
            method: 'GET',
            transformResponse: $http.defaults.transformResponse.concat([
              function getObjects(data) {
                if (!_.isUndefined(data.objects)) {
                  return data.objects[0];
                }

                return data;
              },
            ]),
          },

          update: {
            method: 'PUT',
          },
        };
      }

      factory() {
        const Resource = $resource(this.baseUrl + this.url,
          this.paramDefaults, this.actions, this.params);

        const baseUrl = this.baseUrl;

        function modelConstructor() {
          this.$meta = Object.create(null);
          this.$saveInProgress = false;

          this.init();
        }

        function wrapModel(obj, TargetModel) {
          const original = _.extend({}, obj);

          Object.setPrototypeOf(obj, TargetModel);
          modelConstructor.call(obj, original);

          return obj;
        }

        class BaseModel extends Resource {
          /* istanbul ignore next */
          constructor(...args) {
            super(...args);

            modelConstructor.call(this);
          }

          init() {
          }

          toJSON() {
            return _(super.toJSON())
              .omit([
                '$saveInProgress',
                '$meta',
              ])
              .value();
          }

          static get(...args) {
            const res = Resource.get(...args);

            wrapModel(res, this.prototype);

            res.$promise = res.$promise.then(() => wrapModel(res, this.prototype));

            return res;
          }

          static query(...args) {
            const array = Resource.query(...args);

            array.$promise = array.$promise.then(() => _.map(array,
                  (obj) => wrapModel(obj, this.prototype)));

            return array;
          }

          $get(...args) {
            /* istanbul ignore next */
            return super
              .$get(...args)
              .then((obj) => wrapModel(obj, Object.getPrototypeOf(this)));
          }

          $save() {
            if (this.$saveInProgress) {
              this.$promise = this.$promise.then(() => this.$update({ id: this.id }));

              return this.$promise;
            }

            this.$saveInProgress = true;

            this.$promise = $q((resolve, reject) => {
              /* istanbul ignore next */
              super.$save({},
                (serverData, headersGetter) => {
                  const newLocation = headersGetter('Location');
                  let url;

                  if (!newLocation) {
                    resolve(serverData);
                    return;
                  }

                  if (isAbsoluteUrl(newLocation)) {
                    url = newLocation;
                  } else {
                    url = `${baseUrl}${newLocation}`;
                  }

                  $resource(url)
                    .get()
                    .$promise
                    .then((newServerData) => {
                      this.id = newServerData.id;
                      resolve(newServerData);
                    });
                },
                (data) => {
                  reject(data);
                }
              );
            });

            this.$promise.finally(() => {
              this.$saveInProgress = false;
            });

            return this.$promise;
          }
        }

        return BaseModel;
      }

      serialize() {
        return _.ary(JSON.stringify, 1);
      }
    }

    return Base;
  },
]);
