const angular = require('angular');


const app = angular.module('sweeft-spa');


app.constant('accountSchema', {
  companyName: '@company',
  addressPostal: {
    streetAddress: '@street_address',
    extendedAddress: '@secondary_address',
    locality: '@city',
    postalCode: '@zipcode',
    countryName: '@country_code',
    region: '@city',
  },
  techContact: {
    email: '@email',
    familyName: '@last_name',
    givenName: '@first_name',
    telVoice: '+1 234 567890',
  },
});
