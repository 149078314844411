const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('UIResource', [
  '$resource',
  '$http',
  'config',
  'baseModel',
  function UIResourceModel(
    $resource,
    $http,
    config,
    Base
  ) {
    const stringify = _.ary(JSON.stringify, 1);

    class UIResource extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/ui-resource/:id/';

        this.actions = _.extend({}, this.actions, {
          sort: {
            url: `${config.apiBaseUrl}/api/v1/ui-resource/:id/sort/:after`,
            method: 'POST',
            transformRequest: $http.defaults.transformRequest.concat([
              () => JSON.stringify({}),
            ]),
          },
          update: {
            method: 'PUT',
            transformRequest: [
              (resource) => {
                const tempResource = _.extend({}, resource);
                if (tempResource.model_resource.unit && tempResource.model_resource.unit.id) {
                  tempResource.model_resource.unit = tempResource.model_resource.unit.id;
                }
                return tempResource;
              },
              stringify,
            ],
          },
        });

        return this.factory();
      }
    }

    return new UIResource();
  },
]);
