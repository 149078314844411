const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


function domIndexOf(child, parent) {
  return Array.prototype.indexOf.call(angular.element(parent).children(), child);
}


app.controller('connEditUIController', [
  '$scope',
  '$resource',
  '$timeout',
  '$log',
  '$q',
  '$document',
  '$window',
  '$stateParams',
  '$mdToast',
  'mirror',
  'polling',
  'dragulaService',
  'Locales',
  'ConnectorUI',
  'UIResource',
  'UIMetaResource',
  'Localization',
  function connEditUIController(
    $scope,
    $resource,
    $timeout,
    $log,
    $q,
    $document,
    $window,
    $stateParams,
    $mdToast,
    mirror,
    polling,
    dragulaService,
    Locales,
    ConnectorUI,
    UIResource,
    UIMetaResource,
    Localization
  ) {
    $scope.meta = [
      {
        title: 'Downloads',
        type: {
          type: 'DOWNLOAD_LINK',
        },
        description: 'Static download links that your customers might need.',
      },
      {
        title: 'Documentation',
        type: {
          type: 'DOC_LINK',
        },
        description: 'Static links to documentation that customers might need.',
      },
    ];

    $scope.model = {
      mockModeNotificationVisible: true,
    };

    $scope.forms = {};

    $scope.canChange = () => $scope.conn.master;

    $scope.connUISaver = (form) => {
      if (form && form.$invalid) {
        return;
      }

      $scope.localization.resourcesHasChanged = true;
      $scope._save(() => $scope.ui.$update({ id: $scope.ui.id }));
    };

    $scope.uiResourceDisplayNamePattern = /^[a-zA-Z_][a-zA-Z0-9_\ \-]*$/i;

    $scope.uiResourceSaver = (res, form) => {
      if (form && form.$invalid) {
        return;
      }

      $scope.localization.resourcesHasChanged = true;
      $scope._save(() => res.$update({ id: res.id }));
    };

    $scope.$on('ui-resource-bag.drop-model', (event, el, container) => {
      const changedIndex = domIndexOf(el[0], container);
      const changed = $scope.ui.resources[changedIndex];
      const after = $scope.ui.resources[changedIndex - 1];
      let afterId = 0;

      if (!_.isUndefined(after)) {
        afterId = after.model_resource.id;
      }

      $scope._save(() => {
        const s = UIResource.sort({
          id: changed.model_resource.id,
          after: afterId,
        }, {});

        return s.$promise;
      });
    });

    $scope.updateMetaResource = (res) => {
      $scope.localization.resourcesHasChanged = true;
      res.ui = $scope.ui.resource_uri;

      return $scope._save(() => {
        if (res.id) {
          return res.$update({ id: res.id });
        }

        return res.$save();
      });
    };

    $scope.updateResource = (ui) => {
      _.each(ui.resources, (resource) => {
        let needUpdate = false;
        const defaultName = resource.model_resource.title;

        if (!resource.display_name) {
          needUpdate = true;
          resource.display_name = defaultName;
        }

        if (!resource.display_name_single && resource.model_resource.unit === 'user') {
          needUpdate = true;
          resource.display_name_single = defaultName;
        }

        if (needUpdate) {
          resource.$update({ id: resource.id })
            .catch((...args) => {
              $log.error(args);
            });
        }
      });
    };

    const getUI = () => $scope.conn.$promise
      .then(connector => ConnectorUI.get({ app: connector.id }).$promise)
      .then(ui => $scope.updateResource($scope.ui = ui));

    $scope.locales = Localization.query({ app: $stateParams.connId });

    $scope.initLocales = () => {
      $scope.localization = new Locales({
        conn: $scope.conn,
        ui: $scope.ui,
        locales: $scope.locales,
        generating: $scope.ui.isLocalesInProgress(),
        updateResource: ui => $scope.updateResource($scope.ui = ui),
        toggleEditing: (turnOn) => {
          if (turnOn) {
            $scope.canChange = () => $scope.conn.master;
          } else {
            $scope.canChange = () => false;
          }
        },
      });
    };

    $scope.checkLocales = (locale, isFirst) => {
      if (!isFirst) {
        return;
      }

      const isInProgressLocales = $scope.ui.isLocalesInProgress();
      const isFailedLocales = $scope.ui.isLocalesFailed();

      if (isInProgressLocales || isFailedLocales) {
        locale._current = true;
        if (isFailedLocales) {
          $timeout(() => {
            $scope.localization.setError(locale._form, 'cannotCreate', true);
          });

          return;
        }
        $scope.canChange = () => false;
        $scope.localization.startPolling(locale);
      }
    };

    $scope.promise = $q.all([
      $scope.conn.$promise,
      $scope.locales.$promise,
      getUI(),
    ]).then(() => $scope.initLocales());

    $scope.$on('version:change', () => getUI());

    $scope.$on('loading.done', () => {
      dragulaService.options($scope, 'ui-resource-bag', {
        direction: 'vertical',
        mirrorContainer: angular.element($document[0].querySelector('#dragula-mirror'))[0],
        documentElement: angular.element($document[0].querySelector('#dragula-mirror'))[0],
        moves: (el, container, handle) => {
          let e = handle;

          do {
            if (e.classList.contains('drag-handle')) {
              return true;
            }

            e = e.parentElement;
          } while (e !== el && e !== null);

          return false;
        },
      });

      $scope.bagSetup = true;
    });

    $scope.addMetaResource = (type) => {
      const metaRes = _.merge({}, type, {
        name: '',
        value: '',
        visibility_type: 0,
      });

      return new UIMetaResource(metaRes);
    };

    $scope.deleteMetaResource = (r) => {
      $scope.localization.resourcesHasChanged = true;

      const meta = new UIMetaResource(r);

      mirror({
        meta,
      }, $scope);

      return $scope._delete(
        () => ({
          obj: r,
          delete: () => {
            if (!meta.id) {
              return $q.when(r);
            }

            return meta.$delete({
              id: meta.id,
            });
          },
        }),
        // Undoer
        () => {
          if (meta.id && meta.isValid()) {
            return meta.$save().then(res => res);
          }

          r.id = null;

          return $q.when(r);
        },
        // Content getter
        (obj) => {
          let l = '';

          if (obj.name) {
            l = `'${obj.name}' `;
          }

          return `Link ${l}deleted`;
        });
    };

    $scope.uiHandler = (resource) => ({
      placeholder: 'Name',
      type: 'URL',
      label: 'Title',
      modelOptions: $scope.modelOptions,
      filter: resource.type,
      add: () => $scope.addMetaResource(resource.type),
      visibility: () => resource.type.type === 'DOC_LINK',
    });

    mirror({
      getUI,
      domIndexOf,
    }, $scope);
  },
]);
