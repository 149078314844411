const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');
const pow = Math.pow;
const CANVAS_RATIO = 32;


app.service('colorPalette', [
  '$mdColorPalette',
  '$q',
  '$document',
  function convertService(
    $mdColorPalette,
    $q,
    $document
  ) {
    return {
      colorify(img) {
        const canvas = document.createElement('canvas');
        canvas.width = canvas.height = CANVAS_RATIO;
        const ctx = canvas.getContext('2d');
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.drawImage(img, 0, 0, CANVAS_RATIO, CANVAS_RATIO);
        const canvasData = ctx.getImageData(0, 0, CANVAS_RATIO, CANVAS_RATIO);

        const clrfy = (data) => {
          const distances = [];
          for (let i = 0; i < data.length; i = i + 4) {
            if ((data[i] > 250 && data[i + 1] > 250 && data[i + 2] > 250) || data[i + 3] < 100) {
              continue;
            }

            let dist = 0;
            let count = 0;
            for (let j = 0; j < data.length; j = j + 4) {
              if (i === j) continue;

              if ((data[j] > 250 && data[j + 1] > 250 && data[j + 2] > 250) || data[j + 3] < 100) {
                continue;
              }

              const d = { r: 0, g: 0, b: 0 };
              d.r = data[i] - data[j];
              d.g = data[i + 1] - data[j + 1];
              d.b = data[i + 2] - data[j + 2];

              dist += Math.sqrt(d.r * d.r + d.g * d.g + d.b * d.b);
              count += 1;
            }

            const pixel = {};
            pixel.p = i;
            pixel.d = _.floor(dist / count);
            distances.push(pixel);
          }

          distances.sort((a, b) => a.d - b.d);

          const topPixel = distances[0];
          const rgb = [data[topPixel.p], data[topPixel.p + 1], data[topPixel.p + 2]];

          return rgb;
        };

        return clrfy(canvasData.data);
      },
      similarColorMd(c) {
        const mdPalette = $mdColorPalette;
        const sParentColor = _.map(mdPalette, (r, key) => {
          const sTone = _.transform(r, (res, d, t) => {
            const result = Math.sqrt(
              pow(d.value[0] - c[0], 2) + pow(d.value[1] - c[1], 2) + pow(d.value[2] - c[2], 2)
            );
            res.push({ key, t, result });

            return _.head(t) !== 'A';
          }, []);

          return _.minBy(sTone, (i) => i.result);
        });

        return _.minBy(sParentColor, (i) => i.result);
      },
      imageToPalette(img) {
        const dominant = this.colorify(img);

        const getColor = this.similarColorMd(dominant);
        const colorPalette = getColor.key;
        let tone = _.toNumber(getColor.t);
        tone = tone === 50 ? 0 : tone;
        tone = tone < 500 ? tone + 400 : tone - 400;

        const color = $mdColorPalette[colorPalette][tone];

        return {
          dominant,
          name: colorPalette,
          color,
          tone,
          palette: $mdColorPalette[colorPalette],
        };
      },
      urlToPalette(url) {
        return $q((resolve, reject) => {
          let img = new Image(CANVAS_RATIO, CANVAS_RATIO);
          img.src = url;

          angular.element(img)
            .bind('load', () => {
              try {
                const p = this.imageToPalette(img);
                img = null;

                resolve(p);
              } catch (e) {
                $document.find('canvas').remove();
                img = null;

                reject();
              }
            })
            .bind('error', reject);
        });
      },
    };
  },
]);
