const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('labServiceClientController', [
  '$scope',
  '$stateParams',
  'Client',
  function labServiceClientController(
    $scope,
    $stateParams,
    Client
  ) {
    $scope.promise = $scope.conn.$promise.then(() => {
      $scope.resellerName = $stateParams.resellerName;
      Client.setToken($scope.conn.sandbox.token);
      $scope.clients = Client.query({ resellerName: $stateParams.resellerName });

      return $scope.clients.$promise;
    }).then((res) => {
      $scope.total = _.reduce(res, (acc, client) => ({
        storage: {
          usage: acc.storage.usage + client.storage.usage,
          limit: acc.storage.limit + client.storage.limit,
        },
      }), { storage: { usage: 0, limit: 0 } });
    });
  },
]);
