const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('listController', [
  '$scope',
  '$stateParams',
  '$q',
  '$mdDialog',
  'resource',
  'auth',
  'colorPalette',
  'mirror',
  function listController(
    $scope,
    $stateParams,
    $q,
    $mdDialog,
    resource,
    auth,
    colorPalette,
    mirror
  ) {
    $scope.user = auth.user;
    $scope.imgLoader = false;

    const imgPromises = (conns) => _.transform(conns, (result, conn) => {
      if (conn.logo) {
        result.push(colorPalette.urlToPalette(conn.logo)
          .then((palette) => {
            conn.palette = conn.logo_placeholder ? '' : `${palette.name}-${palette.tone}`;

            return palette;
          })
          .catch((palette) => _.identity(palette))
        );
      }
    });

    const connectors = resource.query({ 'company-detail': true }).$promise
      .then((conns) => {
        $scope.conns = conns;

        return $q.all(imgPromises($scope.conns))
          .then(() => {
            $scope.imgLoader = true;
          })
          .catch(() => {
            $scope.imgLoader = true;
          });
      });

    mirror({ connectors, imgPromises }, $scope);

    $scope.promise = $q.all({
      connectors,
      companies: auth.fetchCompanies(),
    });

    $scope.promise.then(() => {
      if (auth.user.companies.length && $stateParams.firstCompany) {
        $mdDialog.show(
          $mdDialog
            .alert()
            .parent(angular.element(document.querySelector('#newCompanyCard')))
            .clickOutsideToClose(true)
            .title('Congratulations!')
            .theme('customTheme')
            .htmlContent(
              `Company "${auth.user.companies[0].title}" was successfully created.<br/>` +
              'You are an administrator and can now invite additional users by clicking<br/>' +
              'on the Accounts item of the top-level navigation menu.'
            )
            .ok('Ok')
        );
      }
    });
  },
]);
