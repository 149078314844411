const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('commitOverlayController', [
  '$scope',
  '$injector',
  function commitOverlayController(
    $scope,
    $injector
  ) {
    if ($injector.has('commitHash')) {
      const rev = $injector.get('commitHash');

      $scope.commitHash = rev;
    } else {
      $scope.commitHash = 'None';
    }
  },
]);
