const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Authentication', [
  '$resource',
  'config',
  'baseModel',
  function AuthenticationModel(
    $resource,
    config,
    Base
  ) {
    class Authentication extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/authentication/';

        this.actions = _.extend({}, this.actions, {
          login: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/authentication/login/`,
          },

          logout: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/authentication/logout/`,
          },
        });

        return this.factory();
      }
    }

    return new Authentication();
  },
]);
