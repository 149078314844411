/*
*   StubPromise: Service to mock promise-chains for unit tests
*   (for .then(), .catch(), .finally(), .when() and .$promise)
*
*   Use as: spyOn(funcToReturnPromise).and.returnValue(stubPromise());
*
*   1. stubPromise() - calls whole promise-chain at once
*      with no data as parameter
*
*   2. stubPromise(data) - calls whole promise chain with "data" as parameter of first step.
*      Each next step will get result of previous one as parameter.
*
*   3. stubPromise(data, true) - calls whole promise chain with the same parameter for each step.
*
*   4. stubPromise.then(data),
*      stubPromise.catch(data),
*      stubPromise.finally(data) - calls part of promise-chain separately with "data" as param,
*      skips other parts with empty callbacks
*
*   5. stubPromise.through() - calls chain with empty callbacks
*/

const angular = require('angular');

const app = angular.module('sweeft-spa');

const stubPromise = (data, goThroughWithValue) => {
  if (goThroughWithValue) {
    return {
      then: callback => { callback(data); return stubPromise(data, true); },
      catch: callback => { callback(data); return stubPromise(data, true); },
      when: callback => { callback(data); return stubPromise(data, true); },
      finally: callback => { callback(data); },
      $promise: {
        then: callback => { callback(data); return stubPromise(data, true); },
        catch: callback => { callback(data); return stubPromise(data, true); },
        when: callback => { callback(data); return stubPromise(data, true); },
        finally: callback => { callback(data); },
      },
    };
  }

  return {
    then: callback => stubPromise(callback(data)),
    catch: callback => stubPromise(callback(data)),
    when: callback => stubPromise(callback(data)),
    finally: callback => callback(data),
    $promise: {
      then: callback => stubPromise(callback(data)),
      catch: callback => stubPromise(callback(data)),
      when: callback => stubPromise(callback(data)),
      finally: callback => callback(data),
    },
  };
};

const stub = (data, goThroughWithValue) =>
  stubPromise(data, goThroughWithValue);

stub.then = (data) => ({
  then: callback => stub.then(callback(data)),
  catch: () => stub.then(data),
  when: () => stub.then(data),
  finally: () => stub.then(data),
  $promise: {
    then: callback => stub.then(callback(data)),
    catch: () => stub.then(data),
    when: () => stub.then(data),
    finally: () => stub.then(data),
  },
});

stub.catch = (data) => ({
  then: () => stub.catch(data),
  catch: callback => stub.catch(callback(data)),
  when: () => stub.catch(data),
  finally: () => stub.catch(data),
  $promise: {
    then: () => stub.catch(data),
    catch: callback => stub.catch(callback(data)),
    when: () => stub.catch(data),
    finally: () => stub.catch(data),
  },
});

stub.finally = (data) => ({
  then: () => stub.finally(data),
  catch: () => stub.finally(data),
  when: () => stub.finally(data),
  finally: callback => stub(callback(data)),
  $promise: {
    then: () => stub.finally(data),
    catch: () => stub.finally(data),
    when: () => stub.finally(data),
    finally: callback => stub(callback(data)),
  },
});

stub.through = () => ({
  then: () => stub.through(),
  catch: () => stub.through(),
  when: () => stub.through(),
  finally: () => stub.through(),
  $promise: {
    then: () => stub.through(),
    catch: () => stub.through(),
    when: () => stub.through(),
    finally: () => stub.through(),
  },
});

app.factory('stubPromise', () => stub);
