const angular = require('angular');


const app = angular.module('sweeft-spa');


function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    /* globals unescape */
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}


app.service('convert', [
  '$q',
  function convertService(
    $q
  ) {
    return {
      dataURItoPng(uri) {
        const img = new Image();
        const canvas = angular.element('<canvas></canvas>')[0];
        const ctx = canvas.getContext('2d');

        img.setAttribute('src', uri);

        return $q((resolve, reject) => {
          img.onload = function imgOnload() {
            canvas.width = img.width;
            canvas.height = img.height;

            try {
              ctx.drawImage(this, 0, 0);

              resolve(dataURItoBlob(canvas.toDataURL('image/png')));
            } catch (e) {
              reject(e);
            }
          }.bind(img);
        });
      },
    };
  },
]);
