const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.directive('uppercase', [
  function uppercase() {
    return {
      require: 'ngModel',
      scope: {
        ngModel: '=',
      },
      link(scope, el, attrs, ngModel) {
        const uppercaseText = (inputValue) => {
          let newinputValue = inputValue;

          if (_.isUndefined(newinputValue)) {
            newinputValue = '';
          }

          const uppercased = newinputValue.toUpperCase().replace(/\s/g, '_');

          if (uppercased !== newinputValue) {
            ngModel.$setViewValue(uppercased);
            ngModel.$render();
          }

          return uppercased;
        };

        ngModel.$parsers.push(uppercaseText);

        scope.$watch('ngModel', () => {
          uppercaseText(scope[attrs.ngModel]);
        });
      },
    };
  },
]);
