const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('emailExists', [
  '$q',
  'asyncValidatorFactory',
  'User',
  function emailExists(
    $q,
    asyncValidatorFactory,
    User
  ) {
    return asyncValidatorFactory({
      delay: 3000,
      name: 'emailExists',
      validate(email) {
        return $q((resolve, reject) => {
          User.query({ email }, () => {
            resolve();
          }, () => {
            reject();
          });
        });
      },
    });
  },
]);
