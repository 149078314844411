const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ConnectorApi', [
  '$resource',
  '$http',
  '$q',
  'config',
  'baseModel',
  function ConnectorApiModel(
    $resource,
    $http,
    $q,
    config,
    Base
  ) {
    class ConnectorApi extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        const status = {
          NOT_INSTALLED: 0,
          INSTALLING: 1,
          INSTALLED: 2,
          ERROR: 3,
        };

        this.actions = _.extend({}, this.actions);

        class ConnectorApiResource extends this.factory() {
          init() {
            this.$status = status;
          }
        }

        ConnectorApiResource.$status = status;

        return ConnectorApiResource;
      }
    }

    return new ConnectorApi();
  },
]);
