const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('uniqueConnectorTitle', [
  '$q',
  'asyncValidatorFactory',
  'Connector',
  function uniqueEmail(
    $q,
    asyncValidatorFactory,
    Connector
  ) {
    return asyncValidatorFactory({
      name: 'uniqueConnectorTitle',
      validate(title) {
        return $q((resolve, reject) => {
          Connector.checkTitle({ title }, ({ exists }) => {
            if (!exists) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    });
  },
]);
