const angular = require('angular');

const app = angular.module('sweeft-spa');


app.controller('connListController', [
  '$scope',
  '$controller',
  '$state',
  '$stateParams',
  '$q',
  '$mdDialog',
  'Connector',
  'auth',
  'FederatedConnector',
  function connListController(
    $scope,
    $controller,
    $state,
    $stateParams,
    $q,
    $mdDialog,
    Connector,
    auth,
    FederatedConnector
  ) {
    const parent = $controller('listController', {
      $scope,
      $stateParams,
      $q,
      $mdDialog,
      resource: Connector,
      auth,
    });

    angular.extend(this, parent);

    if (auth.user.isVendor) {
      $state.go('isv.connector.list');
      $scope.profileState = 'isv.connector.edit.profile';
    } else {
      $state.go('provider.connector.list');
      $scope.profileState = 'provider.connector.edit.profile';
    }

    $scope.requests = FederatedConnector.query({
      status: FederatedConnector.$status.REQUESTED,
    });

    $scope.auth = auth;


    $scope.creatingConnector = false;
    $scope.creatingCompany = false;

    $scope.startAddConnector = () => {
      if ($scope.creatingConnector) {
        return;
      }

      $scope.creatingConnector = true;

      $mdDialog
        .show({
          onRemoving: () => {
            auth.fetchCompanies().then(() => {
              $scope.companies = auth.user.companies;
            });
          },
          controller: 'connNewController',
          templateUrl: 'dist/templates/dialogs/createConnector.html',
        })
        .finally(() => ($scope.creatingConnector = false));
    };

    $scope.startAddCompany = ($event) => {
      if ($scope.creatingCompany) {
        return;
      }

      $scope.creatingCompany = true;

      $mdDialog
        .show({
          clickOutsideToClose: true,
          targetEvent: $event,
          onRemoving: () => {
            auth.fetchCompanies().then(() => {
              $scope.companies = auth.user.companies;
            });
          },
          onComplete: () => {
            $scope.creatingCompany = false;
          },
          controller: 'companyCreateDialogController',
          templateUrl: 'dist/templates/dialogs/createCompany.html',
        });
    };

    $scope.getTab = (user, conn) => {
      if (user.isVendor) {
        const tabName = conn.fm_type ? 'profile' : 'guide';

        return `isv.connector.edit.${tabName}({connId: conn.id})`;
      }

      return 'provider.connector.edit.profile({connId: conn.id})';
    };
  },
]);
