const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditApiController', [
  '$log',
  '$scope',
  '$timeout',
  '$q',
  'SandboxService',
  'errorMessages',
  'mirror',
  function connEditApiController(
    $log,
    $scope,
    $timeout,
    $q,
    SandboxService,
    errorMessages,
    mirror
  ) {
    $scope.forms = {};
    $scope.sandboxConn = {};
    $scope.errorMessages = errorMessages;

    // Data to load
    const getSandbox = () => $scope.getSandboxStatus()
      .then((sandbox) => {
        $scope.checkSandboxStatus(sandbox);

        return sandbox;
      });

    const getConfiguration = () => $scope.conn.$promise
      .then((conn) => {
        if (!conn.master) {
          return conn.configuration;
        }

        return conn.getConfiguration();
      })
      .then((config) => {
        $scope.conn.configuration = config;
        $scope.tabs.active = $scope.tabs.open = $scope.checkMode(config);
        $scope.tabs.development.fill(config);
        $scope.tabs.publish.fill(config);
      });

    const getSandboxConfiguration = () => $scope.conn.$promise
      .then((conn) => SandboxService.check({ connId: conn.id }).$promise)
      .then((sandboxConnector) => {
        $scope.tabs.simulator.fill(sandboxConnector.configuration);
        $scope.sandboxConn = sandboxConnector;
      });

    mirror({
      getSandbox,
      getConfiguration,
      getSandboxConfiguration,
    }, $scope);

    $scope.promise = $scope.conn.$promise
      .then((conn) => {
        if (!conn.master || !conn.sandbox) {
          return getConfiguration();
        }

        return $q.all([
          getSandbox(),
          getConfiguration(),
          getSandboxConfiguration(),
        ]);
      });

    $scope.$on('$destroy', $scope.clearPollTimeouts);
    $scope.$on('version:change', () => getConfiguration());

    // helpers
    $scope.mod = {
      SIMULATOR: 'simulator',
      DEVELOPMENT: 'development',
      PUBLISH: 'publish,',
    };

    $scope.isModeChangeEnabled = () => $scope.tabs.status.enabled
      && $scope.statusChecks.publicationReady !== false
      && $scope.statusChecks.previewReady !== false;

    $scope.resetApiURL = () => $scope.conn.configuration.constructor.prototype
      .$resetConnectorURL({ id: $scope.conn.configuration.id });

    $scope.checkMode = (config) => {
      if (config.is_mock) {
        return $scope.mod.SIMULATOR;
      } else if (config.is_image) {
        return $scope.mod.PUBLISH;
      }

      return $scope.mod.DEVELOPMENT;
    };

    $scope.toggleEditor = (mode) => (mode === 'view' ? 'code' : 'view');

    // TABS MODEL
    $scope.tabs = {
      status: {
        inProgress: false,
        enabled: true,

        freeze(isFreeze) {
          this.enabled = !isFreeze;
          this.inProgress = isFreeze;
        },
      },
      active: $scope.mod.SIMULATOR,
      open: $scope.mod.SIMULATOR,
      simulator: {
        showDetails: false,
        model: {
          image: null,
          config: null,
          healthCheckPath: null,
          rootPath: null,
        },
        fill(config) {
          this.model.image = config.image;
          this.model.config = config.config;
          this.model.healthCheckPath = config.healthcheck_path;
          this.model.rootPath = config.root_path;
        },
        apply() {
          $scope.conn.configuration.image = this.model.image;
          $scope.conn.configuration.config = this.model.config;
          $scope.conn.configuration.healthcheck_path = this.model.healthCheckPath;
          $scope.conn.configuration.root_path = this.model.rootPath;
        },
      },
      development: {
        showSecret: false,
        model: {
          url: null,
          oauth_key: null,
          oauth_secret: null,
        },
        fill(config) {
          this.model.url =
            $scope.tabs.active === $scope.mod.DEVELOPMENT ? config.url : null;
          this.model.oauth_key = config.oauth_key;
          this.model.oauth_secret = config.oauth_secret;
        },
        apply() {
          $scope.conn.configuration.url = this.model.url;
          $scope.conn.configuration.oauth_key = this.model.oauth_key;
          $scope.conn.configuration.oauth_secret = this.model.oauth_secret;
        },
      },
      publish: {
        advancedSettings: false,
        editJson: 'view',
        model: {
          image: '',
          config: {},
          healthCheckPath: '/',
          rootPath: '/',
        },
        fill(config) {
          if (config.is_image) {
            this.model.image = config.image;
            this.model.config = config.config;
            this.model.healthCheckPath = config.healthcheck_path;
            this.model.rootPath = config.root_path;
          } else {
            this.model.image = '';
            this.model.config = {};
            this.model.healthCheckPath = '/';
            this.model.rootPath = '/';
          }
        },
        apply() {
          $scope.conn.configuration.image = this.model.image;
          $scope.conn.configuration.config = this.model.config;
          $scope.conn.configuration.healthcheck_path = this.model.healthCheckPath;
          $scope.conn.configuration.root_path = this.model.rootPath;
        },
      },
    };

    $scope.saveConfiguration = (mode) => {
      $scope.tabs.status.freeze(true);

      if (mode === $scope.mod.SIMULATOR) {
        $scope.conn.configuration.is_image = false;
        $scope.tabs.simulator.apply();
      } else if (mode === $scope.mod.DEVELOPMENT) {
        $scope.conn.configuration.is_image = false;
        $scope.tabs.development.apply();
      } else if (mode === $scope.mod.PUBLISH) {
        $scope.conn.configuration.is_image = true;
        $scope.tabs.publish.apply();
      } else {
        return $q.reject();
      }

      return $scope.conn.configuration.$update({
        id: $scope.conn.configuration.id,
      }).finally(() => {
        $scope.tabs.status.freeze(false);
      });
    };

    $scope.apply = (mode) => {
      $scope.saveConfiguration(mode)
        .then(() => {
          if (mode === $scope.mod.SIMULATOR) {
            return $scope.resetApiURL();
          }

          return $q.when();
        })
        .then(() => {
          $scope.tabs.active = mode;
        });
    };

    $scope.$watch('tabs.development.model.url', () => {
      if ($scope.forms.apiUrl) {
        $scope.forms.apiUrl.url.$setTouched();
      }
    });
  },
]);
