const angular = require('angular');

const app = angular.module('apsclassic-spa', []);

app.config(['$stateProvider', function stateConfiguration($stateProvider) {
  $stateProvider
    .state('classicApp', {
      abstract: true,
      template: '<ui-view/>',
      url: '/classic-app',
    })
    .state('classicApp.list', {
      url: '/',
      controller: 'classicAppListController',
      templateUrl: 'templates/isv/application/list.html',
    })
    .state('classicApp.new', {
      url: '/new',
      controller: 'classicAppNewController',
      templateUrl: 'templates/isv/application/new.html',
    })
    .state('classicApp.edit', {
      url: '/:appId',
      controller: 'classicAppEditController',
      templateUrl: 'templates/apsclassic/base.html',
    })
    .state('classicApp.edit.profile', {
      url: '/profile',
      controller: 'classicAppEditProfileController',
      templateUrl: 'templates/apsclassic/profile.html',
      data: {
        title: 'Profile',
        icon: 'assignment',
      },
    })
    .state('classicApp.edit.review', {
      url: '/review',
      controller: 'classicAppEditReviewController',
      templateUrl: 'templates/apsclassic/review.html',
      data: {
        title: 'Review',
        icon: 'check_box',
      },
    })
    .state('classicApp.edit.reviewHistory', {
      url: '/review-history',
      controller: 'classicAppEditReviewController',
      templateUrl: 'templates/apsclassic/reviewHistory.html',
      data: {
        title: 'Audit',
        icon: 'history',
      },
    })
    .state('classicApp.edit.reviewHistoryItem', {
      url: '/review-history/:revId',
      controller: 'classicAppEditReviewController',
      templateUrl: 'templates/apsclassic/reviewHistoryItem.html',
      data: {
        title: 'Review',
      },
    })
    .state('classicApp.edit.packages', {
      url: '/packages',
      controller: 'classicAppEditPackagesController',
      templateUrl: 'templates/apsclassic/packages.html',
      data: {
        title: 'Packages',
        icon: 'folder',
      },
    })
    .state('classicApp.edit.package', {
      url: '/packages/:packageId',
      controller: 'classicAppEditPackagesController',
      templateUrl: 'templates/apsclassic/package.html',
      data: {
        title: 'Package Details',
      },
    });
}]);

module.exports = 'apsclassic-spa';
