const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('BucketCreds', [
  '$resource',
  'config',
  'baseModel',
  function BucketCredsModel(
    $resource,
    config,
    Base
  ) {
    class BucketCredsResource extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/bucket-creds/:id/';

        this.actions = _.extend({}, this.actions);

        return this.factory();
      }
    }

    return new BucketCredsResource();
  },
]);
