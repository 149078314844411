const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('labServiceLogsController', [
  '$q',
  '$scope',
  '$state',
  '$timeout',
  'polling',
  'TestLabLogs',
  function labServiceLogsController(
    $q,
    $scope,
    $state,
    $timeout,
    polling,
    TestLabLogs
  ) {
    $scope.entries = [];

    $scope.logs = TestLabLogs.serviceLogsFactory();

    $scope.getForemost = () => {
      const sandbox = $scope.conn.sandbox.instance;

      return $scope.logs.getLogs({
        service: sandbox.service,
        version: sandbox.version,
        project: sandbox.project,
        token: $scope.conn.sandbox.token,
      });
    };
    $scope.runPolling = (entries) => {
      $scope.entries = entries;
      $scope.entriesLength = $scope.logs.serviceLogsLength;

      polling.add(() => {
        const poll = $scope.logs.pollLogs().then((updateEntries) => {
          $scope.entries = updateEntries.concat($scope.entries).slice(0, 100);
          $scope.entriesLength = $scope.logs.serviceLogsLength;
        });

        $scope.pollingPromise = $q.all([poll.$promise, $timeout(_.noop, 1000)]);

        return poll;
      }, 5000);
    };

    $scope.startLogsPolling = () => $q.all([$scope.conn.$promise, $scope.logs.$promise])
      .then($scope.getForemost)
      .then($scope.runPolling);

    $scope.promise = $scope.checkSandbox()
      .then((status) => {
        if ($scope.sandboxIsReady()) {
          return $scope.startLogsPolling();
        }

        $scope.checkSandboxStatus(status).then($scope.startLogsPolling);

        return true;
      });

    $scope.$on('$destroy', $scope.clearPollTimeouts);
  },
]);
