const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('userProfileController', [
  '$scope',
  '$q',
  '$mdDialog',
  '$state',
  '$stateParams',
  '$location',
  'Company',
  'User',
  'auth',
  function toolbarController(
    $scope,
    $q,
    $mdDialog,
    $state,
    $stateParams,
    $location,
    Company,
    User,
    auth
  ) {
    $scope.getCompany = () => Company.get({ id: auth.user.companies[0].id }).$promise
      .then((company) => {
        const users = _.filter(company.users, 'is_active');
        $scope.isLastUser = () => users.length === 1;
      });

    $scope.promise = auth.user.companies.length ? $scope.getCompany() : $q.when();

    $scope.user = auth.user;
    $scope.helpers = {
      cancel: $mdDialog.cancel,
      hide: $mdDialog.hide,
      process: false,
    };

    $scope.emailChangeCode = $stateParams['change-email'] || null;

    $scope.change = (type, event) => $mdDialog.show({
      clickOutsideToClose: true,
      controller: `change${type}AccountController`,
      controllerAs: 'ctrl',
      focusOnOpen: false,
      targetEvent: event,
      locals: { helpers: $scope.helpers },
      templateUrl: `dist/templates/dialogs/profile/change${type}.html`,
    });

    $scope.confirmEmail = (code) => {
      if ($scope.helpers.process) {
        return $q.when();
      }

      $scope.helpers.process = true;

      return User
        .confirmEmail({ code })
        .$promise
        .then((res) => {
          if (res.email) {
            auth.user.email = res.email;
            auth.loginAs(auth.user);
          }

          $mdDialog.show(
            $mdDialog.alert()
              .theme('customTheme')
              .title('Email changed')
              .textContent('Your email was successfully changed.')
              .ok('Ok')
          );

          $state.go($state.current.name, { 'change-email': null }, { notify: false });
        })
        .catch((error) => {
          if (error.status !== 401) {
            $mdDialog.show(
              $mdDialog.alert()
                .theme('customTheme')
                .title('Email change error')
                .textContent(
                  error.data && (error.data.error || error.data.code) ||
                    'Failed to change email. Please try again later.'
                )
                .ok('Ok')
            );

            $state.go($state.current.name, { 'change-email': null }, { notify: false });
          }
        })
        .finally(() => {
          $scope.helpers.process = false;
        });
    };

    if ($scope.emailChangeCode) {
      $scope.promise = $scope.promise.then(() => $scope.confirmEmail($scope.emailChangeCode));
    }
  },
]);
