const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connectorRequestListController', [
  '$scope',
  '$q',
  'FederatedConnector',
  'auth',
  function connectorRequestListController(
    $scope,
    $q,
    FederatedConnector,
    auth
  ) {
    $scope.user = auth.user;

    $scope.promise = FederatedConnector.query({
      status: FederatedConnector.$status.REQUESTED,
    }).$promise;

    $scope.promise.then((requests) => {
      $scope.requests = requests;
    });
  },
]);
