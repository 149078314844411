const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditVersionsController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  'Connector',
  function connEditVersionsController(
    $scope,
    $state,
    $stateParams,
    $q,
    Connector
  ) {
    $scope.stateSandboxStatus();

    $scope.isPublished = (version) => version.pub_status === 'published';

    $scope.inspect = (item) => {
      const conn = $scope.changeVersion(item);
      $scope.loadingIndicator(conn.$promise)
        .then(() => {
          $state.go('isv.connector.edit.guide');
        });
    };

    $scope.publish = (version) => {
      Connector.setPubStatus({ versionId: version.id }, { pub_status: 'published' })
        .$promise.then(() => { version.pub_status = 'published'; });
    };

    $scope.unpublish = (version) => {
      Connector.setPubStatus({ versionId: version.id }, { pub_status: 'unpublished' })
        .$promise.then(() => { version.pub_status = 'unpublished'; });
    };
  },
]);
