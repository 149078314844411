const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.factory('ClientUser', [
  'BaseFallball',
  'config',
  function ClientUserModel(
    BaseFallball,
    config
  ) {
    class ClientUser extends BaseFallball {
      /* istanbul ignore next */
      constructor() {
        super();
        this.baseUrl = config.fallballBaseUrl;
        this.url = '/resellers/:resellerName/clients/:clientName/users/:email/';

        this.actions = _.extend({}, this.actions, {
          login: {
            method: 'GET',
            url: `${this.baseUrl}${this.url}link/`,
            headers: {
              Authorization: () => `Token ${this.token}`,
              Accept: 'text/plain',
            },
            transformResponse: (data) => ({ link: data }),
          },
        });

        const clientUser = this;

        return class extends this.factory() {
          static setToken(token) {
            clientUser.token = token;
          }
        };
      }
    }

    return new ClientUser();
  },
]);
