const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('connEditInstallationsControllerEdit', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  '$mdDialog',
  'InstallationRequest',
  'errorMessages',
  'Configuration',
  function connEditInstallationsControllerEdit(
    $scope,
    $state,
    $stateParams,
    $q,
    $mdDialog,
    InstallationRequest,
    errorMessages,
    Configuration
  ) {
    const showConfirmation = function showConfirmation($event, templateName) {
      return $mdDialog.show({
        clickOutsideToClose: true,
        scope: $scope,
        preserveScope: true,
        targetEvent: $event,
        templateUrl: `dist/templates/dialogs/${templateName}.html`,
      });
    };

    $scope.errorMessages = errorMessages;

    const request = InstallationRequest.get({ id: $stateParams.requestId });
    $scope.request = request;

    $scope.promise = request.$promise.then((req) => {
      let state;

      if (req.status === req.statuses.APPROVED) {
        state = 'isv.connector.edit.installationsApproved';
      }

      if (req.status === req.statuses.REJECTED) {
        state = 'isv.connector.edit.installationsRejected';
      }

      if (state && state !== $state.current.name) {
        return $state.go(state, { requestId: req.id });
      }

      const connector = req.app;

      $scope.connectorVersion = connector.master
        ? `Version ${connector.release - 1} (Always latest)`
        : `Version ${connector.release}`;

      return $q.when();
    });

    $scope.forms = {};
    $scope.approving = false;
    $scope.rejecting = false;

    $scope.confirmRequestReject = ($event) => {
      $scope.message = '';
      showConfirmation($event, 'rejectRequest');
    };

    $scope.confirmRequestApproval = $event => showConfirmation($event, 'approveRequest');
    $scope.cancel = $mdDialog.cancel;

    $scope.rejectRequest = () => {
      if ($scope.rejecting) {
        return $q.when();
      }

      $scope.rejecting = true;

      return InstallationRequest.reject({ id: request.id }, { message: $scope.message }).$promise
        .then(() => $state.go('isv.connector.edit.installations'))
        .catch((res) => {
          const error = _.get(res, 'data.error', 'Sorry, something went wrong');
          $mdDialog.show(
            $mdDialog
              .alert()
              .clickOutsideToClose(true)
              .theme('customTheme')
              .content(error)
              .ok('Ok')
          );
        })
        .finally(() => ($scope.rejecting = false));
    };

    $scope.approveRequest = () => {
      if ($scope.approving) {
        return $q.when();
      }

      $scope.approving = true;

      request.configuration.config = _.assign(
        {},
        request.app.configuration.config,
        {
          oauth_key: request.configuration.oauth_key,
          oauth_secret: request.configuration.oauth_secret,
        },
        request.configuration.config
      );

      return Configuration.patch(
        { id: request.configuration.id },
        {
          config: request.configuration.config,
          image: request.app.configuration.image,
          root_path: request.app.configuration.root_path,
        }
      ).$promise
        .then(() => InstallationRequest.approve({ id: request.id }, {}).$promise)
        .then(() => $state.go('isv.connector.edit.installations'))
        .catch((res) => {
          const error = _.get(res, 'data.error', 'Sorry, something went wrong');
          $mdDialog.show(
            $mdDialog
              .alert()
              .clickOutsideToClose(true)
              .theme('customTheme')
              .content(error)
              .ok('Ok')
          );
        })
        .finally(() => ($scope.approving = false));
    };
  },
]);
