const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('hubConnectorsListController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  '$mdToast',
  '$mdDialog',
  'InstallationRequest',
  'colors',
  function hubConnectorsListController(
    $scope,
    $state,
    $stateParams,
    $q,
    $mdToast,
    $mdDialog,
    InstallationRequest,
    colors
  ) {
    $scope.reqIsPending = (req) => req.isStatus(req.statuses.PENDING);
    $scope.reqIsApproved = (req) => req.isStatus(req.statuses.APPROVED);
    $scope.reqIsRejected = (req) => req.isStatus(req.statuses.REJECTED);

    $scope.iconOnState = (req) => {
      const statuses = req.statuses;
      const icons = {
        [statuses.PENDING]: { icon: 'info', style: { color: colors.disabled } },
        [statuses.APPROVED]: { icon: 'check_circle', style: { color: colors.theme } },
        [statuses.REJECTED]: { icon: 'warning', style: { color: colors.failed } },
      };

      return icons[req.status];
    };

    $scope.textOnState = (req) => {
      const statuses = req.statuses;
      const texts = {
        [statuses.PENDING]: 'Installation request pending',
        [statuses.APPROVED]: 'Installed',
        [statuses.REJECTED]: 'Rejected',
      };

      return texts[req.status];
    };

    $scope.deleteRequest = ($event, req, index) => {
      if ($scope.deleting) {
        return;
      }

      $scope.deleting = true;

      const confirm = $mdDialog
        .confirm()
        .targetEvent($event)
        .theme('customTheme')
        .clickOutsideToClose(true)
        .title('Delete request')
        .textContent('Please confirm the operation. The request will be deleted.')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(() => InstallationRequest.delete({ id: req.id }).$promise)
        .then(() => {
          $scope.requests.splice(index, 1);
        })
        .catch((err = {}) => {
          if (err.statusText) {
            const toast = $mdToast
              .simple()
              .textContent('Sorry, something went wrong')
              .position('bottom right')
              .hideDelay(5000)
              .theme('customTheme');

            $mdToast.show(toast);
          }
        })
        .finally(() => ($scope.deleting = false));
    };
    $scope.requests = InstallationRequest.query({ oa_instance: $stateParams.hubId });

    $scope.promise = $q.all([
      $scope.connectors.$promise,
      $scope.requests.$promise,
    ])
      .then(() => {
        $scope.isAnyConnectors = () => $scope.connectors.length > 0;
        $scope.isAnyRequests = () => $scope.requests.length > 0;
      });
  },
]);
