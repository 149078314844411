const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');

const stringify = _.ary(JSON.stringify, 1);

app.factory('User', [
  '$resource',
  'config',
  'baseModel',
  function UserModel(
    $resource,
    config,
    Base
  ) {
    class User extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/user/';
        this.isVendor = this.type === 'isv';
        this.isProvider = this.type === 'isp';

        this.paramDefaults = {
          id: '@id',
        };

        this.actions = _.extend({}, this.actions, {
          verify: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/verify/`,
          },

          activate: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/activate/`,
          },

          checkCode: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/check-code/`,
          },

          forgotPassword: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/forgot-password/`,
          },

          changeEmail: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/change-email/`,
          },

          confirmEmail: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/confirm-email/`,
          },

          changePassword: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/change-password/`,
          },

          resetPassword: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/reset-password/`,
          },

          fetchInfo: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/user/`,
          },

          deleteAccount: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/user/deactivate/`,
          },

          saveName: {
            method: 'PATCH',
            url: `${config.apiBaseUrl}/api/v1/user/:id`,
            transformRequest: stringify,
          },

          checkSession: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/user/check-session/`,
            transformResponse: [
              (data) => JSON.parse(data),
            ],
          },
        });

        return this.factory();
      }
    }

    return new User();
  },
]);
