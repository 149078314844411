const _ = require('lodash');
const angular = require('angular');
const dragula = require('angular-dragula');


require('angular-xeditable');
require('angular-google-analytics');
require('material-steppers');
require('ng-jsoneditor');


const app = angular.module('sweeft-spa', [
  require('angular-ui-router'),
  require('angular-material'),
  require('angular-messages'),
  require('angular-resource'),
  require('angular-cookies'),
  require('angular-sanitize'),
  require('angular-material-data-table'),
  require('ng-file-upload'),
  require('angular-highlightjs'),
  require('angular-animate'),
  require('apsclassic-spa'),
  'ng.jsoneditor',
  'mdSteppers',
  'angular-google-analytics',
  'yamlEditor',
  dragula(angular),
]);


app.config([
  '$windowProvider',
  ($windowProvider) => {
    $windowProvider.$get().JSONEditor = require('jsoneditor');
  },
]);


app.config(['$locationProvider', ($locationProvider) => {
  $locationProvider.hashPrefix('');
}]);


app.config([
  '$stateProvider',
  '$urlRouterProvider',
  '$urlMatcherFactoryProvider',
  function stateConfiguration(
    $stateProvider,
    $urlRouterProvider,
    $urlMatcherFactoryProvider
  ) {
    $urlMatcherFactoryProvider.strictMode(false);
    $urlRouterProvider.when(
      '/isv/edit/:appId/lab-service',
      '/isv/edit/:appId/lab-service/resellers'
    );
    $urlRouterProvider.otherwise(($injector, $location) => {
      $injector.get('$state').go('notFound');
      return $location.path();
    });

    const installationRequestStateData = {
      title: 'Installation Request',
      titleHelp: 'installations',
      parent: 'installations',
      breadcrumb: {
        url: 'isv.connector.edit.installations',
        title: 'Installations',
      },
    };

    $stateProvider
      .state('home', {
        url: '',
        controller: 'rootStateController',
        skipAuthentication: true,
      })
      .state('notFound', {
        templateUrl: 'dist/templates/notFound.html',
        skipAuthentication: true,
      })
      .state('isv', {
        abstract: true,
        url: '/isv',
        template: '<ui-view/>',
      })
      .state('isv.connector', {
        abstract: true,
        url: '',
        template: '<ui-view/>',
      })
      .state('isv.connector.list', {
        url: '/',
        params: {
          firstCompany: false,
        },
        controller: 'connListController',
        templateUrl: 'dist/templates/isv/connector/list.html',
      })
      .state('isv.connector.new', {
        url: '/new',
        controller: 'connNewController',
        templateUrl: 'dist/templates/isv/connector/new.html',
      })
      .state('isv.connector.edit', {
        url: '/edit/:connId',
        controller: 'connEditController',
        templateUrl: 'dist/templates/isv/connector/edit/base.html',
      })
      .state('isv.connector.edit.guide', {
        url: '/guide',
        controller: 'connEditGuideController',
        templateUrl: 'dist/templates/isv/connector/edit/guide.html',
        data: {
          title: 'Guide',
          titleHelp: 'guide',
          icon: 'navigation',
          addClass: 'rotate_45',
        },
      })
      .state('isv.connector.edit.profile', {
        url: '/profile',
        controller: 'connEditProfileController',
        templateUrl: 'dist/templates/isv/connector/edit/profile.html',
        data: {
          title: 'Profile',
          titleHelp: 'profile',
          icon: 'assignment',
        },
      })
      .state('isv.connector.edit.settings', {
        url: '/settings',
        controller: 'connEditSettingsController',
        templateUrl: 'dist/templates/isv/connector/edit/settings.html',
        data: {
          title: 'Settings',
          titleHelp: 'settings',
          icon: 'settings',
          disableNetworkErrorToast: true,
        },
      })
      .state('isv.connector.edit.model', {
        url: '/model',
        controller: 'connEditModelController',
        templateUrl: 'dist/templates/isv/connector/edit/model.html',
        data: {
          title: 'Model',
          titleHelp: 'model',
          icon: 'dashboard',
          disableNetworkErrorToast: true,
        },
      })
      .state('isv.connector.edit.api', {
        url: '/api',
        controller: 'connEditApiController',
        templateUrl: 'dist/templates/isv/connector/edit/api/api.html',
        data: {
          title: 'API',
          titleHelp: 'connector',
          icon: 'import_export',
        },
      })
      .state('isv.connector.edit.ui', {
        url: '/ui',
        controller: 'connEditUIController',
        templateUrl: 'dist/templates/isv/connector/edit/ui.html',
        data: {
          title: 'UI',
          titleHelp: 'ui',
          icon: 'palette',
          disableNetworkErrorToast: true,
        },
      })
      .state('isv.connector.edit.preview', {
        url: '/preview',
        controller: 'connEditPreviewController',
        templateUrl: 'dist/templates/includes/preview.html',
        data: {
          title: 'Preview',
          titleHelp: 'preview',
          icon: 'play_circle_filled',
        },
      })
      .state('isv.connector.edit.publish', {
        url: '/publish',
        controller: 'connEditPublicationController',
        templateUrl: 'dist/templates/isv/connector/edit/publication/publication.html',
        data: {
          title: 'Publish',
          titleHelp: 'validate',
          icon: 'view_carousel',
        },
      })
      .state('isv.connector.edit.providers', {
        abstract: true,
        url: '/providers',
        controller: 'connEditProvidersController',
        template: '<ui-view/>',
        data: {
          parent: 'providers',
          icon: 'work',
        },
      })
      .state('isv.connector.edit.providers.list', {
        url: '/',
        controller: 'connEditProvidersControllerList',
        templateUrl: 'dist/templates/isv/connector/edit/providers/list.html',
        data: {
          title: 'Providers',
          titleHelp: 'providers',
        },
      })
      .state('isv.connector.edit.providers.new', {
        url: '/new',
        controller: 'connEditProvidersControllerNew',
        templateUrl: 'dist/templates/isv/connector/edit/providers/new.html',
        cache: false,
        data: {
          title: 'Invite Providers',
          titleHelp: 'providers',
        },
      })
      .state('isv.connector.edit.providers.edit', {
        url: '/edit/:invitationId',
        controller: 'connEditProvidersControllerEdit',
        templateUrl: 'dist/templates/isv/connector/edit/providers/edit.html',
        cache: false,
        data: {
          title: 'Provider Details',
          titleHelp: 'providers',
        },
      })
      .state('isv.connector.edit.versions', {
        url: '/versions',
        controller: 'connEditVersionsController',
        templateUrl: 'dist/templates/isv/connector/edit/versions.html',
        data: {
          title: 'Versions',
          titleHelp: 'versions',
          icon: 'format_list_numbered',
        },
      })
      .state('isv.connector.edit.installations', {
        url: '/installations',
        controller: 'connEditInstallationsController',
        templateUrl: 'dist/templates/isv/connector/edit/installations/list.html',
        data: {
          title: 'Installations',
          titleHelp: 'installations',
          parent: 'installations',
          icon: 'business',
        },
      })
      .state('isv.connector.edit.installationsEdit', {
        url: '/installations/:requestId',
        controller: 'connEditInstallationsControllerEdit',
        templateUrl: 'dist/templates/isv/connector/edit/installations/edit.html',
        data: installationRequestStateData,
      })
      .state('isv.connector.edit.installationsApproved', {
        url: '/installations/:requestId',
        controller: 'connEditInstallationsControllerEdit',
        templateUrl: 'dist/templates/isv/connector/edit/installations/approved.html',
        data: installationRequestStateData,
      })
      .state('isv.connector.edit.installationsRejected', {
        url: '/installations/:requestId',
        controller: 'connEditInstallationsControllerEdit',
        templateUrl: 'dist/templates/isv/connector/edit/installations/rejected.html',
        data: installationRequestStateData,
      })
      .state('isv.connector.edit.labService', {
        url: '/lab-service',
        controller: 'labServiceController',
        templateUrl: 'dist/templates/isv/connector/lab/labService.html',
        data: {
          title: 'Service',
          icon: 'extension',
          parent: 'labService',
        },
      })
      .state('isv.connector.edit.labDetails', {
        url: '/lab-service-details',
        controller: 'labDetailsController',
        templateUrl: 'dist/templates/isv/connector/lab/labDetails.html',
        data: {
          title: 'Details',
          icon: 'extension',
        },
      })
      .state('isv.connector.edit.labService.resellers', {
        url: '/resellers',
        controller: 'labServiceResellerController',
        templateUrl: 'dist/templates/isv/connector/lab/labService.resellers.html',
        cache: false,
      })
      .state('isv.connector.edit.labService.clients', {
        url: '/clients/:resellerName',
        controller: 'labServiceClientController',
        templateUrl: 'dist/templates/isv/connector/lab/labService.clients.html',
        cache: false,
      })
      .state('isv.connector.edit.labService.clientUsers', {
        url: '/client-users',
        params: {
          resellerName: null,
          clientName: null,
        },
        controller: 'labServiceClientUserController',
        templateUrl: 'dist/templates/isv/connector/lab/labService.clientUsers.html',
        cache: false,
      })
      .state('isv.connector.edit.labApiLogs', {
        url: '/lab-api-logs',
        controller: 'labApiLogsController',
        templateUrl: 'dist/templates/isv/connector/lab/labLogs.html',
        data: {
          title: 'API Logs',
          icon: 'content_paste',
        },
      })
      .state('isv.connector.edit.labServiceLogs', {
        url: '/lab-service-logs',
        controller: 'labServiceLogsController',
        templateUrl: 'dist/templates/isv/connector/lab/labLogs.html',
        data: {
          title: 'Service Logs',
          icon: 'content_paste',
        },
      })
      .state('isv.connector.edit.labRemove', {
        url: '/lab-remove',
        controller: 'labRemoveController',
        templateUrl: 'dist/templates/isv/connector/lab/labRemove.html',
        data: {
          title: 'Remove',
          icon: 'delete_forever',
        },
      })
      .state('isv.connector.previewRedirect', {
        url: '/preview/redirect/:connId/:version?locale',
        params: {
          locale: 'en_US',
        },
        controller: 'previewRedirectController',
        templateUrl: 'dist/templates/redirect.html',
        blankView: true,
      })
      .state('isv.connector.sandboxRedirect', {
        url: '/sandbox/redirect/:token/:resellerName/:clientName/:email',
        controller: 'sandboxRedirectController',
        templateUrl: 'dist/templates/redirect.html',
        blankView: true,
      })
      .state('activate', {
        url: '/activate?code',
        controller: 'activateController',
        templateUrl: 'dist/templates/user/activate.html',
        blankView: true,
      })
      .state('login', {
        url: '/login',
        controller: 'authController',
        templateUrl: 'dist/templates/login.html',
        skipAuthentication: true,
        blankView: true,
        params: {
          message: '',
          stateName: '',
          stateParams: { },
        },
        onEnter: [
          '$state', 'auth',
          ($state, auth) => {
            if (auth.user.isAuthenticated) {
              if (auth.user.isVendor) {
                $state.go('isv.connector.list');
              } else {
                $state.go('provider.connector.list');
              }
            }
          },
        ],
      })
      .state('logout', {
        url: '/logout',
        controller: 'logoutController',
      })
      .state('user-profile', {
        url: '/user-profile?change-email',
        controller: 'userProfileController',
        templateUrl: 'dist/templates/user-profile.html',
      })
      .state('forgotPassword', {
        url: '/forgot-password',
        controller: 'forgotPasswordController',
        templateUrl: 'dist/templates/user/forgot.html',
        skipAuthentication: true,
        blankView: true,
      })
      .state('resetPassword', {
        url: '/reset-password?code',
        controller: 'resetPasswordController',
        templateUrl: 'dist/templates/user/reset.html',
        skipAuthentication: true,
        reloadOnSearch: false,
        blankView: true,
      })
      .state('hub', {
        abstract: true,
        url: '/hub',
        template: '<ui-view/>',
        controller: 'hubController',
      })
      .state('hub.add', {
        url: '/add',
        controller: 'hubAddController',
        templateUrl: 'dist/templates/provider/hub/add.html',
      })
      .state('hub.list', {
        url: '',
        controller: 'hubListController',
        templateUrl: 'dist/templates/provider/hub/list.html',
      })
      .state('hub.edit', {
        url: '/:hubId',
        controller: 'hubEditController',
        templateUrl: 'dist/templates/isv/connector/edit/base.html',
      })
      .state('hub.edit.setup', {
        url: '/setup',
        controller: 'hubSetupController',
        templateUrl: 'dist/templates/provider/hub/setup.html',
        data: {
          pageLogo: 'hub',
          title: 'Setup',
          icon: 'assignment',
        },
      })
      .state('hub.edit.connectors', {
        abstract: true,
        url: '/connectors',
        template: '<ui-view/>',
        data: {
          parent: 'hubConnectors',
          pageLogo: 'hub',
          title: 'Connectors',
          icon: 'store',
        },
      })
      .state('hub.edit.connectors.list', {
        url: '/',
        controller: 'hubConnectorsListController',
        templateUrl: 'dist/templates/provider/hub/connectors/list.html',
        data: {
          pageLogo: 'hub',
          title: 'Connectors',
          icon: 'store',
        },
      })
      .state('hub.edit.connectors.selection', {
        url: '/selection',
        controller: 'hubConnectorsSelectionController',
        templateUrl: 'dist/templates/provider/hub/connectors/selection.html',
        data: {
          pageLogo: 'hub',
          title: 'Request installation',
          icon: 'store',
          breadcrumb: {
            url: 'hub.edit.connectors.list',
            title: 'Connectors',
          },
        },
      })
      .state('invite', {
        url: '/invitation-activate?app&code',
        controller: 'invitationController',
        templateUrl: 'dist/templates/provider/invite.html',
        blankView: true,
      })
      .state('company', {
        abstract: true,
        url: '/accounts',
        template: '<ui-view/>',
      })
      .state('company.list', {
        url: '/',
        controller: 'companyListController',
        templateUrl: 'dist/templates/company/list.html',
      })
      .state('company.create', {
        url: '/create',
        controller: 'companyCreateController',
        templateUrl: 'dist/templates/company/create.html',
      })
      .state('company.details', {
        url: '/company/:companyId',
        controller: 'companyInfoController',
        templateUrl: 'dist/templates/company/info.html',
      })
      .state('federated', {
        abstract: true,
        url: '/fm',
        template: '<ui-view/>',
      })
      .state('federated.requestList', {
        url: '/request',
        controller: 'connectorRequestListController',
        templateUrl: 'dist/templates/federated/requestList.html',
      })
      .state('federated.approveList', {
        url: '/approve',
        controller: 'connectorApproveListController',
        templateUrl: 'dist/templates/federated/approveList.html',
      })
      .state('federated.approveAdd', {
        url: '/approve/:requestId',
        controller: 'connectorApproveAddController',
        templateUrl: 'dist/templates/federated/approveAdd.html',
      });
  },
]);


app.config([
  '$stateProvider',
  '$urlRouterProvider',
  '$urlMatcherFactoryProvider',
  function stateConfiguration(
    $stateProvider,
    $urlRouterProvider,
    $urlMatcherFactoryProvider
  ) {
    $urlMatcherFactoryProvider.strictMode(false);
    $urlRouterProvider.when(
      '/isv/edit/:appId/lab-service',
      '/isv/edit/:appId/lab-service/resellers'
    );
    $urlRouterProvider.otherwise(($injector, $location) => {
      $injector.get('$state').go('notFound');
      return $location.path();
    });
    $stateProvider
      .state('provider', {
        abstract: true,
        url: '/provider',
        template: '<ui-view/>',
      })

      .state('provider.connector', {
        abstract: true,
        url: '',
        template: '<ui-view/>',
      })

      .state('provider.connector.list', {
        url: '/connectors',
        params: {
          firstCompany: false,
        },
        controller: 'connListController',
        templateUrl: 'dist/templates/isv/connector/list.html',
      })

      .state('provider.connector.edit', {
        url: '/edit/:connId',
        controller: 'connProviderController',
        templateUrl: 'dist/templates/isv/connector/edit/base.html',
      })

      .state('provider.connector.edit.profile', {
        url: '/profile',
        controller: 'connEditProfileController',
        templateUrl: 'dist/templates/provider/connector/edit/profile.html',
        data: {
          title: 'Profile',
          titleHelp: 'profile',
          icon: 'assignment',
        },
      })
      .state('provider.connector.edit.preview', {
        url: '/preview',
        controller: 'connProviderPreviewController',
        templateUrl: 'dist/templates/includes/preview.html',
        data: {
          title: 'Preview',
          titleHelp: 'preview',
          icon: 'play_circle_filled',
        },
      })
      .state('provider.connector.edit.installations', {
        abstract: true,
        url: '/installation',
        template: '<ui-view/>',
        data: {
          parent: 'Installations',
          title: 'Installations',
          icon: 'business',
        },
      })
      .state('provider.connector.edit.installations.list', {
        url: '/',
        controller: 'connProviderInstallationsController',
        templateUrl: 'dist/templates/provider/connector/edit/installations/list.html',
        data: {
          title: 'Installations',
          icon: 'business',
        },
      })
      .state('provider.connector.edit.installations.edit', {
        url: '/:requestId',
        controller: 'connProviderInstallationsEditController',
        templateUrl: 'dist/templates/provider/connector/edit/installations/edit.html',
        data: {
          title: 'Installations Request',
          icon: 'business',
          breadcrumb: {
            url: 'provider.connector.edit.installations.list',
            title: 'Installations',
          },
        },
      })
      .state('provider.connector.previewRedirect', {
        url: '/preview/redirect/:connId/:version?locale',
        params: {
          locale: 'en_US',
        },
        controller: 'previewRedirectController',
        templateUrl: 'dist/templates/redirect.html',
        blankView: true,
      });
  },
]);


app.config([
  '$mdThemingProvider',
  function themeConfiguration(
    $mdThemingProvider
  ) {
    const customTheme = $mdThemingProvider.theme('customTheme');
    const customPalette = $mdThemingProvider.extendPalette('blue', {
      50: 'ffffff',
      100: '000000',
      200: 'BBDEFB',
      // used for the dialog overlay bgcolor
      900: '000000',
      // used for the menu bgcolor
      A100: 'ffffff',
      A200: '000000',
      contrastDefaultColor: 'light',
    });

    $mdThemingProvider.definePalette('customThemeBlue', customPalette);
    $mdThemingProvider.theme('default').accentPalette('customThemeBlue', {
      default: '500',
    });
    $mdThemingProvider.alwaysWatchTheme(true);

    customTheme.primaryPalette('customThemeBlue', {
      'hue-1': '800',
      'hue-2': '50',
    });
    customTheme.backgroundPalette('customThemeBlue', {
      default: '50',
    });
    customTheme.accentPalette('customThemeBlue', {
      default: '500',
    });

    customTheme.warnPalette('red');
    customTheme.foregroundPalette['1'] = 'rgba(0,0,0,0.97)';
    customTheme.foregroundPalette['2'] = 'rgba(0,0,0,0.67)';
    customTheme.foregroundPalette['3'] = 'rgba(0,0,0,0.57)';

    const controlTheme = $mdThemingProvider.theme('controlTheme', 'default');
    const controlPalette = $mdThemingProvider.extendPalette('blue');

    $mdThemingProvider.definePalette('controlPalette', controlPalette);

    controlTheme.primaryPalette('controlPalette');
  },
]);


app.config([
  'AnalyticsProvider',
  'configProvider',
  function analyticsConfiguration(
    AnalyticsProvider,
    configProvider
  ) {
    const config = configProvider.$get();

    if (config.analyticsId) {
      AnalyticsProvider.setAccount(config.analyticsId);

      AnalyticsProvider.setPageEvent('$stateChangeSuccess');
    }
  },
]);


app.run([
  '$rootScope',
  '$location',
  '$cookies',
  '$http',
  '$state',
  'auth',
  function authConfiguration(
    $rootScope,
    $location,
    $cookies,
    $http,
    $state,
    auth
  ) {
    const userData = $cookies.getObject('user');

    if (userData) {
      auth.init(userData);
    }

    if (auth.isAuthenticated()) {
      auth.setCredentials();
    }

    $rootScope.$on('$stateChangeStart', (event, toState) => {
      if (toState.skipAuthentication) {
        return;
      }
    });
  },
]);


app.run([
  '$mdDialog',
  'auth',
  function expirationNotification(
    $mdDialog,
    auth
  ) {
    let dialog;

    function DialogController($scope, dlg, timeLeft) {
      $scope.logout = () => dlg.cancel();
      $scope.continue = () => dlg.hide();
      $scope.left = () => Math.floor(timeLeft() / 60);

      $scope.captions = {
        0: 'will expire in less than one minute',
        one: 'will expire in one minute',
        other: 'will expire in {} minutes',
      };
    }

    auth.sessionPollConfig({
      notify: (left) => {
        dialog = $mdDialog
          .show({
            locals: {
              timeLeft: left,
            },
            controller: [
              '$scope',
              '$mdDialog',
              'timeLeft',
              DialogController,
            ],
            templateUrl: 'dist/templates/dialogs/sessionExpiration.html',
          });

        return dialog;
      },
      cancelNotify: () => {
        if (dialog) {
          $mdDialog.cancel(dialog);
        }
      },
    });
  },
]);


app.run([
  'Analytics',
  angular.noop,
]);


app.run([
  '$rootScope',
  function defineJsoneditorInitializer(
    $rootScope
  ) {
    $rootScope.initializeJsoneditor = (jsoneditor) => {
      const initializeJsoneditor = () => {
        if (jsoneditor.aceEditor) {
          jsoneditor.aceEditor.setOptions({
            minLines: _.get(jsoneditor, 'options.minLines', 10),
            maxLines: _.get(jsoneditor, 'options.maxLines', 50),
          });
        }
      };

      const onModeChange = _.get(jsoneditor, 'options.onModeChange', _.noop);

      jsoneditor.options.onModeChange = (...args) => {
        initializeJsoneditor(jsoneditor);
        return onModeChange.apply(args);
      };

      initializeJsoneditor(jsoneditor);
    };
  },
]);
