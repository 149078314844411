const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('connEditProvidersControllerEdit', [
  '$scope',
  '$state',
  '$stateParams',
  '$mdDialog',
  '$q',
  'Invitation',
  'Connector',
  function connEditProvidersControllerEdit(
    $scope,
    $state,
    $stateParams,
    $mdDialog,
    $q,
    Invitation,
    Connector
  ) {
    const sandbox = $scope.getSandboxStatus()
      .then($scope.checkSandboxStatus);

    $scope.invitation = Invitation.get({ id: $stateParams.invitationId });
    $scope.revoking = false;

    $scope.promise = $q.all([sandbox, $scope.invitation.$promise]);

    $scope.updateName = () => {
      $scope._save(() => $scope.invitation.$update({ id: $scope.invitation.id }));
    };

    $scope.parentPromise.then(() => {
      const latestVersion = Connector.getLatestVersion($scope.versions.list);

      $scope.auth = {
        version: `${latestVersion.release} (Always latest)`,
        configuration: latestVersion.configuration,
      };
    });

    $scope.removeProvider = ($event) => {
      if ($scope.revoking) {
        return;
      }

      $scope.revoking = true;

      const confirm = $mdDialog
        .confirm()
        .targetEvent($event)
        .theme('customTheme')
        .clickOutsideToClose(true)
        .title('Revoke invitation')
        .textContent('Please confirm the operation. The invitation will be revoked.')
        .ok('Revoke')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(() => $scope.invitation.$delete({ id: $scope.invitation.id }))
        .then(() => $state.go('isv.connector.edit.providers.list'))
        .catch((res) => {
          const error = _.get(res, 'data.error', 'Sorry, something went wrong');
          const alert = $mdDialog
            .alert()
            .clickOutsideToClose(true)
            .theme('customTheme')
            .content(error)
            .ok('Ok');

          if (res) $mdDialog.show(alert);
        })
        .finally(() => ($scope.revoking = false));
    };
  },
]);
