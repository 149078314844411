const angular = require('angular');
const _ = require('lodash');
const Holder = require('holderjs');


const app = angular.module('sweeft-spa');

app.factory('wrpHolder', () => Holder);

app.directive('connLogo', [
  '$rootScope',
  '$document',
  '$q',
  'colorPalette',
  'mirror',
  'wrpHolder',
  function connLogo(
    $rootScope,
    $document,
    $q,
    colorPalette,
    mirror,
    wrpHolder
  ) {
    const theme = {
      cardColor: 'primary-800',
      fg: '#FFFFFF',
      bg: '#1565C0',
      fgColor: [255, 255, 255],
      bgColor: [21, 101, 192],
    };

    function runHolder(el, attr, params) {
      const p = params();
      const size = p.imgSize;
      const attrName = 'data-background-src';

      delete p.imgSize;

      const holderParams = _(p)
        .map((v, k) => `${k}=${v}`)
        .join('&');

      el.setAttribute(attrName, `?holder.js/${size}?${holderParams}`);

      $q.when(wrpHolder.addTheme('odin', _.pick(theme, ['bg', 'fg']))
        .run({
          images: el,
          noBackgroundSize: true,
          nocss: true,
        })
      ).then(() => $rootScope.$broadcast(
        'placeholder:change',
        el.style.backgroundImage.replace(/^url\(\"(.*)\"\)$/, '$1')
      ));
    }

    function stopHolder(el) {
      angular.element(el).css('background-image', '');
    }

    return {
      restrict: 'E',
      scope: {
        url: '=',
        size: '<',
        textSize: '<',
        holderText: '<',
        changeable: '<',
        colorNotify: '<',
        palette: '=?',
        maxSize: '<',
        logoPlaceholder: '<',
        master: '<',
      },
      templateUrl: '/dist/directives/connLogo/connLogo.html',
      controller: [
        '$scope',
        function connLogoController(
          $scope
        ) {
          $scope.file = null;
          $scope.logoUrl = '';

          $scope.upload = (files, invalidFiles) => {
            if (files.length) {
              $scope.file = files[0];
              $rootScope.$broadcast('logo:change', $scope.file);
            } else if (invalidFiles.length) {
              $rootScope.$broadcast('logo:invalid', invalidFiles[0]);
            }
          };

          mirror({ runHolder, stopHolder }, $scope);
        },
      ],
      link(scope, elements, attrs) {
        const holderElement = elements[0].children[0];
        const logoImg = holderElement.children[0];
        const size = () => {
          let s = scope.size;

          if (!s) {
            s = { width: 128, height: 128 };
          }

          return `${s.width}x${s.height}`;
        };

        const params = () => ({
          size: _.get(scope, 'textSize', 65),
          text: _.get(scope, 'holderText', '?'),
          theme: 'odin',
          random: 'no',
          imgSize: size(),
        });

        scope.loadLogo = (logo) => {
          const errorHandler = () => {
            scope.palette = theme.cardColor;
            runHolder(holderElement, attrs, params);
          };

          stopHolder(holderElement);

          if (_.isNull(logo)) {
            errorHandler();
          }

          logoImg.onerror = errorHandler;
          scope.logoUrl = _.startsWith(logo, 'http') ? logo : scope.logoUrl;
        };

        mirror({ holderElement, logoImg, size, params }, scope);

        scope.$watch('holderText', (prev, next) => {
          if ((prev !== next) && scope.logoPlaceholder && prev && next) {
            runHolder(holderElement, attrs, params);
            scope.palette = theme.cardColor;
          }
        });

        scope.$watch('url', (val) => {
          if (!_.isUndefined(val) && (val !== scope.logoUrl)) {
            scope.loadLogo(val);
          }
        });

        $rootScope.$on('logo:resetFile', () => {
          scope.file = null;
        });
      },
    };
  },
]);
