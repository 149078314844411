const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('labServiceClientUserController', [
  '$scope',
  '$stateParams',
  'ClientUser',
  function labServiceClientUserController(
    $scope,
    $stateParams,
    ClientUser
  ) {
    $scope.resellerName = $stateParams.resellerName;
    $scope.clientName = $stateParams.clientName;

    $scope.promise = $scope.conn.$promise.then(() => {
      ClientUser.setToken($scope.conn.sandbox.token);

      $scope.clientUsers = ClientUser.query({
        resellerName: $stateParams.resellerName,
        clientName: $stateParams.clientName,
      });

      return $scope.clientUsers.$promise;
    }).then((res) => {
      _.forEach(res, (value, key) => {
        if ($scope.clientUsers[key].admin) {
          $scope.clientUsers[key].role = 'Admin';
        } else {
          $scope.clientUsers[key].role = 'User';
        }
      });

      $scope.total = _.reduce(res, (acc, clientUser) => ({
        storage: {
          usage: acc.storage.usage + clientUser.storage.usage,
          limit: acc.storage.limit + clientUser.storage.limit,
        },
      }), { storage: { usage: 0, limit: 0 } });
    });
  },
]);
