const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('noAnimation', [
  '$animate',
  function noAnimation($animate) {
    return {
      restrict: 'A',
      link(scope, element) {
        $animate.enabled(element, false);
      },
    };
  },
]);
