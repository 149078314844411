const angular = require('angular');


const app = angular.module('sweeft-spa');


app.provider('config', [
  '$injector',
  function configProvider(
    $injector
  ) {
    const config = {
      apiBaseUrl: 'http://localhost:8080',
    };

    if ($injector.has('sweeft')) {
      const sweeftConfig = $injector.get('sweeft');

      angular.extend(config, sweeftConfig);
    }
    config.apiBaseUrl = config.apiBaseUrl.replace(/\/+$/, '');  // Cut ending slash if present

    return {
      $get: () => config,
    };
  },
]);
