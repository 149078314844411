const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('labDetailsController', [
  '$scope',
  '$stateParams',
  'SandboxService',
  'mirror',
  function labDetailsController(
    $scope,
    $stateParams,
    SandboxService,
    mirror
  ) {
    $scope.stateSandboxStatus();

    const sandbox = () => $scope.conn.$promise
      .then(() => SandboxService.check({ connId: $stateParams.connId }).$promise)
      .then((connector) => {
        $scope.conn.sandbox.connector = connector;
      });

    mirror({ sandbox }, $scope);

    $scope.promise = sandbox();

    $scope.$on('connector:sandboxReady', sandbox);
  },
]);
