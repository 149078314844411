const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connProviderController', [
  '$scope',
  '$stateParams',
  '$controller',
  '$state',
  '$q',
  'polling',
  'Connector',
  'ConnectorPreview',
  'stateResult',
  'auth',
  'preview',
  function connProviderController(
    $scope,
    $stateParams,
    $controller,
    $state,
    $q,
    polling,
    Connector,
    ConnectorPreview,
    stateResult,
    auth,
    preview
  ) {
    const parent = $controller('statusControllerMixin', {
      $scope,
      $stateParams,
      $state,
      $q,
      stateResult,
      auth,
      polling,
    });

    angular.extend(this, parent);

    $scope.isPreviewMode = () => preview.isPreviewMode();
    $scope.stateResult = stateResult;
    $scope.user = auth.user;

    $scope.preview = new ConnectorPreview({
      status: -1,
    });
    $scope.showContent = false;

    $scope.conn = Connector.getLastVersion({ connId: $stateParams.connId });

    $scope.urlPromise = $q((resolve, reject) => {
      $scope.conn.$promise
        .then((conn) => {
          $scope.navigation = [{
            states: [
              { id: 'provider.connector.edit.profile' },
              { id: 'provider.connector.edit.preview' },
              { id: 'provider.connector.edit.installations.list', parent: 'Installations' },
            ],
          }];

          $scope.versions = {
            current: _.assign({ name: `${conn.release} (latest)` }, conn),
          };

          $scope.showContent = true;
          $scope.pageTitle = () => $scope.conn.title;

          resolve($scope.conn.logo);
        }).catch(() => {
          reject();
        });
    });

    $scope.getPreview = $scope.check.checkFactory('preview', ConnectorPreview, {
      limit: 1,
      order_by: '-created',
      types: $scope.preview.$types.PROVIDER,
      company: $scope.user.companies[0].id,
    });

    $scope.checkPreviewStatus = $scope.check.checkItemFactory('preview',
      $scope.preview.state.IN_PROGRESS,
      5000,
      'getPreview'
    );
  },
]);
