const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('statusControllerMixin', [
  '$scope',
  '$stateParams',
  '$state',
  '$q',
  'polling',
  'preview',
  function statusControllerMixin(
    $scope,
    $stateParams,
    $state,
    $q,
    polling,
    preview
  ) {
    $scope.statusChecks = {};
    $scope.go = $state.go;

    $scope.connNamePattern = /^(?!\-)[A-z0-9 \-]+$/;
    $scope.descriptionPattern = /^[A-z0-9\s\.\-,\/#!$%\^&\*;:{}='`~()_–]*$/;

    $scope.stateInfo = {
      preview: {},
      publication: {},
    };

    $scope.showStateResult = (type, s) => {
      $scope.stateInfo[type][s.name] = !$scope.stateInfo[type][s.name];
    };

    $scope.showResult = (type, s) => $scope.stateInfo[type][s.name];

    $scope.getLogoType = () => _.get($state, 'current.data.pageLogo', 'default');

    $scope.pageTitle = () => '';

    $scope.title = () => _.get($state, 'current.data.title', '');

    $scope.breadcrumb = () => _.get($state, 'current.data.breadcrumb', '');

    $scope.installationRequestButton = {
      isShown: () => false,
      isEnabled: () => false,
    };

    $scope.check = {
      checkFactory: (alias, Model, params = {}, dir) => {
        function check() {
          return $scope.conn.$promise
            .then(() => {
              const id = $scope.user.isProvider ||
                ($scope.user.isVendor && preview.isPreviewMode()) ?
                  $scope.conn.id :
                  $stateParams.connId;

              return Model.get(_.extend({ app: id }, params)).$promise;
            })
            .then((result) => {
              const res = dir ? result[dir] : result;
              res.states = res.getStates();
              $scope[alias] = res;

              if ((res && res.status > -1 && res.state)
                  && res.status !== res.state.IN_PROGRESS) {
                $scope.statusChecks[`${alias}Ready`] = true;
                polling.stop(alias);
              }

              return res;
            });
        }

        return check;
      },

      checkItemFactory: (scopeName, loopStatus, timeout, getItemFnName) => {
        function checkItem(item) {
          const p = $q.defer();

          $scope[scopeName] = item;
          // TODO: Find out how to configure polling stop condition.
          // Maybe it will make sence to improve polling service.
          if (item.status === loopStatus) {
            $scope.statusChecks[`${scopeName}Ready`] = false;
            const callback = () =>
              $scope[getItemFnName]()
                .then((result) => {
                  if (result.status !== loopStatus) {
                    p.resolve(result.status);
                  }
                })
                .catch((err) => {
                  p.reject(err);
                });

            polling.add(callback, timeout, { alias: scopeName });
          } else {
            $scope.statusChecks[`${scopeName}Ready`] = true;

            p.resolve(item.status);
          }

          return p.promise;
        }

        return checkItem;
      },

      statusFactory: (scopeName, status) => {
        function isStatus() {
          return $scope[scopeName] && $scope[scopeName].status === status;
        }

        return isStatus;
      },
    };

    $scope.stateData = (state) => $state.get(state.id).data;
    $scope.isCurrentState = (state) => {
      if ('parent' in state && $state.current.data) {
        return $state.current.data.parent === state.parent;
      }

      return $state.current.name === state.id;
    };
  },
]);

