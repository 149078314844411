const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('FederatedAPSPackage', [
  '$resource',
  '$q',
  'config',
  'Upload',
  'BaseFederatedPackage',
  function FederatedAPSPackageModel(
    $resource,
    $q,
    config,
    Upload,
    BaseFederatedPackage
  ) {
    class FederatedAPSPackage extends BaseFederatedPackage {
      /* istanbul ignore next */
      constructor() {
        super();

        const modelUrl = this.url = '/api/v1/federated-aps-package/:id/';

        this.actions = _.extend({}, this.actions);

        return class extends this.factory() {
          uploadUrl() {
            return modelUrl;
          }
        };
      }

    }

    return new FederatedAPSPackage();
  },
]);
