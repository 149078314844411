const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('HubClient', [
  '$resource',
  'config',
  'baseModel',
  function HubClientModel(
    $resource,
    config,
    Base
  ) {
    class HubClient extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/oainstance-client/';

        this.actions = _.extend({}, this.actions);

        return this.factory();
      }
    }

    return new HubClient();
  },
]);
