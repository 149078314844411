const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('UIMetaResource', [
  '$resource',
  'config',
  'baseModel',
  function UIMetaResourceModel(
    $resource,
    config,
    Base
  ) {
    class UIMetaResource extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/ui-application-meta/:id/';

        this.actions = _.extend({}, this.actions);

        return class extends this.factory() {
          init() {
            this.visibility = {
              Admins: 0,
              Users: 1,
            };
          }

          isValid() {
            const pattern = {
              id: /^[a-zA-Z_][a-zA-Z0-9_\ \-]*$/i,
              url: /^(https?:\/\/)([\da-z\.-]+)\.([a-z\.]{2,6})/i,
            };

            return this.name &&
              this.name.length > 0 &&
              pattern.id.test(this.name) &&
              this.value &&
              this.value.length &&
              pattern.url.test(this.value);
          }

          getVisibleType(type) {
            return _.findKey(this.visibility, val => val === type);
          }
        };
      }
    }

    return new UIMetaResource();
  },
]);
