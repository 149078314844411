const angular = require('angular');
const urljoin = require('url-join');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connectorApproveAddController', [
  '$scope',
  '$state',
  '$stateParams',
  '$log',
  '$q',
  'dialog',
  'FederatedConnector',
  'FederatedConfiguration',
  'FederatedAPSPackage',
  'FederatedApi',
  'Configuration',
  'mirror',
  function connectorApproveAddController(
    $scope,
    $state,
    $stateParams,
    $log,
    $q,
    dialog,
    FederatedConnector,
    FederatedConfiguration,
    FederatedAPSPackage,
    FederatedApi,
    Configuration,
    mirror
  ) {
    $scope.form = {};
    $scope.customConfiguration = 'custom';
    $scope.linkData = {
      configuration: '',
      api_params: {
        collector_oauth_key: 'key',
        collector_oauth_secret: 'secret',
        collector_entrypoint: 'entrypoint',
      },
      APSProperties: '# APS Package properties example\n# service:\n#     some_key: value',
      anniversaryDate: '1999-01-15T00:00:00Z',
    };

    $scope.promise = $q.all({
      request: FederatedConnector.get({ id: $stateParams.requestId }).$promise,
      configurations: FederatedConfiguration.query().$promise,
    });

    $scope.promise.then(({ request, configurations }) => {
      $scope.request = request;
      $scope.configurations = configurations;
      $scope.connConfig = new Configuration(request.connector.configuration);

      if (!$scope.request.id) {
        $state.go('federated.requestList');
      }
    });

    $scope.isCustom = () => $scope.linkData.configuration === $scope.customConfiguration;

    $scope.isPackage = () => Boolean(_.get($scope.linkData, 'api_package', ''));
    $scope.isImage = () => Boolean(
      _.get(
        $scope.linkData,
        'imageUrl',
        _.get($scope.linkData, 'imageHealth', '')
      )
    );

    function rejectWithMessage(msg) {
      return () => {
        throw new Error(msg);
      };
    }

    function checkPartner() {
      if (!$scope.request.partner.gae_project) {
        const partnerID = $scope.request.partner.account_id;
        return `Please specify GAE Project for Partner (${partnerID})`;
      }

      return null;
    }

    function approveApplication() {
      const error = checkPartner();
      if (error) return $q.reject(error);

      $scope.processing = true;

      const apsPackage = new FederatedAPSPackage($scope.request.aps_package);
      const api = new FederatedApi($scope.request.connector);

      apsPackage.$meta.package = $scope.linkData.application_package;
      apsPackage.application_id = $scope.linkData.applicationId;
      apsPackage.anniversary_date = $scope.linkData.anniversaryDate;
      api.params = $scope.linkData.api_params;

      if ($scope.linkData.APSProperties instanceof Object) {
        apsPackage.properties = $scope.linkData.APSProperties;
      }

      if (_.get($scope.linkData, 'imageUrl', '').length > 0) {
        $scope.connConfig.is_image = true;
        $scope.connConfig.image = $scope.linkData.imageUrl;
        $scope.connConfig.healthcheck_path = _.get($scope.linkData, 'imageHealth', '/');
        $scope.connConfig.root_path = _.get($scope.linkData, 'apiPath', '/');
      } else {
        api.$meta.package = $scope.linkData.api_package;
        api.package = urljoin(
          $stateParams.requestId,
          $scope.linkData.api_package.name
        );
      }

      $scope.request.status = $scope.request.$status.APPROVED;

      const saveAPSPackage = () => apsPackage.savePackage()
        .catch(
          rejectWithMessage('Sorry, something went wrong while uploading application package')
        );

      return saveAPSPackage()
        .then(() => apsPackage.$update({ id: apsPackage.id })
          .catch(
            rejectWithMessage('Sorry, something went wrong while updating application package type')
          ))
        .then(() => api.savePackage()
          .catch(
            rejectWithMessage('Sorry, something went wrong while uploading API package')
          ))
        .then(() => api.$update({ id: api.id })
          .catch(
            rejectWithMessage('Sorry, something went wrong while updating API params')
          ))
        .then(() => $scope.connConfig.$patch({ id: $scope.connConfig.id })
          .catch(
            rejectWithMessage('Sorry, something went wrong while updating connector configuration')
          ))
        .then(() => $scope.request.$update({ id: $scope.request.id })
          .catch(
            rejectWithMessage('Sorry, something went wrong during request approval')
          ))
        .then(() => $state.go('federated.requestList'))
        .catch((err) => {
          dialog.show(err.message);
        })
        .finally(() => {
          $scope.processing = false;
        });
    }

    function setConfigurationInRequest() {
      const error = checkPartner();
      if (error) return $q.reject(error);

      $scope.processing = true;

      return FederatedConnector.setConfiguration({
        id: $scope.request.id,
      }, {
        configuration: $scope.linkData.configuration,
      }).$promise
        .then(() => $state.go('federated.requestList'));
    }

    $scope.approveRequest = ($event) => {
      if ($scope.processing) {
        return;
      }

      if ($scope.form.approveForm.$invalid) {
        dialog.show('Please fill in valid values', $event);
        return;
      }

      approveApplication()
        .catch((error) => {
          $log.error(error);

          dialog.show(`Request approval has failed: ${error}`, $event);
        });
    };

    $scope.setConfigurationInRequest = ($event) => {
      if ($scope.processing) {
        return;
      }

      if (!$scope.linkData.configuration) {
        dialog.show('Please fill configuration field', $event);
        return;
      }

      setConfigurationInRequest()
        .catch((error) => {
          $log.error(error);
          $scope.processing = false;

          dialog.show(`Request approval has failed: ${error}`, $event);
        });
    };

    $scope.$watch('linkData.api_package', (newValue) => {
      if (newValue === '') {
        $scope.form.approveForm.imageUrl.$setUntouched();
        $scope.form.approveForm.imageUrl.$setPristine();
      }
    });

    mirror({
      rejectWithMessage,
      checkPartner,
      approveApplication,
      setConfigurationInRequest,
    }, $scope);
  },
]);
