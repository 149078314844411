const angular = require('angular');
const html2canvas = require('html2canvas');


const app = angular.module('sweeft-spa');


app.controller('feedbackController', [
  '$document',
  '$window',
  '$location',
  '$scope',
  '$mdToast',
  '$mdDialog',
  'Feedback',
  'uri',
  function feedbackController(
    $document,
    $window,
    $location,
    $scope,
    $mdToast,
    $mdDialog,
    Feedback,
    uri
  ) {
    $scope.feedbackForm = null;
    $scope.feedback = new Feedback();
    $scope.feedback.img = uri;
    $scope.feedback.url = $location.absUrl();
    $scope.feedback.browser = {
      userAgent: $window.navigator.userAgent,
    };

    $scope.feedbackId = null;
    $scope.isError = null;
    $scope.processing = false;

    $scope.cancel = () => {
      $mdDialog.cancel();
    };

    $scope.continue = () => {
      if (!$scope.processing) {
        $scope.processing = true;

        return Feedback.send($scope.feedback).$promise
          .then((res) => {
            $scope.feedbackId = res.id;
            $scope.isError = res.error;
          })
          .catch(() => {
            $scope.isError = true;
          })
          .finally(() => {
            $scope.processing = false;
          });
      }

      return null;
    };

    $scope.copy = () => {
      const linkEl = $document[0].getElementById('report-id');
      let range;
      let selection;

      if ($document[0].createRange) {
        range = $document[0].createRange();
        range.selectNode(linkEl);
        selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
      } else {
        range = $document[0].body.createTextRange();
        range.moveToElementText(linkEl);
        range.select();
      }

      const isCopy = $document[0].execCommand('copy');
      const message = isCopy ?
        'Report ID was copied.' :
        'Something went wrong, try again.';

      $mdToast.show(
        $mdToast
          .simple()
          .position('bottom right')
          .textContent(message)
          .hideDelay(3000)
          .theme('customTheme')
      );
    };
  },
]);


app.directive('feedback', [
  '$document',
  '$http',
  '$mdDialog',
  function feedback(
    $document,
    $http,
    $mdDialog
  ) {
    return {
      restrict: 'E',
      templateUrl: 'dist/directives/feedback/feedback-icon.html',
      transclude: true,
      scope: {},
      link: (scope) => {
        scope.isInProgress = false;

        function resetProgress() {
          scope.isInProgress = false;
        }

        function showDialog(img) {
          return $mdDialog
            .show({
              clickOutsideToClose: true,
              targetEvent: event,
              templateUrl: 'dist/directives/feedback/sendFeedback.html',
              locals: {
                uri: img,
              },
              controller: 'feedbackController',
              onComplete: resetProgress,
            });
        }

        scope.launchFeedback = () => {
          const body = $document.find('body');
          scope.isInProgress = true;

          body.addClass('screenshot');

          return html2canvas(body, {
            allowTaint: true,
            useCORS: true,
          }).then((canvas) => {
            body.removeClass('screenshot');
            showDialog(canvas.toDataURL('image/png'));
          });
        };
      },
    };
  },
]);
