const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');
const moment = require('moment');


app.controller('connEditPreviewController', [
  '$q',
  '$scope',
  '$stateParams',
  '$interval',
  'Connector',
  'ConnectorResource',
  'Localization',
  '$controller',
  'auth',
  'colors',
  'mirror',
  function connEditPreviewController(
    $q,
    $scope,
    $stateParams,
    $interval,
    Connector,
    ConnectorResource,
    Localization,
    $controller,
    auth,
    colors,
    mirror
  ) {
    const parent = $controller('previewController', {
      $scope,
      $stateParams,
      colors,
      auth,
      Connector,
      previewFunc: Connector.preview,
    });

    angular.extend(this, parent);

    const connPromise = $scope.versionGoToMaster()
      .then(() => $q.all({
        resources: ConnectorResource.query({ application: $stateParams.connId }).$promise,
        units: ConnectorResource.units().$promise,
      }))
      .then(({ resources, units }) => {
        _.forEach(units, (unit) => {
          $scope.units[unit.unit] = unit.description;
        });

        _.forEach($scope.conn.resources, (res, i) => {
          _.map(resources, (r) => {
            if (r.id === res.id) {
              res.title = r.title;
            }
          });

          res.limit = res.name === 'DEVICES' ? 'unlimited' : _.get(res, 'limit', i * 100);
          res.unit_description = $scope.units[res.unit];
        });
      });

    const sandbox = $scope.getSandboxStatus();
    const publication = $scope.getPublication();
    const preview = $scope.getPreview();
    const usage = $scope.collectUsage();
    const locales = Localization.query({ app: $stateParams.connId }).$promise;

    $scope.showPreview = () => $scope.sandboxIsReady() && $scope.statusChecks.publicationReady;

    const PATH_REDIRECT = 'isv.connector.previewRedirect';
    $scope.ccpLink = (v) => `${PATH_REDIRECT}({ connId: conn.id, version: ${v}, locale })`;

    $scope.promise = $q.all([sandbox, publication, preview, usage, connPromise, locales]);

    sandbox.then($scope.checkSandboxStatus);
    publication.then($scope.checkPublicationStatus);
    preview.then($scope.checkPreviewStatus);
    usage.then($scope.checkCollectUsageStatus);
    locales.then(locale => ($scope.locales = locale));

    $scope.user = auth.user;
    let timeoutCanLogin;

    $scope.$preview = {
      isInProgress: () => !$scope.statusChecks.previewReady
        || $scope.statusIs($scope.preview.state.IN_PROGRESS, 'preview'),
      isFailed: () => $scope.statusIs($scope.preview.state.PREVIEW_FAIL, 'preview')
        || $scope.statusIs($scope.preview.state.INSTALL_FAIL, 'preview'),
      isOK: () => $scope.statusChecks.sandboxReady
        && $scope.statusChecks.publicationReady
        && $scope.statusIs($scope.preview.state.OK, 'preview'),
      can_login: false,
    };

    $scope.$usage = {
      isInProgress: () => !$scope.statusChecks.usageReady
        || $scope.statusIs($scope.usage.state.IN_PROGRESS, 'usage'),
      isFailed: () => $scope.$preview.isOK
        && $scope.statusIs($scope.usage.state.FAIL, 'usage'),
      isOK: () => $scope.statusChecks.usageReady && $scope.statusIs($scope.usage.state.OK, 'usage'),
    };

    $scope.triggerUsage = () => {
      $scope.statusChecks.usageReady = false;

      Connector.usageCollection({ connId: $stateParams.connId }, {}).$promise
        .then(() => $scope.collectUsage())
        .then($scope.checkCollectUsageStatus)
        .catch((err) => {
          $scope.statusChecks.usageReady = true;
          $scope.usageFail = `${err.statusText} + ${err.status}`;
        });
    };

    $scope.checkStateStatus = (name) => _.find($scope.preview.getStates(), { name });
    $scope.stopInterval = () => $interval.cancel(timeoutCanLogin);

    const checkStatusSubscription = () => {
      const createAccount = $scope.checkStateStatus('create_account').timeout_at;
      const createSubscription = $scope.checkStateStatus('create_subscription').checkStatus();
      const nowTime = moment(new Date()).unix();
      const timeoutAt = moment.utc(createAccount).add(1, 'minutes').unix();
      if (createSubscription === 'OK' || createSubscription === 'FAIL') $scope.stopInterval();

      if ((timeoutAt - nowTime) < 0 && createSubscription === 'IN_PROGRESS') {
        $scope.$preview.can_login = true;
        $scope.stopInterval();
      }
    };

    $scope.$watch('preview.signin_ready', (step) => {
      if (step && $scope.$preview.isInProgress()) {
        checkStatusSubscription();
        timeoutCanLogin = $interval(checkStatusSubscription, 10000);
      }
    });

    $scope.startOver = () => $scope.$usage.isInProgress() || !$scope.enabledYaml;

    $scope.$on('$destroy', $scope.stopInterval);

    mirror({
      connPromise,
      checkStatusSubscription,
    }, $scope);
  },
]);
