const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Feedback', [
  '$http',
  '$resource',
  '$window',
  'config',
  'baseModel',
  function UserModel(
    $http,
    $resource,
    $window,
    config,
    Base
  ) {
    class Feedback extends Base {
      /* istanbul ignore next */
      constructor() {
        super();
        this.img = null;
        this.note = null;
        this.url = null;
        this.browser = {};

        this.actions = _.extend({}, this.actions, {
          send: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/feedback/`,
            transformResponse: (data, headers, status) => {
              if (status === 201) {
                return { id: headers().location.replace('/api/v1/feedback/', ''), error: false };
              }

              return { id: null, error: true };
            },
          },
        });

        return this.factory();
      }
    }

    return new Feedback();
  },
]);
