const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditProvidersController', [
  '$scope',
  '$mdToast',
  '$document',
  function connEditProvidersController(
    $scope,
    $mdToast,
    $document
  ) {
    $scope.copyLink = () => {
      const linkEl = $document[0].getElementById('providers-invitation-link');
      const range = $document[0].createRange();
      range.selectNode(linkEl);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      const isCopy = $document[0].execCommand('copy');
      const message = isCopy ?
        'The link was copied.' :
        'Something went wrong, try again.';

      $mdToast.show(
        $mdToast
          .simple()
          .position('bottom right')
          .textContent(message)
          .hideDelay(3000)
          .theme('customTheme')
      );
    };
  },
]);
