const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');

// This controller is injected by connProviderPreview and connEditPreview.
// previewFunc is just a method of Connector resource
app.controller('previewController', [
  '$log',
  '$scope',
  '$stateParams',
  '$timeout',
  '$q',
  'colors',
  'preview',
  'auth',
  'Connector',
  'FakeData',
  'previewFunc',
  'accountSchema',
  'yamlEditorService',
  'mirror',
  function previewController(
    $log,
    $scope,
    $stateParams,
    $timeout,
    $q,
    colors,
    preview,
    auth,
    Connector,
    FakeData,
    previewFunc,
    accountSchema,
    yamlEditorService,
    mirror
  ) {
    const samples = [
      {
        title: 'A',
        name: 'account',
        data: {},
      },
      {
        title: 'L',
        name: 'usageLimit',
        data: {},
      },
    ];
    $scope.samples = samples;

    $scope.enabledYaml = true;

    $scope.connId = $stateParams.connId;

    $scope.forms = {};
    $scope.units = {};
    $scope.locale = 'en_US';

    $scope.changeLocale = (locale) => {
      $scope.locale = locale;
    };

    $scope.dataCustomize = false;
    $scope.dataShow = false;

    $scope.switchDataCustomize = (mode) => {
      $scope.enabledYaml = true;

      if (!mode) {
        $scope.dataShow = false;
        samples[0].data = {};

        return;
      }

      $scope.fakeData.load().then(() => {
        samples[1].data = $scope.updateLimit();
        $scope.dataShow = true;
      });
    };

    $scope.isUsersUnit = (unit) => _.lowerCase(unit) === 'users';
    $scope.isVendor = () => auth.user.isVendor || preview.isPreviewMode();
    $scope.statusIs = (status, factory) => $scope[factory].status === status;

    $scope.stateIsInProgress = (s) => s.status === s.state.IN_PROGRESS;
    $scope.stateIsFailed = (s) => s.status === s.state.FAIL;
    $scope.stateIsOK = (s) => s.status === s.state.OK;

    $scope.$state = {
      isInProgress: (s) => s.status === s.state.IN_PROGRESS,
      isFailed: (s) => s.status === s.state.FAIL,
      isOK: (s) => s.status === s.state.OK,
    };

    $scope.stateStyle = (s) => ({
      color: s.status === s.state.NONE ? 'rgba(0, 0, 0, 0.57)' : '',
      'font-weight': s.status === s.state.IN_PROGRESS ? 'bold' : '',
    });

    $scope.stateResultStyle = (s) => ({
      color: s.status === s.state.FAIL ? colors.failed : '',
    });

    $scope.stateIcon = (s) => {
      const failed = $scope.stateIsFailed(s);
      const color = failed ? colors.failed : colors.theme;
      const icon = failed ? 'warning' : 'check_circle';

      return {
        style: { color },
        icon,
      };
    };
    $scope.updateResource = () => {
      $scope.conn.resources = _.map($scope.conn.resources, (res) => {
        const sample = _.get(samples[1].data, res.name);
        res.limit = _.get(sample, 'limit', res.limit);

        return res;
      });

      return $scope.conn.resources;
    };

    $scope.connPreview = () => {
      const account = samples[0].data;
      $scope.oaIsOffline = false;
      $scope.statusChecks.previewReady = false;
      $scope.preview.status = $scope.preview.state.IN_PROGRESS;
      $scope.stateResult.clearCache();
      $scope.updateResource();

      _.forEach($scope.preview.states, (s) => {
        s.status = s.state.NONE;
        s.result = '';
        s.showResult = false;
        $scope.$parent.stateInfo.preview[s.name] = false;
      });

      // auxiliary variable in order to set up timeout
      let oaOffline = false;

      const promise = $q((resolve) => {
        previewFunc({ connId: $stateParams.connId, version: $scope.conn.release },
        { account, resources: $scope.conn.resources }).$promise
          .then(() => {
            resolve();

            return $scope.getPreview();
          })
          .then($scope.checkPreviewStatus)
          .catch((err) => {
            if (err.status === 400) {
              resolve();
              oaOffline = true;
            }

            $log.error(`OA is offline: ${err.statusText} Response code is ${err.status}`);
          });
      });

      return $q.all([$timeout(3 * 1000), promise]).then(() => {
        if (oaOffline !== true) {
          return;
        }

        $scope.oaIsOffline = true;
        $scope.statusChecks.previewReady = true;
        $scope.preview.status = $scope.preview.state.OK;
      });
    };

    $scope.updateLimit = () => {
      const usageLimit = {};
      _.forEach($scope.conn.resources, (res, i) => {
        if (res.type !== 'SUBSCRIPTION_SERVICE') {
          usageLimit[res.name] = {
            name: res.title,
            limit: res.name === 'DEVICES' ? 'unlimited' : i * 100,
          };
        }
      });

      return usageLimit;
    };

    $scope.fakeData = {
      load: () => (
          FakeData.generate(accountSchema).$promise
            .then($scope.fakeData.save('account'))
            .then(() => (samples[1].data = $scope.updateLimit()))
      ),
      save: accountName => (data) => {
        const account = _.find(samples, _.matchesProperty('name', accountName));

        account.data = angular.fromJson(angular.toJson(data));
      },
    };

    function watchEditorStates(editorName) {
      return () => yamlEditorService.hasEditing(editorName);
    }

    function handleEditorStates(newValue) {
      if (newValue === true || newValue === false) {
        $scope.enabledYaml = _.every(samples, sample =>
          !yamlEditorService.hasEditing(sample.name) &&
          !yamlEditorService.hasError(sample.name)
        );
      }
    }

    _.forEach(samples, (sample) => {
      $scope.$watch(watchEditorStates(sample.name), handleEditorStates);
    });

    $scope.showTriggerUsage = (con) => auth.user.isVendor && !preview.isPreviewMode() && con;

    $scope.startPreview = () => {
      $scope.$preview.can_login = false;

      return $scope.connPreview();
    };

    $scope.$on('$destroy', $scope.clearPollTimeouts);

    mirror({
      watchEditorStates,
      handleEditorStates,
    }, $scope);
  },
]);
