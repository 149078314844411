const angular = require('angular');
const _ = require('lodash');
const app = angular.module('sweeft-spa');

app.factory('Locales', [
  '$q',
  '$mdToast',
  '$window',
  'polling',
  'downloader',
  'Localization',
  'ConnectorUI',
  function LocalesFactory(
    $q,
    $mdToast,
    $window,
    polling,
    downloader,
    Localization,
    ConnectorUI
  ) {
    const URL = $window.URL || $window.webkitURL;

    class Locale {
      constructor({
        conn,
        ui,
        pattern = /^[a-z]{2}_[a-z]{2}$/i,
        locales = [],
        generating = false,
        resourcesHasChanged = true,
        updateResource = () => {},
        toggleEditing = () => {},
      }) {
        this.conn = conn;
        this.ui = ui;
        this.pattern = pattern;
        this.locales = locales;
        this.generating = generating;
        this.downloading = false;
        this.uploading = false;
        this.resourcesHasChanged = resourcesHasChanged;
        this.suggests = [];
        this.updateResource = updateResource;
        this.toggleEditing = toggleEditing;
        this.minlength = 5;
        this.templates = [
          'de_DE',
          'es_ES',
          'fr_FR',
          'it_IT',
          'ja_JP',
          'nl_NL',
          'pt_BR',
          'ru_RU',
        ];

        this.filteringSuggests();
        this.addTmplLocale();
      }

      filteringSuggests() {
        const locales = _.map(this.locales, 'locale');
        this.suggests = _.difference(this.templates, locales);
      }

      canDownload(locale) {
        return this.ui.isLocalesOK() && locale.id.length > 0 && !this.generating;
      }

      canUpload() {
        return this.ui.isLocalesOK() &&
          this.locales.some((locale) => locale.id.length > 0) &&
          !this.generating;
      }

      canDelete() {
        return !this.generating && !this.downloading && !this.uploading;
      }

      isShownProgress(locale) {
        return locale._current && (this.generating || this.downloading);
      }

      searchLocale(localeText) {
        const pattern = new RegExp(`^${localeText}`, 'i');

        return _.filter(this.suggests, (locale) => locale.search(pattern) !== -1);
      }

      changeLocale(locale, isLast) {
        if (locale.locale.length < this.minlength) {
          return;
        }

        if (!locale._touched) {
          locale._touched = true;
          locale._form.localeAutocomplete.$setTouched();
        }

        this.chosenLocale(locale, isLast);
      }

      chosenLocale(locale, isLast) {
        if (isLast) {
          this.addTmplLocale();
        }

        this.validateLocale(locale);

        if (locale._form.localeAutocomplete.$valid) {
          this.checkLocale(locale);
        }
      }

      validateLocale(loc) {
        this.setError(loc._form, 'alreadyExist', false);
        this.setError(loc._form, 'invalidLocale', false);

        const isInValid = loc.locale.search(this.pattern) === -1;
        this.setError(loc._form, 'invalidSyntax', isInValid);
      }

      setError(form, type, bool) {
        if (form.localeInput) {
          form.localeInput.$setTouched();
          form.localeInput.$setValidity(type, !bool);
        } else {
          form.localeAutocomplete.$setValidity(type, !bool);
        }
      }

      checkLocale(loc) {
        Localization.check({ locale: loc.locale }).$promise
          .then((res) => {
            if (res.valid) {
              this.saveLocale(loc);
            } else {
              this.setError(loc._form, 'invalidLocale', true);
            }
          });
      }

      saveLocale(locale) {
        this.generating = true;
        locale._current = true;

        this.toggleEditing(false);

        locale.$save()
          .then((loc) => {
            locale.setUpdatedTime(loc.updated);
            this.filteringSuggests();
            this.makeKeys(locale);
          })
          .catch((e) => {
            const errorMessage = _.get(e, 'data.ui-localization.__all__', '');
            const errorType = errorMessage.length > 0 ? 'alreadyExist' : 'invalidLocale';
            this.setError(locale._form, errorType, true);
            this.resetLocale(locale);
          });
      }

      makeKeys(locale) {
        return Localization.makeKeys({ id: this.ui.id }).$promise
          .then(() => {
            this.resourcesHasChanged = false;

            return this.startPolling(locale);
          })
          .catch(() => {
            this.resetLocale(locale);
            this.setError(locale._form, 'cannotCreate', true);
          });
      }

      startPolling(locale) {
        return $q((resolve, reject) => {
          const poller = () => (
            ConnectorUI.get({ app: this.conn.id }).$promise
              .then((ui) => {
                this.updateResource(this.ui = ui);
                const isFailed = this.ui.isLocalesFailed();

                if (this.ui.isLocalesOK() || isFailed) {
                  this.stopPolling(locale, resolve, isFailed);
                }
              })
              .catch((e) => {
                this.error(e);
                reject(e);
              })
          );

          polling.add(poller, 2000, { alias: locale.locale });
        });
      }

      stopPolling(locale, resolve, isFailed) {
        this.fetchLocales().then(() => {
          if (isFailed) {
            this.setError(locale._form, 'cannotCreate', true);
          }

          this.resetLocale(locale);
          polling.stop(locale.locale);
          resolve(isFailed);
        });
      }

      fetchLocales() {
        return Localization.query({ app: this.conn.app_id }).$promise
          .then((locales) => {
            locales.forEach((loc) => {
              const locale = _.find(this.locales, { id: loc.id });

              if (_.isObject(locale)) {
                locale.setUpdatedTime(loc.updated);
              }
            });
          });
      }

      resetLocale(locale) {
        this.generating = false;
        this.downloading = false;
        delete locale._current;

        this.toggleEditing(true);
      }

      downloadLocale(locale) {
        const download = () => {
          this.downloading = locale._current = true;

          Localization.download({ id: locale.id }).$promise
            .then((file) => {
              downloader.download({
                fileName: `${locale.locale}.po`,
                url: URL.createObjectURL(new Blob(file.blob)),
              });

              this.resetLocale(locale);
            });
        };

        if (this.resourcesHasChanged) {
          this.generating = true;
          locale._current = true;
          this.toggleEditing(false);
          this.makeKeys(locale).then(() => download());

          return;
        }

        download();
      }

      uploadLocale(file, form) {
        if (!_.isObject(file)) {
          return;
        }

        this.uploading = true;
        const reader = new FileReader();
        reader.onload = () => this.selectedFile(reader.result, form);
        reader.readAsText(file);
      }

      selectedFile(fileContent, form) {
        const data = { app_id: this.conn.id, file: fileContent };

        form.fileInput.$setValidity('invalidFile', true);

        Localization.upload({}, data).$promise
          .then((loc) => {
            const locale = _.find(this.locales, { locale: loc.locale });
            locale.setUpdatedTime(loc.updated);
            this.uploading = false;
            this.success(`Locale "${loc.locale}" was updated`, 8000);
          })
          .catch(() => {
            this.uploading = false;
            form.fileInput.$setValidity('invalidFile', false);
          });
      }

      deleteLocale(loc) {
        let promise = $q.resolve();

        if (loc.id.length > 0) {
          promise = loc.$delete({ id: loc.id });
        }

        promise
          .then(() => {
            const index = this.locales.indexOf(loc);
            this.locales.splice(index, 1);
            this.filteringSuggests();
          })
          .catch(this.error);
      }

      addTmplLocale() {
        this.locales.push(
          new Localization({
            app: this.conn.resource_uri,
            ui: this.ui.resource_uri,
            id: '',
            locale: '',
            updated: '',
          })
        );
      }

      success(msg, delay) {
        const toast = $mdToast
          .simple()
          .textContent(msg)
          .position('bottom right')
          .hideDelay(delay)
          .theme('customTheme');

        $mdToast.show(toast);
      }

      error(e) {
        const errorMessage = _.get(e, 'data.error', 'Sorry, something went wrong');
        const toast = $mdToast
          .simple()
          .textContent(errorMessage)
          .position('bottom right')
          .hideDelay(5000)
          .theme('customTheme');

        $mdToast.show(toast);
      }
    }

    return Locale;
  },
]);
