const _ = require('lodash');
const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditInstallationsController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  'InstallationRequest',
  function connEditInstallationsController(
    $scope,
    $state,
    $stateParams,
    $q,
    InstallationRequest
  ) {
    $scope.promise = InstallationRequest.query({ app__app_id: $stateParams.connId }).$promise
      .then((reqs) => {
        $scope.requests = reqs.map((req) => {
          const connector = req.app;
          req.connectorVersion = connector.master ? connector.release - 1 : connector.release;

          return req;
        });
      });

    $scope.requestStyle = (item) => {
      const status = _.lowerCase(item.getStatus());

      return `request-status--${status}`;
    };
  },
]);
