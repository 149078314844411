const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.directive('uploadFile', [
  function uploadFile() {
    return {
      restrict: 'E',
      scope: {
        id: '@',
        name: '@',
        text: '@',
        accept: '@',
        maxSize: '@',
        require: '=?',
        disable: '=?',
        clearButton: '=?',
        model: '=',
      },
      templateUrl: 'dist/directives/uploadFile/uploadFile.html',
      link(scope, element) {
        scope.id = scope.id || 'fileInput';
        scope.name = scope.name || 'fileInput';
        scope.text = scope.text || 'Choose file';
        scope.accept = scope.accept || '';
        scope.maxSize = scope.maxSize || '3MB';

        scope.fileInput = element[0].querySelector('input[type="file"]');

        scope.triggerClick = () => {
          scope.clearForm();
          scope.fileInput.click();
        };

        scope.clearForm = () => {
          scope.fileName = scope.model = '';
        };

        scope.$watch('model', (newFile, prevFile) => {
          if (newFile !== prevFile) {
            scope.fileName = _.isObject(newFile) ? newFile.name : '';
          }
        });
      },
    };
  },
]);
