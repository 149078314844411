const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ErrorReport', [
  '$resource',
  'config',
  'baseModel',
  function ErrorReportModel(
    $resource,
    config,
    Base
  ) {
    class ErrorReport extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/spa-errors/';
        this.actions = _.extend({}, this.actions, {});

        return this.factory();
      }
    }

    return new ErrorReport();
  },
]);
