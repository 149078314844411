const angular = require('angular');
const app = angular.module('sweeft-spa');

app.factory('polling', [
  '$q',
  '$rootScope',
  '$interval',
  '$log',
  '$state',
  function pollingFactory(
    $q,
    $rootScope,
    $interval,
    $log,
    $state
  ) {
    const defaultPollingTime = 10000;
    const polls = {};
    const polling = {
      add: (func, interval, settings) => {
        const { alias, callback, nostate = false } = settings ||
          { alias: null, callback: null, nostate: null };
        let stateName = $state.current.name;

        if (nostate) {
          stateName = 'non-state';
        }

        function getPollAlias() {
          if (alias) {
            return alias;
          } else if (polls[stateName]) {
            return `poll${Object.keys(polls[stateName]).length}`;
          }

          return 'poll';
        }

        const params = {
          alias: getPollAlias(),
          callback: callback || null,
          state: stateName,
        };

        if (!polls[params.state]) {
          polls[params.state] = {};
        }

        polls[params.state][params.alias] = {
          interval: null,
          pollingPromise: null,
        };

        const poll = polls[params.state][params.alias];

        function poller() {
          if (poll.pollingPromise === null ||
            poll.pollingPromise.$$state.status === 1) {
            const res = func(callback);

            if (res && angular.isFunction(res.then)) {
              poll.pollingPromise = res;
            } else {
              $interval.cancel(poll.interval);
              $log.error('Poll creation error. Polled function should return Promise');
            }
          }
        }

        poll.interval =
          $interval(poller, interval || defaultPollingTime);
      },
      stop: (pollingName, state) => {
        if (!state || state !== 'non-state') {
          const inState = state || $state.current.name;

          if (pollingName && polls[inState] && polls[inState][pollingName]) {
            $interval.cancel(polls[inState][pollingName].interval);

            delete polls[inState][pollingName];

            if (polls[inState] && Object.keys(polls[inState]).length === 0) {
              delete polls[inState];
            }
          } else if (!pollingName) {
            polling.stopAll(inState);
          }
        }
      },
      stopAll: (givenStateName) => {
        if (!givenStateName || givenStateName !== 'non-state') {
          const inState = givenStateName || $state.current.name;

          angular.forEach(polls[inState], (value) => {
            $interval.cancel(value.interval);
          });

          delete polls[inState];
        }
      },
      stopGlobalPolls: () => {
        angular.forEach(polls['non-state'], (value) => {
          $interval.cancel(value.interval);
        });

        delete polls['non-state'];
      },
    };

    $rootScope.$on('$stateChangeStart', (event, toState, toParams, fromState) => {
      if (fromState.name) {
        polling.stopAll(fromState.name);
      }
    });

    return polling;
  },
]);
