const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('loadingIndicator', [
  '$timeout',
  function loadingIndicator(
    $timeout
  ) {
    return {
      restrict: 'AE',
      scope: {
        until: '=',
      },
      transclude: true,
      templateUrl(element, attrs) {
        const base = '/dist/directives/loadingIndicator/loadingIndicator';
        const templateMap = {
          ngif: 'if.html',
          ngshow: 'show.html',
        };
        let use = templateMap.ngif;

        if (attrs.use) {
          use = templateMap[attrs.use] || templateMap.ngif;
        }

        return `${base}/${use}`;
      },
      link(scope) {
        scope.loadingIndicator = true;

        scope.until.then(() => {
          scope.loadingIndicator = false;

          $timeout(() => scope.$emit('loading.done'));
        });

        scope.$on('loadingIndicator:start', () => {
          scope.loadingIndicator = true;
        });

        scope.$on('loadingIndicator:stop', () => {
          scope.loadingIndicator = false;
        });
      },
    };
  },
]);
