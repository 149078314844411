const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.filter('firstUnitIsUsers', () => (items) => {
  const users = _.find(items, (unit) => unit.unit === 'users');
  const filter = users ? _.concat(users, _.filter(items, (unit) => unit.unit !== 'users')) : items;

  return filter;
});
