const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('resetPasswordController', [
  '$scope',
  '$state',
  '$mdDialog',
  'auth',
  '$stateParams',
  'User',
  '$location',
  function resetPasswordController(
    $scope,
    $state,
    $mdDialog,
    auth,
    $stateParams,
    User,
    $location
  ) {
    const formData = {
      code: $stateParams.code,
      password: '',
    };

    $location.search('code', null);

    $scope.formData = formData;

    function dialog(text) {
      const parent = angular.element(document.querySelector('#resetPasswordApp'));

      return $mdDialog.show(
        $mdDialog
        .alert()
        .parent(parent)
        .clickOutsideToClose(true)
        .content(text)
        .ok('Got it!')
      );
    }

    User.checkCode(formData).$promise
      .then((data) => {
        $scope.email = data.email;
      })
      .catch(() => {
        dialog('Invalid link').then(() => {
          $state.go('login');
        });
      });

    $scope.submit = () => {
      if ($scope.processing) {
        return;
      }

      $scope.processing = true;

      User.resetPassword(formData).$promise.then((userData) => {
        userData.isAuthenticated = true;
        auth.init(userData);
        auth.setCredentials();

        if (auth.user.isVendor) {
          $state.go('isv.connector.list');
        } else {
          $state.go('provider.connector.list');
        }
      })
      .catch((response) => {
        if (response.data.error) {
          dialog(response.data.error);
        } else {
          dialog('Something went wrong');
        }
      })
      .finally(() => {
        $scope.processing = false;
      });
    };
  },
]);
