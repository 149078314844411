const BUILD_PACKAGE = 'build_package';


const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connEditPublicationController', [
  '$scope',
  '$q',
  '$stateParams',
  '$mdStepper',
  '$mdToast',
  'Connector',
  'ConnectorPublication',
  'FakeData',
  'yamlEditorService',
  'colors',
  'accountSchema',
  'mirror',
  function connEditPublicationController(
    $scope,
    $q,
    $stateParams,
    $mdStepper,
    $mdToast,
    Connector,
    ConnectorPublication,
    FakeData,
    yamlEditorService,
    colors,
    accountSchema,
    mirror
  ) {
    const samples = [
      {
        title: 'A1',
        name: 'account1',
        data: {},
      },
      {
        title: 'A2',
        name: 'account2',
        data: {},
      },
      {
        title: 'L1',
        name: 'usageLimit1',
        data: {},
      },
      {
        title: 'L2',
        name: 'usageLimit2',
        data: {},
      },
    ];

    const helpText = 'Publishing will be available once validation is completed';
    $scope.cleanWatcher = null;
    $scope.samples = samples;

    $scope.validation = {
      processingValidate: false,

      checkAbility: () => {
        // publish is not possible with mock settings
        const configuration = _.get($scope, 'configuration', {});
        if (configuration.is_mock) {
          $scope.statusChecks.is_mock = true;

          return false;
        }

        $scope.statusChecks.is_mock = false;

        return $scope.statusChecks.previewReady
          && $scope.statusChecks.publicationReady
          && $scope.statusChecks.sandboxReady;
      },

      validate: () => {
        if ($scope.validation.processingValidate) return;

        const payload = {};
        _.forEach(samples, (sample) => {
          payload[sample.name] = sample.data;
        });

        $scope.isValidationBeingAborted = false;
        $scope.statusChecks.publicationReady = false;
        $scope.publication.status = $scope.publication.state.IN_PROGRESS;
        $scope.stateResult.clearCache();

        $scope.publication.states.length = 0;
        $scope.$parent.stateInfo.publication = {};

        $scope.setInProgressState($scope.validation.processingValidate = true);

        Connector.publication({ connId: $stateParams.connId }, payload)
          .$promise
          .then(() => $scope.getPublication())
          .then((arg) => {
            $scope.setInProgressState($scope.validation.processingValidate = false);

            return $scope.checkPublicationStatus(arg);
          })
          .then(() => {
            if ($scope.isFailed()) {
              $scope.setFailedState();
            }
          })
          .catch(() => {
            const toast = $mdToast
              .simple()
              .textContent('Sorry, something went wrong')
              .position('bottom right')
              .hideDelay(5000)
              .theme('customTheme');

            $mdToast.show(toast);
          });
      },

      restartState: (s) => {
        $scope.isValidationBeingAborted = false;
        $scope.statusChecks.publicationReady = false;
        $scope.publication.status = $scope.publication.state.IN_PROGRESS;
        $scope.stateResult.clearCache();

        _.forEach($scope.publication.states, (item) => {
          if (item.name === s.name) {
            item.status = s.state.IN_PROGRESS;
            s.result = '';
            item.showResult = false;
            $scope.$parent.stateInfo.publication[s.name] = false;
          }
        });

        $scope.setInProgressState();

        ConnectorPublication.restartState({ connId: $stateParams.connId }, { state: s.name })
          .$promise
          .then(() => $scope.getPublication())
          .then($scope.checkPublicationStatus)
          .catch(() => {
            const toast = $mdToast
              .simple()
              .textContent('Sorry, something went wrong')
              .position('bottom right')
              .hideDelay(5000)
              .theme('customTheme');

            $mdToast.show(toast);
          });
      },

      abort: () => {
        $scope.isValidationBeingAborted = true;

        return Connector.abortValidation({ connId: $stateParams.connId }, {});
      },
    };

    $scope.publishing = {
      helpText,
      disabled: true,
      processingReset: false,
      showInfo: true,
      state: 'waiting',
      isWaiting: () => $scope.publishing.state === 'waiting',
      isRunning: () => $scope.publishing.state === 'run',
      hasSucceeded: () => $scope.publishing.state === 'success',
      hasFailed: () => $scope.publishing.state === 'fail',
      resetState: () => {
        if ($scope.publishing.processingReset) return;

        const cacheStatus = $scope.publication.status;
        $scope.publication.status = $scope.publication.state.NONE;
        $scope.publishing.processingReset = true;

        ConnectorPublication.resetState(
          { id: $scope.publication.id },
          $scope.publication
        )
          .$promise
          .then(() => $scope.getPublication())
          .then($scope.checkPublicationStatus)
          .then(() => $scope.fakeData.load())
          .then(() => {
            const stepper = $mdStepper('publishing-steps');

            $scope.stateResult.clearCache();
            $scope.publication.states.length = 0;

            $scope.setInitialState();

            $scope.publishing.disabled = true;
            $scope.publishing.processingReset = false;
            $scope.publishing.helpText = helpText;

            stepper.goto(0);
          })
          .catch(() => {
            $scope.publication.status = cacheStatus;
            $scope.publishing.processingReset = false;

            const toast = $mdToast
              .simple()
              .textContent('Sorry, something went wrong')
              .position('bottom right')
              .hideDelay(5000)
              .theme('customTheme');

            $mdToast.show(toast);
          });
      },
      publish: () => {
        $scope.publishing.state = 'run';
        $scope.statusChecks.versionDeploymentReady = false;
        let version;

        Connector.publish({ connId: $stateParams.connId }, {})
          .$promise
          .then((ver) => {
            version = ver;

            return $scope.deployVersion();
          })
          .then($scope.checkDeployVersion)
          .then(() => {
            $scope.statusChecks.versionDeploymentReady = true;
            const name = `Version ${version.release}`;
            $scope.versions.list.splice(1, 0, _.assign({ name }, version));

            return $scope.changeVersion(_.assign({ name: 'Master' }, $scope.conn)).$promise;
          })
          .then(() => ($scope.publishing.state = 'success'))
          .catch(() => {
            $scope.statusChecks.versionDeploymentReady = true;
            $scope.publishing.state = 'fail';

            const toast = $mdToast
              .simple()
              .textContent('Sorry, something went wrong')
              .position('bottom right')
              .hideDelay(5000)
              .theme('customTheme');

            $mdToast.show(toast);
          });
      },
    };

    $scope.fakeData = {
      load: () => (
        $q.all([
          FakeData.generate(accountSchema).$promise
            .then($scope.fakeData.save('account1')),
          FakeData.generate(accountSchema).$promise
            .then($scope.fakeData.save('account2')),
        ])
        .then(() => {
          const usageLimit1 = {};
          const usageLimit2 = {};
          _.forEach($scope.conn.resources, (res, index) => {
            if (res.type !== 'SUBSCRIPTION_SERVICE') {
              const limit = _.get(res, 'limit', index * 100);
              usageLimit1[res.name] = {
                name: res.title,
                limit,
              };
              usageLimit2[res.name] = {
                name: res.title,
                limit: limit + _.random(1, 99),
              };
            }
          });

          samples[2].data = usageLimit1;
          samples[3].data = usageLimit2;
        })
      ),
      save: accountName => (data) => {
        const account = _.find(samples, _.matchesProperty('name', accountName));

        account.data = angular.fromJson(angular.toJson(data));
      },
    };

    function statusIs(status, type = 'publication') {
      return $scope[type].status === status;
    }

    const sandbox = $scope.getSandboxStatus();
    const publication = $scope.getPublication();
    const preview = $scope.getPreview();
    const deployVersion = $scope.deployVersion();
    const isMaster = $scope.versionGoToMaster();

    sandbox.then($scope.checkSandboxStatus);
    publication.then($scope.checkPublicationStatus);
    preview.then($scope.checkPreviewStatus);
    deployVersion.then($scope.checkDeployVersion);

    $scope.promise = $q.all([sandbox, publication, preview, deployVersion, isMaster])
      .then(() => $scope.fakeData.load())
      .then(() => $scope.getConfiguration())
      .then((configuration) => {
        $scope.configuration = configuration;
        $scope.setInitialState();

        if (statusIs($scope.publication.state.IN_PROGRESS)) {
          $scope.setInProgressState();
        }

        if ($scope.isFailed()) {
          $scope.setFailedState();
        }
      });

    $scope.addWatchResult = () =>
      $scope.$watch(
        () => $scope.isSuccess(),
        (newValue) => {
          if (newValue) {
            const stepper = $mdStepper('publishing-steps');
            $scope.publishing.disabled = false;
            $scope.publishing.helpText = '';
            stepper.goto(1);

            if (_.isFunction($scope.cleanWatcher)) {
              $scope.cleanWatcher();
            }
          }
        }
      );

    $scope.setInitialState = () => {
      $scope.isSuccess = () => false;

      $scope.connectorVersion = $scope.conn.release;
      $scope.fakeData.showed = false;
      $scope.fakeData.enabledToggler = true;
      $scope.validation.showButton = $scope.validation.checkAbility();
      $scope.validation.enabledButton = true;
      $scope.validation.showSteps = false;
    };

    $scope.initDeployStatus = () => {
      if ($scope.$deployVersion.isInProgress() && $scope.isComplete()) {
        $scope.isSuccess = $scope.isComplete;
        $scope.addWatchResult();
      }
    };

    $scope.setInProgressState = (waitSpinner) => {
      if (waitSpinner === false) {
        $scope.validation.showButton = waitSpinner;
        $scope.validation.showSteps = !waitSpinner;
        return;
      }

      $scope.isSuccess = $scope.isComplete;

      $scope.fakeData.enabledToggler = false;
      $scope.validation.showButton = waitSpinner;
      $scope.validation.showSteps = !waitSpinner;

      $scope.cleanWatcher = $scope.addWatchResult();
    };

    $scope.setFailedState = () => {
      $scope.isSuccess = () => false;

      $scope.fakeData.enabledToggler = false;
      $scope.validation.showButton = false;
      $scope.validation.showSteps = true;

      if (_.isFunction($scope.cleanWatcher)) {
        $scope.cleanWatcher();
      }
    };

    $scope.$deployVersion = {
      isInProgress: () => !$scope.statusChecks.versionDeploymentReady
        || statusIs($scope.versionDeployment.state.IN_PROGRESS, 'versionDeployment'),
      isFailed: () => statusIs($scope.versionDeployment.state.FAIL, 'versionDeployment'),
      isOK: () => $scope.statusChecks.versionDeploymentReady
        && statusIs($scope.versionDeployment.state.OK, 'versionDeployment'),
    };

    $scope.isInProgress = () => !$scope.statusChecks.publicationReady
      || statusIs($scope.publication.state.IN_PROGRESS);

    $scope.isFailed = () => statusIs($scope.publication.state.FAIL);
    $scope.isComplete = () => statusIs($scope.publication.state.OK);

    $scope.setInitialState();

    if (statusIs($scope.publication.state.IN_PROGRESS)) {
      $scope.setInProgressState();
    }

    if ($scope.isFailed()) {
      $scope.setFailedState();
    }

    $scope.showStateAbortButton = state => state.name !== BUILD_PACKAGE
      && !$scope.isValidationBeingAborted
      && $scope.stateIsInProgress(state);

    $scope.stateIsInProgress = (s) => s.status === s.state.IN_PROGRESS;
    $scope.stateIsFailed = (s) => s.status === s.state.FAIL;
    $scope.stateIsOK = (s) => s.status === s.state.OK;
    $scope.stateIsNONE = (s) => s.status === s.state.NONE;

    $scope.stateStyle = (s) => {
      let color = '';
      let fontWeight = '';

      if ($scope.stateIsNONE(s)) {
        color = 'rgba(0, 0, 0, 0.57)';
      }

      if ($scope.stateIsFailed(s)) {
        color = colors.failed;
      }

      if ($scope.stateIsInProgress(s)) {
        fontWeight = 'bold';
      }

      return {
        color,
        'font-weight': fontWeight,
      };
    };

    $scope.stateIcon = (s) => {
      const failed = $scope.stateIsFailed(s);
      const color = failed ? colors.failed : colors.theme;
      const icon = failed ? 'warning' : 'check_circle';

      return {
        style: { color },
        icon,
      };
    };

    function watchEditorStates(editorName) {
      return () => yamlEditorService.hasEditing(editorName);
    }

    function handleEditorStates(newValue) {
      if (newValue === true || newValue === false) {
        $scope.validation.enabledButton = _.every(samples, sample =>
          !yamlEditorService.hasEditing(sample.name) &&
            !yamlEditorService.hasError(sample.name)
        );
      }
    }

    _.forEach(samples, (sample) => {
      $scope.$watch(watchEditorStates(sample.name), handleEditorStates);
    });

    $scope.$on('$destroy', $scope.clearPollTimeouts);

    $scope.switchDataCustomize = () => {
      $scope.validation.enabledButton = true;
    };

    mirror({
      samples,
      helpText,
      statusIs,
      watchEditorStates,
      handleEditorStates,
    }, $scope);
  },
]);
