const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditGuideController', [
  '$scope',
  function connEditGuideController(
    $scope
  ) {
    $scope.displayWelcomeMessage = false;

    if (localStorage.getItem('displayWelcomeMessage') === null) {
      $scope.displayWelcomeMessage = true;
    }

    $scope.hideWelcomeMessage = () => {
      localStorage.setItem('displayWelcomeMessage', false);
      $scope.displayWelcomeMessage = false;
    };
  },
]);
