const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('previewRedirectController', [
  '$q',
  '$scope',
  '$stateParams',
  '$state',
  '$window',
  '$mdDialog',
  'polling',
  'preview',
  'ConnectorPreview',
  'mirror',
  function previewRedirectController(
    $q,
    $scope,
    $stateParams,
    $state,
    $window,
    $mdDialog,
    polling,
    previewService,
    ConnectorPreview,
    mirror
  ) {
    function wrongAlert() {
      $mdDialog.show(
        $mdDialog
          .alert()
          .clickOutsideToClose(true)
          .theme('customTheme')
          .content('Something went wrong')
          .ok('Ok')
      ).then($window.close);
    }

    function getPreview() {
      return ConnectorPreview.get({
        app: $stateParams.connId,
        limit: 1,
        order_by: '-created',
      }).$promise;
    }

    const check = (_preview) => {
      if (_preview.session_id && _preview.session_id !== 'fail') {
        const params = {
          connId: $stateParams.connId,
        };

        if (previewService.isPreviewMode()) {
          params.is_preview_mode = true;
        }

        ConnectorPreview.getCCPUrl(params, {}, (res) => {
          if (res.url) {
            $window.open(res.url, '_self');
          } else {
            wrongAlert();
            polling.stop();
          }
        });
      } else if (_preview.session_id === 'fail') {
        wrongAlert();
        polling.stop();
      }
    };

    const checkIteration = (preview) => {
      if ((preview.session_id && preview.session_id !== 'fail')
        || preview.session_id === 'fail') {
        return $q.when(check(preview));
      }

      return getPreview().then(check);
    };

    function checkPreviewSession(preview) {
      polling.add(() => checkIteration(preview), 5000);
    }

    mirror({
      wrongAlert,
      check,
      checkIteration,
      checkPreviewSession,
    }, $scope);

    ConnectorPreview.createSession({
      connId: $stateParams.connId,
    }, {
      version: $stateParams.version,
      locale: $stateParams.locale,
      is_preview_mode: previewService.isPreviewMode(),
    })
      .$promise
      .then(getPreview)
      .then(checkPreviewSession);
  },
]);
