const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');

app.controller('hubConnectorsSelectionController', [
  '$scope',
  '$state',
  '$mdToast',
  'InstallationRequest',
  function hubConnectorsSelectionController(
    $scope,
    $state,
    $mdToast,
    InstallationRequest
  ) {
    $scope.createRequest = () => {
      if ($scope.selection.processing) {
        return;
      }

      $scope.selection.processing = true;
      InstallationRequest.save({
        app: $scope.selection.connectorId,
        oa_instance: $scope.hub.resource_uri,
      }).$promise
        .then(() => {
          $state.go('hub.edit.connectors.list');
        })
        .catch(() => {
          const toast = $mdToast
            .simple()
            .textContent('Sorry, something went wrong')
            .position('bottom right')
            .hideDelay(5000)
            .theme('customTheme');

          $mdToast.show(toast);
        })
        .finally(() => ($scope.selection.processing = false));
    };

    $scope.selection = {
      connectorId: '',
      processing: false,
    };

    $scope.promise = $scope.connectors.$promise
      .then(() => {
        const firstConn = _.head($scope.connectors);
        $scope.selection.connectorId = firstConn.resource_uri;
      });
  },
]);
