const angular = require('angular');
const yaml = require('js-yaml');


const app = angular.module('yamlEditor', [
  'xeditable',
]);


function parseYaml(data) {
  let res;
  try {
    res = yaml.safeLoad(data);
  } catch (err) {
    let errorMessage = 'Invalid format';
    const pattern = /^[\D\d]+at\sline\s\d+,\scolumn\s\d+:([\D\d]+)\^/;
    if (err && angular.isString(err.message)) {
      const matches = err.message.match(pattern);
      if (matches !== null) {
        errorMessage = `Syntax error near: ${matches[1]}`;
      }
    }

    return errorMessage;
  }

  return res;
}


app.directive('yamlEditor', [
  function yamlEditor() {
    return {
      restrict: 'E',
      templateUrl: 'dist/directives/yamlEditor/yamlEditor.html',
      scope: {
        disabled: '<',
        name: '<',
        data: '=',
        placeholder: '@',
        onChange: '&',
      },
      controller: [
        '$scope',
        'yamlEditorService',
        function yamlEditorController(
          $scope,
          yamlEditorService
        ) {
          let disabled = false;

          yamlEditorService.createEditor($scope.name);

          $scope.hover = false;

          $scope.switchHoverState = (isShown) => {
            if (!disabled) {
              $scope.hover = !isShown;
            }
          };

          $scope.switchActiveState = (isShown) => {
            $scope.switchHoverState(isShown);
            yamlEditorService.hasEditing($scope.name, isShown);
          };

          $scope.cancelEditing = () => {
            yamlEditorService.hasError($scope.name, false);
          };

          $scope.onError = () => {
            yamlEditorService.hasError($scope.name, true);
          };

          $scope.clearError = () => {
            yamlEditorService.hasError($scope.name, false);
          };

          $scope.onClick = (event) => {
            if (disabled) {
              event.preventDefault();
              event.stopImmediatePropagation();
            }
          };

          $scope.$watch('disabled', (value) => {
            if (!angular.isUndefined(value)) {
              disabled = value;
            }
          });
        },
      ],
      controllerAs: 'yamlEditor',
      link(scope) {
        if (!scope.name) {
          throw new Error('Name attribute is required for yamlEditor');
        }

        scope.beforeSave = (data) => {
          const s = parseYaml(data);

          if (angular.isString(s)) {
            scope.onError();

            return s.includes('Syntax error near') ? s : `Invalid data format: ${s}`;
          }

          scope.data = s;
          scope.clearError();

          return undefined;
        };

        scope.afterSave = () => {
          if (angular.isFunction(scope.onChange)) {
            scope.onChange({ $data: scope.data });
          }
        };

        if (!isNaN(scope.placeholder)) {
          scope.yaml = scope.data;
        } else {
          scope.yaml = yaml.safeDump(scope.data).trimRight('\n');
        }
      },
    };
  }]);


app.service('yamlEditorService', [
  function yamlEditorService() {
    const editors = {};

    const API = {
      createEditor(name) {
        editors[name] = {
          hasError: false,
          hasEditing: false,
        };
      },
      hasError(name, err) {
        if (!editors.hasOwnProperty(name)) {
          return undefined;
        }

        if (!angular.isUndefined(err)) {
          editors[name].hasError = !!err;
        }

        return editors[name].hasError;
      },
      hasEditing(name, state) {
        if (!editors.hasOwnProperty(name)) {
          return undefined;
        }

        if (!angular.isUndefined(state)) {
          editors[name].hasEditing = state;
        }

        return editors[name].hasEditing;
      },
    };

    return API;
  },
]);


app.directive('textareaYaml', [
  function textareaYaml() {
    return {
      restrict: 'E',
      replace: true,
      template: '<textarea></textarea>',
      scope: {},
      controller: [
        '$scope',
        function textAreaYamlController() {
        },
      ],
    };
  },
]);


app.directive('editableTextareaYaml', [
  'editableDirectiveFactory',
  function editableTextareaYaml(
    editableDirectiveFactory
  ) {
    return editableDirectiveFactory({
      directiveName: 'editableTextareaYaml',
      inputTpl: '<textarea-yaml></textarea-yaml>',
      addListeners() {
        const self = this;
        self.parent.addListeners.call(self);
        // submit textarea by ctrl+enter even with buttons
        if (self.single && self.buttons !== 'no') {
          self.autosubmit();
        }
      },
      autosubmit() {
        const self = this;
        self.inputEl.bind('keydown', (e) => {
          if ((e.ctrlKey || e.metaKey) && (e.keyCode === 13)) {
            this.scope.$apply(() => {
              this.scope.$form.$submit();
            });
          }
        });
      },
    });
  },
]);


app.run([
  'editableOptions',
  'editableThemes',
  function editableThemeConfig(
    editableOptions,
    editableThemes
  ) {
    editableThemes.material = {
      formTpl: '<form class="editable-wrap"></form>',
      noformTpl: '<span class="editable-wrap"></span>',
      controlsTpl: '<md-input-container flex class="editable-controls" ' +
        'ng-class="{\'md-input-invalid\': $error}"></md-input-container>',
      inputTpl: '',
      errorTpl: '<div ng-messages="{message: $error}"><div class="editable-error" ' +
        'ng-message="message"><md-icon class="md-warn icon icon_offset-rigth"' +
        'md-font-icon>warning</md-icon> {{$error}}</div></div>',
      buttonsTpl: '<md-card-actions class="editable-actions"' +
        'layout="row" layout-align="end center"></md-card-actions>',
      cancelTpl: '<md-button class="cancel-button" type="button" ' +
      'flex-order="1" ng-click="$form.$cancel()">cancel</md-button>',
      submitTpl: '<md-button type="submit" flex-order="2" ' +
      'class="md-primary apply-button">Apply</md-button>',
    };

    editableOptions.theme = 'material';
    editableOptions.blurElem = 'ignore';
  },
]);
