const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('labApiLogsController', [
  '$q',
  '$scope',
  '$state',
  '$timeout',
  'polling',
  'config',
  'Connector',
  'TestLabLogs',
  function labApiLogsController(
    $q,
    $scope,
    $state,
    $timeout,
    polling,
    config,
    Connector,
    TestLabLogs
  ) {
    $scope.entries = [];

    $scope.logs = TestLabLogs.apiLogsFactory();

    $scope.next = () => $scope.logs.getLogs({
      cluster: config.cluster,
      namespace: config.namespace,
      project: config.project,
      container: $scope.conn.sandbox.connector.configuration.name,
    });

    $scope.getForemost = () => {
      if (!$scope.conn.sandbox.connector.status) {
        $scope.conn = Connector.get({ connId: $scope.conn.id });

        return $scope.conn.$promise.then($scope.next);
      }

      return $scope.next();
    };

    $scope.runPolling = (entries) => {
      $scope.entries = entries;
      $scope.entriesLength = $scope.logs.apiLogsLength;

      polling.add(() => {
        const poll = $scope.logs.pollLogs().then((updateEntries) => {
          $scope.entries = updateEntries.concat($scope.entries).slice(0, 100);
          $scope.entriesLength = $scope.logs.apiLogsLength;
        });

        $scope.pollingPromise = $q.all([poll.$promise, $timeout(_.noop, 1000)]);

        return poll;
      }, 5000);
    };

    $scope.startLogsPolling = () => $q.all([$scope.conn.$promise, $scope.logs.$promise])
      .then($scope.getForemost)
      .then($scope.runPolling);

    $scope.promise = $scope.checkSandbox()
      .then((status) => {
        if ($scope.sandboxIsReady()) {
          return $scope.startLogsPolling();
        }

        $scope.checkSandboxStatus(status).then($scope.startLogsPolling);

        return true;
      });

    $scope.$on('$destroy', $scope.clearPollTimeouts);
  },
]);
