const angular = require('angular');


const app = angular.module('sweeft-spa');


app.constant('colors', {
  theme: '#0091EE',
  failed: '#dd1144',
  disabled: '#757575',
  success: '#00c752',
});
