const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('rootStateController', [
  '$scope',
  '$state',
  'auth',
  function rootStateController(
    $scope,
    $state,
    auth
  ) {
    if (auth.user.isVendor) {
      $state.go('isv.connector.list');
    } else {
      $state.go('provider.connector.list');
    }
  },
]);
