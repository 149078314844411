const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('SandboxService', [
  '$resource',
  'config',
  'baseModel',
  function SandboxServiceModel(
    $resource,
    config,
    Base
  ) {
    class SandboxService extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/sandbox-service/:id';

        this.paramDefaults = { id: '@id' };

        this.actions = _.extend({}, this.actions, {
          check: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/sandbox-service-connector/:connId/`,
          },
        });

        return this.factory();
      }
    }

    return new SandboxService();
  },
]);
