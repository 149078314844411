const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.directive('ensureUniqueValues', [
  'resourcesStorage',
  function ensureUniqueValues(resourcesStorage) {
    return {
      require: 'ngModel',
      link(scope, el, attrs, ngModel) {
        let stateValidation;

        const setValidity = (element, state) => {
          element.model.$setValidity('ensureUniqueValues', state);
        };

        const validation = (value) => {
          if (value && _.filter(resourcesStorage.resources, { id: value }).length > 1) {
            stateValidation = false;
          } else {
            stateValidation = true;
          }

          _.forEach(resourcesStorage.resources, (val1, key1) => {
            setValidity(val1, true);

            _.forEach(resourcesStorage.resources, (val2, key2) => {
              if (val1.id === val2.id && key1 !== key2) {
                setValidity(val1, false);
                setValidity(val2, false);
              }
            });
          });
        };

        el.on('$destroy', () => {
          resourcesStorage.clear(scope.$id);
          validation();
        });

        ngModel.$validators.ensureUniqueValues = (value) => {
          if (!_.isUndefined(value)) {
            const dataModel = {
              id: value,
              model: ngModel,
            };

            resourcesStorage.addResource(scope.$id, dataModel);

            validation(value);
          }

          return stateValidation;
        };
      },
    };
  },
]);


app.factory('resourcesStorage', [
  function resourcesStorageService() {
    const storage = {
      resources: {},

      addResource(id, data) {
        storage.resources[id] = data;
      },

      clear(id) {
        delete storage.resources[id];
      },
    };

    return storage;
  },
]);
