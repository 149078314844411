const angular = require('angular');


const app = angular.module('sweeft-spa');


app.service('errorMessages', [
  function errorMessagesService() {
    const messages = {};

    return {
      add(id, message) {
        messages[id] = message;
      },
      get(id) {
        return messages[id];
      },
    };
  },
]);
