const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.service('downloader', [
  '$document',
  function downloaderService($document) {
    const link = $document[0].createElement('a');
    link.target = '_blank';
    $document[0].body.appendChild(link);

    return {
      download(options = {}) {
        if (_.isString(options.fileName) && options.fileName.length > 0) {
          link.download = options.fileName;
        }

        link.href = options.url;
        link.click();
      },
    };
  },
]);
