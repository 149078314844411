const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ConnectorPublication', [
  '$resource',
  '$q',
  'config',
  'polling',
  'baseModel',
  'StateModel',
  function ConnectorPublicationModel(
    $resource,
    $q,
    config,
    polling,
    Base,
    StateModel
  ) {
    const versionDeployState = (result) => {
      result.publication_status = new StateModel(result.publication_status);

      return result;
    };
    class ConnectorPublication extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/application-validation/:id/';

        this.actions = _.extend({}, this.actions, {
          restartState: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application-validation/:connId/restart-state/`,
          },

          resetState: {
            method: 'PUT',
            url: `${config.apiBaseUrl}/api/v1/application-validation/:id/`,
          },
        });

        return class extends this.factory() {
          init() {
            this.state = {
              NONE: 0,
              IN_PROGRESS: 1,
              FAIL: 2,
              OK: 3,
            };
          }

          static get(...args) {
            const publication = super.get(...args);

            publication.$promise = publication.$promise.then(versionDeployState);

            return publication;
          }

          getStates() {
            return _.map(this.states, (state) => new StateModel(state));
          }
        };
      }
    }

    return new ConnectorPublication();
  },
]);
