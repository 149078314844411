const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ConnectorPreview', [
  '$resource',
  'config',
  'baseModel',
  'StateModel',
  function ConnectorPreviewModel(
    $resource,
    config,
    Base,
    StateModel
  ) {
    const usageState = (result) => {
      result.usage_refresh = new StateModel(result.usage_refresh);

      return result;
    };

    class ConnectorPreview extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/application-preview/:id/';

        this.actions = _.extend({}, this.actions, {
          createSession: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/application-preview/:connId/create-session/`,
          },

          getCCPUrl: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/application-preview/:connId/ccp-url/`,
          },
        });

        return class extends this.factory() {
          init() {
            this.state = {
              IN_PROGRESS: 0,
              PREVIEW_FAIL: 1,
              INSTALL_FAIL: 2,
              OK: 3,
            };

            this.$types = {
              VENDOR: 0,
              PROVIDER: 1,
            };
          }

          static get(...args) {
            const preview = super.get(...args);

            preview.$promise = preview.$promise.then(usageState);

            return preview;
          }

          getStates() {
            return _.map(this.states, (state) => new StateModel(state));
          }
        };
      }
    }

    return new ConnectorPreview();
  },
]);
