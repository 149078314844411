const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('hubSetupController', [
  '$scope',
  '$stateParams',
  'Hub',
  'polling',
  'colors',
  function hubSetupController(
    $scope,
    $stateParams,
    Hub,
    polling,
    colors
  ) {
    $scope.hubIsOnline = () => $scope.hub.isHubState($scope.hub.states.ONLINE);
    $scope.hubIsOffline = () => !$scope.hubIsOnline();

    $scope.agentIsOnline = () => $scope.hub.isAgentState($scope.hub.states.ONLINE);
    $scope.agentIsOffline = () => !$scope.agentIsOnline();

    $scope.iconOnState = (serviceType) => {
      const methodName = `${serviceType}IsOnline`;
      const isOnline = $scope[methodName]();
      let color = isOnline ? colors.theme : colors.failed;
      let icon = isOnline ? 'check_circle' : 'warning';
      if (serviceType === 'hub' && !isOnline) {
        color = colors.disabled;
        icon = 'cancel';
      }

      return {
        style: { color },
        icon,
      };
    };

    function updateHub() {
      return Hub.get({ id: $scope.hub.id }).$promise
        .then((hub) => {
          $scope.hub = hub;
        });
    }

    $scope.promise = $scope.hub.$promise
      .then(() => {
        polling.add(updateHub, 5000);
      });
  },
]);
