const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('uniqueConnectorId', [
  '$q',
  'asyncValidatorFactory',
  'Connector',
  function uniqueId(
    $q,
    asyncValidatorFactory,
    Connector
  ) {
    return asyncValidatorFactory({
      name: 'uniqueConnectorId',
      validate(id) {
        return $q((resolve, reject) => {
          Connector.checkId({ id }, ({ exists }) => {
            if (!exists) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
    });
  },
]);
