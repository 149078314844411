const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('Hub', [
  '$resource',
  '$filter',
  'config',
  'baseModel',
  function HubModel(
    $resource,
    $filter,
    config,
    Base
  ) {
    const utcToLocalFilter = $filter('utcToLocal');

    class Hub extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/oainstance/:id/';

        this.actions = _.extend({}, this.actions, {
          installApp: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/oainstance/:id/install-application/`,
          },

          create: {
            method: 'POST',
            url: `${config.apiBaseUrl}/api/v1/oainstance/create/`,
          },
        });

        return class extends this.factory() {
          init() {
            this.states = {
              ONLINE: 0,
              OFFLINE: 1,
              AUTH_ERROR: 2,
            };
          }

          isHubState(state) {
            return this.status_oa === state;
          }

          isAgentState(state) {
            return this.status_agent === state;
          }

          hubState() {
            return _.findKey(this.states, val => val === this.status_oa);
          }

          agentState() {
            return _.findKey(this.states, val => val === this.status_oa);
          }

          getVersion(type) {
            return (this[`version_${type}`] || 'N/A');
          }

          getHealthCheck() {
            let healthCheck = this.last_health_check;

            if (_.isString(healthCheck) && healthCheck.length > 0) {
              healthCheck = utcToLocalFilter(healthCheck, 'medium', true);
            } else {
              healthCheck = 'Never';
            }

            return healthCheck;
          }
        };
      }
    }

    return new Hub();
  },
]);
