const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('tooltipHelp', [
  function tooltipHelp() {
    return {
      restrict: 'E',
      scope: {
        subject: '@',
      },
      templateUrl: '/dist/directives/tooltipHelp/tooltipHelp.html',
    };
  },
]);
