const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('companyAddUserController', [
  '$scope',
  '$mdDialog',
  '$timeout',
  'Company',
  'companyInstance',
  function companyAddUserController(
    $scope,
    $mdDialog,
    $timeout,
    Company,
    companyInstance
  ) {
    $scope.userData = {
      email: '',
      role: companyInstance.$roles.ADMIN,
    };

    $scope.cancel = $mdDialog.cancel;
    $scope.processing = false;

    function success(user) {
      $scope.processing = false;
      $mdDialog.hide(user);
    }

    function error(res) {
      $scope.processing = false;
      $scope.error = res.data.error;
    }

    $scope.setCustomValidity = () => {
      $timeout(() => {
        $scope.addUserForm.email.$parsers.push((value) => {
          let isUnic = true;

          _.forEach(companyInstance.users, (item) => {
            if (item.email === value) {
              $scope.addUserForm.email.$setValidity('emailDuplicate', false);
              isUnic = false;
            }
            return value;
          });

          if (isUnic) {
            $scope.addUserForm.email.$setValidity('emailDuplicate', true);
          }

          return value;
        });
      }, 0);
    };

    $scope.addUser = () => {
      if ($scope.processing || !$scope.addUserForm.$valid) {
        return;
      }

      $scope.processing = true;

      $scope.addUserForm.$setSubmitted();

      Company
        .addUser({ id: companyInstance.id }, $scope.userData)
        .$promise
        .then(success)
        .catch(error);
    };
  },
]);
