const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('ConnectorUI', [
  '$resource',
  'config',
  'baseModel',
  'UIResource',
  'UIMetaResource',
  function ConnectorUIModel(
    $resource,
    config,
    Base,
    UIResource,
    UIMetaResource
  ) {
    function serialize(obj) {
      return _.omit(obj, ['meta', 'resources']);
    }

    const stringify = _.ary(JSON.stringify, 1);

    class ConnectorUI extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/application-ui/:id/';

        this.actions = _.extend({}, this.actions, {
          update: {
            method: 'PUT',
            transformRequest: [serialize, stringify],
          },
        });

        return class extends this.factory() {
          init() {
            this.localesStatuses = {
              NONE: 0,
              IN_PROGRESS: 1,
              FAIL: 2,
              OK: 3,
            };
          }

          isLocalesOK() {
            return this.localization_status.status === this.localesStatuses.OK;
          }

          isLocalesFailed() {
            return this.localization_status.status === this.localesStatuses.FAIL;
          }

          isLocalesInProgress() {
            return this.localization_status.status === this.localesStatuses.IN_PROGRESS;
          }

          static get(...args) {
            const ui = super.get(...args);

            ui.$promise = ui.$promise.then((model) => {
              model.resources = _.map(model.resources, (resource) => new UIResource(resource));
              model.meta = _.map(model.meta, (metaResource) => new UIMetaResource(metaResource));

              return model;
            });

            return ui;
          }
        };
      }
    }

    return new ConnectorUI();
  },
]);
