const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('hubEditController', [
  '$scope',
  '$state',
  '$stateParams',
  '$q',
  '$log',
  '$controller',
  'stateResult',
  'auth',
  'polling',
  'Hub',
  'Connector',
  function hubEditController(
    $scope,
    $state,
    $stateParams,
    $q,
    $log,
    $controller,
    stateResult,
    auth,
    polling,
    Hub,
    Connector
  ) {
    const parent = $controller('statusControllerMixin', {
      $scope,
      $stateParams,
      $state,
      $q,
      stateResult,
      auth,
      polling,
    });
    angular.extend(this, parent);

    $scope.checkVersions = () => false;

    $scope.generateMenu = () => {
      $scope.navigation = [{
        states: [
          { id: 'hub.edit.setup' },
          { id: 'hub.edit.connectors.list', parent: 'hubConnectors' },
        ],
      }];
    };

    $scope.connectors = Connector.query({ 'company-detail': true });
    $scope.hub = Hub.get({ id: $stateParams.hubId });

    $scope.parentPromise = $q.all([
      $scope.connectors.$promise,
      $scope.hub.$promise,
    ])
    .then(() => {
      $scope.pageTitle = () => $scope.hub.hub_name;

      $scope.generateMenu();
      $scope.showContent = true;

      $scope.installationRequestButton.isShown = () => $state.is('hub.edit.connectors.list');
      $scope.installationRequestButton.isEnabled = () => $scope.connectors.length > 0;
    })
    .catch((error) => {
      $log.error(`Failed to get data: ${error.statusText}` +
        `Response code is ${error.status}`);
    });
  },
]);
