const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('TestLabLogs', [
  '$resource',
  '$q',
  '$http',
  '$timeout',
  '$mdToast',
  '$window',
  'config',
  'baseModel',
  function TestLabLogsModel(
    $resource,
    $q,
    $http,
    $timeout,
    $mdToast,
    $window,
    config,
    Base
  ) {
    class TestLabLogs extends Base {
      constructor() {
        super();

        this.actions = _.extend({}, this.actions, {
          getGoogleToken: {
            method: 'GET',
            url: `${config.apiBaseUrl}/api/v1/sandbox-service/logview-token`,
          },
        });

        return class extends this.factory() {
          init() {
            this.types = {
              api: 'api',
              service: 'service',
            };
            this.apiLogs = [];
            this.serviceLogs = [];

            this.apiLogsLength = 0;
            this.serviceLogsLength = 0;

            this.retries = 0;
            this.pageSize = 30;
          }

          static serviceLogsFactory() {
            const obj = new this();

            obj.makeRequestParams = (params, extraFilters) => {
              // eslint-disable-next-line max-len
              let query = `resource.labels.module_id="${params.service}"\nresource.labels.version_id="${params.version}"\nlogName="projects/${params.project}/logs/appengine.googleapis.com%2Frequest_log"\n${params.token}\n`;

              if (extraFilters) query += extraFilters;

              const result = {
                filter: query,
                resourceNames: [`projects/${params.project}`],
                orderBy: 'timestamp desc',
              };

              if (obj.pageSize) result.pageSize = obj.pageSize;

              return result;
            };

            obj.parserFunction = obj.parseLogs(obj.types.service);
            obj.$promise = this.getGoogleToken({}, (r) => {
              obj.access_token = r.access_token;
            }).$promise;

            return obj;
          }

          static apiLogsFactory() {
            const obj = new this();

            obj.makeRequestParams = (params, extraFilters) => {
              // eslint-disable-next-line max-len
              let query = `resource.type="container"\nresource.labels.cluster_name="${params.cluster}"\nresource.labels.namespace_id="${params.namespace}"\nlogName="projects/${params.project}/logs/${params.container}"\nseverity=INFO\n`;

              if (extraFilters) query += extraFilters;

              const result = {
                filter: query,
                resourceNames: [`projects/${params.project}`],
                orderBy: 'timestamp desc',
              };

              if (obj.pageSize) result.pageSize = obj.pageSize;

              return result;
            };

            obj.parserFunction = obj.parseLogs(obj.types.api);
            obj.$promise = this.getGoogleToken({}, (r) => {
              obj.access_token = r.access_token;
            }).$promise;

            return obj;
          }

          parseLogs(logType) {
            const newLogs = [];
            const isApi = logType === this.types.api;
            const logs = `${this.types[logType]}Logs`;
            const logsLength = `${logs}Length`;
            const makeLogEntry = `make${_.capitalize(this.types[logType])}Log`;
            const reqIdPath = isApi ? 'jsonPayload.message.request_id' : 'protoPayload.requestId';
            const statusPath = isApi ? 'jsonPayload.message.status_code' : 'protoPayload.status';

            return (entries) => {
              _.forEach(entries, (entry) => {
                const existsLog = _.find(this[logs], { id: _.get(entry, reqIdPath) });
                const newLog = this[makeLogEntry](entry);

                if (_.isObject(existsLog)) {
                  this.mergeLogs(_.get(entry, statusPath), newLog, existsLog);
                  this.makePrettyLog(existsLog);
                  this.setExpandOption(existsLog);
                } else {
                  this.makePrettyLog(newLog);
                  this.setExpandOption(newLog);
                  newLogs.push(newLog);
                  this[logs].push(newLog);
                }
              });

              this[logsLength] += newLogs.length;

              if (this[logs].length > 100) {
                this[logs] = this[logs].slice(-100);
              }

              return newLogs;
            };
          }

          makeServiceLog(e) {
            const log = this.makeLogTemplate({
              requestId: e.protoPayload.requestId,
              timestamp: e.timestamp,
              method: e.protoPayload.method,
              title: e.protoPayload.resource,
              status: e.protoPayload.status,
            });

            _.forEach(_.get(e, 'protoPayload.line', []), (line) => {
              try {
                const extra = JSON.parse(line.logMessage);
                const redundantProp = 'Request-Method';

                _.forEach(_.get(extra, 'request.headers', []), (hdValue, hdName) => {
                  const name = hdName.split('_').map(_.capitalize).join('-');
                  const value = _.isArray(hdValue) ? hdValue : [hdValue];
                  log.request.headers[name] = value.join(', ');
                });

                const response = _.get(extra, 'response', {});
                const headers = _.get(response, 'headers', {});
                if (_.size(headers) > 0) {
                  _.forEach(headers, (arrHeaders) => {
                    log.response.headers[arrHeaders[0]] = arrHeaders[1];
                  });
                  log.response.body = extra.response.body;
                } else {
                  log.response.body = response;
                }

                log.request.body = extra.request.body;
                log.reseller = extra.reseller;

                delete log.request.headers[redundantProp];
              } catch (err) {
                log.validJSON = false;
              }
            });

            return log;
          }

          makeApiLog(e) {
            if (!e.jsonPayload) return false;

            const message = e.jsonPayload.message;
            const log = this.makeLogTemplate({
              requestId: message.request_id,
              timestamp: e.timestamp,
              method: message.method,
              title: message.url,
              status: message.status_code,
            });

            log.reseller = e.jsonPayload.reseller_id;
            log[message.type].body = message.data;
            log[message.type].headers = _.pickBy(message.headers, (val, key) =>
              _.startsWith(key, 'X-') || _.startsWith(key, 'Aps-')
            );

            return log;
          }

          makeLogTemplate({ requestId, timestamp, method, title, status }) {
            return {
              id: requestId,
              datetime: new Date(timestamp).toLocaleString(),
              request: {
                method,
                title,
                headers: {},
                body: {},
              },
              response: {
                status,
                headers: {},
                body: {},
              },
              reseller: '',
              expandable: false,
              validJSON: true,
            };
          }

          mergeLogs(responseCode, newLog, existsLog) {
            if (_.isNumber(responseCode)) {
              existsLog.response = newLog.response;
            } else {
              existsLog.request = newLog.request;
            }
          }

          setExpandOption(log) {
            const pattern = '{}\n';
            if (log.request.body.length > pattern.length || _.size(log.request.headers) > 0 ||
              log.response.body.length > pattern.length || _.size(log.response.headers) > 0 ||
              !log.validJSON) {
              log.expandable = true;
            }
          }

          makePrettyLog(log) {
            const parser = (rawData) => {
              const tabWidth = 2;
              let result = '';
              let objBody = {};
              try {
                if (_.isString(rawData)) {
                  objBody = JSON.parse(
                    rawData.replace(/\n/g, '')
                      .replace(/\r/g, '')
                      .replace(/\t/g, '')
                      .replace('↵', '')
                  );
                } else if (_.isObject(rawData)) {
                  objBody = rawData;
                }
              } catch (e) {
                return result;
              }

              if (_.size(objBody) > 0) {
                result = JSON.stringify(objBody, null, tabWidth);
              }

              return result;
            };
            log.request.body = parser(log.request.body);
            log.response.body = parser(log.response.body);

            if (_.size(log.request.headers) === 0) {
              log.request.headers = null;
            }

            if (_.size(log.response.headers) === 0) {
              log.response.headers = null;
            }
          }

          gaeRequest(params, extraFilters) {
            return $http({
              method: 'POST',
              url: 'https://logging.googleapis.com/v2/entries:list?alt=json',
              headers: {
                Authorization: `Bearer ${this.access_token}`,
              },
              data: this.makeRequestParams(params, extraFilters),
              withCredentials: false,
            }).then((response) => {
              this.retries = 0;

              return response;
            }, (response) => {
              if (response.status === 500) {
                this.retries = this.retries + 1;

                if (this.retries > 2) {
                  $mdToast.show(
                    $mdToast
                      .simple()
                      .position('bottom right')
                      .textContent('There are some problems during update. ' +
                                   'Try to refresh the page.')
                      .action('Refresh')
                      .highlightAction(true)
                      .hideDelay(0)
                      .theme('customTheme')
                  ).then((answer) => {
                    if (answer === 'ok') {
                      $window.location.reload();
                    }
                  });

                  return $q.reject();
                }

                return $timeout(() =>
                    this.gaeRequest(params, extraFilters), 2000);
              }

              return $q.reject();
            });
          }

          getLogs(params) {
            this.lastRequest = params;

            return this.getEntries(params);
          }

          getEntries(params, extraFilters) {
            const entries = this.gaeRequest(params, extraFilters);

            return entries.then((response) => {
              if (response.data && response.data.entries) {
                this.lastEntry = response.data.entries[0];

                return this.parserFunction(response.data.entries);
              }

              return [];
            });
          }

          pollLogs() {
            let filters = '';
            if (this.lastRequest.filters) {
              filters = `(${this.lastRequest.filters})\n`;
            }

            delete this.pageSize;

            if (this.lastEntry) {
              const timestamp = this.lastEntry.timestamp;
              const insertid = this.lastEntry.insertId;

              filters += `AND (timestamp > "${timestamp}" OR \n` +
                         `(timestamp = "${timestamp}" insertId > "${insertid}"))\n`;
            }

            return this.getEntries(this.lastRequest, filters);
          }
        };
      }
    }

    return new TestLabLogs();
  },
]);
