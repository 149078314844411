const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('hubListController', [
  '$q',
  '$scope',
  'polling',
  'auth',
  'Connector',
  'Hub',
  'FederatedConnector',
  function hubListController(
    $q,
    $scope,
    polling,
    auth,
    Connector,
    Hub,
    FederatedConnector
  ) {
    $scope.installation = {};
    $scope.user = auth.user;

    function makeStatusGetters() {
      function getIcon(s) {
        const icons = {
          ONLINE: 'check_circle',
          OFFLINE: 'warning',
          AUTH_ERROR: 'warning',
        };

        return icons[s];
      }

      function getNgClass(s) {
        const style = {
          ONLINE: 'md-primary',
        };

        return style[s] || 'md-warn';
      }

      return [
        (hub) => {
          const agentStatus = hub.agentState();

          return {
            label: 'Monitoring Agent',
            icon: getIcon(agentStatus),
            ngClass: getNgClass(agentStatus),
            rawStatus: agentStatus,
          };
        },
        (hub) => {
          const hubStatus = hub.hubState();
          let version;

          if (hubStatus === 'ONLINE') {
            version = hub.version_oa;
          }

          return {
            label: 'Odin Automation',
            icon: getIcon(hubStatus),
            ngClass: getNgClass(hubStatus),
            rawStatus: hubStatus,
            version,
          };
        },
      ];
    }

    $scope.statusGetters = makeStatusGetters();

    function getHubs() {
      return Hub.query().$promise;
    }

    function checkApprovedFMConns() {
      return FederatedConnector.query({
        status__in: [
          FederatedConnector.$status.APPROVED,
          FederatedConnector.$status.INSTALLING,
          FederatedConnector.$status.ERROR,
        ],
      }).$promise;
    }

    function poll() {
      return $q.all({
        hubs: getHubs(),
        fmApproved: checkApprovedFMConns(),
      });
    }

    function checkHubs() {
      return poll()
        .then((r) => {
          $scope.hubs = r.hubs;
          $scope.approvedFMConns = r.fmApproved;
          $scope.statusGetters = makeStatusGetters();
        });
    }

    $scope.promise = poll().then((r) => {
      $scope.hubs = r.hubs;
      $scope.approvedFMConns = r.fmApproved;
      polling.add(checkHubs);
    });

    $scope.addHub = () => {
      Hub.create({
        company: auth.user.companies[0].resource_uri,
      }).$promise.then((i) => {
        $scope.hubs.push(i);
      });
    };

    $scope.getHubConns = (hub) =>
      Connector.instanceConns({
        instanceId: hub.agent_key,
      });

    function getAppKey(hub, hubApp) {
      return `${hub.agent_key}-${hubApp.id}`;
    }

    $scope.installationInProgress = (hub, hubApp) =>
      $scope.installation[getAppKey(hub, hubApp)];

    $scope.installApp = (hub, hubApp) => {
      if ($scope.installationInProgress(hub, hubApp)) {
        return;
      }

      $scope.installation[getAppKey(hub, hubApp)] = true;

      Hub.installApp({
        id: hub.agent_key,
      }, {
        app_id: hubApp.id,
      }).$promise.finally(() => {
        $scope.installation[getAppKey(hub, hubApp)] = false;
      });
    };

    $scope.trackingHub = (hub) => {
      const conns = [];

      angular.forEach(hub.apps, (item) => {
        conns.push(item.id);
      });

      return `_${hub.agent_key}${hub.status}${conns.join(',')}`;
    };
  },
]);
