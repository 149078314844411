const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('idSuggestion', [
  '$q',
  '$rootScope',
  'asyncValidatorFactory',
  'Connector',
  function idSuggestion(
    $q,
    $rootScope,
    asyncValidatorFactory,
    Connector
  ) {
    return asyncValidatorFactory({
      name: 'idSuggestion',
      validate(title) {
        $rootScope.$broadcast('connector:idSuggestionRequested');

        return $q((resolve) => {
          Connector.idSuggestion({ title }, ({ id }) => {
            $rootScope.$broadcast('connector:idSuggestion', id);

            resolve();
          }, resolve);
        });
      },
    });
  },
]);
