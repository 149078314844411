const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('StateModel', [
  '$resource',
  'config',
  'baseModel',
  function StateModel(
    $resource,
    config,
    Base
  ) {
    class State extends Base {
      /*  istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/state/:id/';

        return class extends this.factory() {
          init() {
            this.state = {
              NONE: 0,
              IN_PROGRESS: 1,
              FAIL: 2,
              OK: 3,
            };
          }

          getStates() {
            return this.state;
          }

          checkStatus() {
            return _.findKey(this.state, val => val === this.status);
          }
        };
      }
    }

    return new State();
  },
]);
