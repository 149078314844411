const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('FakeData', [
  '$resource',
  'config',
  'baseModel',
  function FakeDataModel(
    $resource,
    config,
    Base
  ) {
    class FakeData extends Base {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/fake-data/';

        this.actions = _.extend({}, this.actions, {
          generate: {
            method: 'POST',
            url: config.apiBaseUrl + this.url,
          },
        });

        return this.factory();
      }
    }

    return new FakeData();
  },
]);
