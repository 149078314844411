const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connEditController', [
  '$scope',
  '$stateParams',
  '$controller',
  '$state',
  '$q',
  '$mdToast',
  '$log',
  '$timeout',
  'polling',
  'Connector',
  'ConnectorPreview',
  'ConnectorPublication',
  'Company',
  'StateModel',
  'convert',
  'stateResult',
  'auth',
  'mirror',
  function connEditController(
    $scope,
    $stateParams,
    $controller,
    $state,
    $q,
    $mdToast,
    $log,
    $timeout,
    polling,
    Connector,
    ConnectorPreview,
    ConnectorPublication,
    Company,
    StateModel,
    convert,
    stateResult,
    auth,
    mirror
  ) {
    const parent = $controller('statusControllerMixin', {
      $scope,
      $stateParams,
      $state,
      $q,
      stateResult,
      auth,
      polling,
    });

    angular.extend(this, parent);

    const deletedCache = {};

    $scope.stateResult = stateResult;
    $scope.user = auth.user;

    $scope.preview = new ConnectorPreview({
      status: -1,
    });

    $scope.usage = $scope.versionDeployment = new StateModel({
      status: 0,
    });

    $scope.publication = new ConnectorPublication({
      status: -1,
    });

    $scope.logo = {
      bgColor: `rgb(${[255, 255, 255]})`,
      uploadInProgress: false,
      maxSize: '2MB',
    };

    $scope.modelOptions = {
      debounce: {
        default: 300,
        blur: 0,
      },
      updateOn: 'default blur',
    };

    $scope.showContent = false;

    $scope.currentVersion = (state) => state === $scope.versions.current.id;

    $scope.checkVersions = () => {
      const enableVersionsState = [
        'isv.connector.edit.profile',
        'isv.connector.edit.model',
        'isv.connector.edit.ui',
        'isv.connector.edit.api',
        'isv.connector.edit.versions',
      ];

      if (_.get($scope.versions, 'list') && _.includes(enableVersionsState, $state.current.name)) {
        return $scope.versions.list.length > 1;
      }

      return false;
    };

    $scope.versionGoToMaster = () => {
      if (!_.isUndefined($scope.conn.master) && !$scope.conn.master) {
        const master = _.find($scope.versions.list, { master: true });
        const conn = $scope.changeVersion(master);
        $scope.loadingIndicator(conn.$promise);
      }

      return $scope.conn.$promise;
    };

    $scope.companyName = (uri) => {
      const company = _.find($scope.companies, { resource_uri: uri });

      return company;
    };

    function uploadLogo(file, holder = false) {
      $scope.logo.uploadInProgress = true;
      $scope.conn.$meta.logo = file;
      $scope.conn.logo_placeholder = holder;

      if (!file.name) {
        file.name = 'logo.png';
      }

      return $scope.connSaver()
        .then(() => $scope.conn.saveLogo())
        .then((connector) => {
          if (!holder) {
            $scope.conn.logo = connector.logo;
          }
        })
        .catch(() => {
          const toast = $mdToast
            .simple()
            .textContent('Something went wrong during logo upload')
            .position('bottom right')
            .hideDelay(5000)
            .theme('customTheme');

          $mdToast.show(toast);
        })
        .finally(() => {
          $scope.logo.uploadInProgress = false;
          $scope.$emit('logo:resetFile');
        });
    }

    $scope.$on('placeholder:change', (event, uri) => {
      $scope.conn.$promise.then(() => {
        convert.dataURItoPng(uri).then((png) => {
          uploadLogo(png, true);
        });
      });
    });

    $scope.$on('logo:invalid', (event, file) => {
      if (file.$error) {
        const errorMessages = {
          maxSize: `Please upload logos no more than ${$scope.logo.maxSize}`,
        };
        const message = errorMessages[file.$error] || `Invalid image: ${file.$error}`;

        $mdToast.show(
          $mdToast
            .simple()
            .position('bottom right')
            .textContent(message)
            .hideDelay(5000)
            .theme('customTheme')
        );
      }
    });

    $scope.$on('logo:change', (event, file) => {
      if (!file) {
        return;
      }

      uploadLogo(file);
    });

    function generateMenu(conn) {
      $scope.navigation = [];

      if (!conn.fm_type) {
        $scope.navigation.push({
          states: [{ id: 'isv.connector.edit.guide' }],
        });

        $scope.navigation.push({
          type: 'integration',
          states: [
            { id: 'isv.connector.edit.profile' },
          ],
        });

        $scope.navigation[1].states.push(...[
          { id: 'isv.connector.edit.model' },
          { id: 'isv.connector.edit.ui' },
          { id: 'isv.connector.edit.api' },
        ]);

        $scope.navigation[1].title = () => 'Integration';

        if (conn.master) {
          $scope.navigation[1].states.push(...[
              { id: 'isv.connector.edit.preview' },
              { id: 'isv.connector.edit.publish' },
          ]);

          $scope.navigation.push({
            title: () => 'Operations',
            type: 'extra',
            states: [
              { id: 'isv.connector.edit.versions' },
              { id: 'isv.connector.edit.providers.list', parent: 'providers' },
              { id: 'isv.connector.edit.installations', parent: 'installations' },
            ],
          });
        }

        if (conn.sandbox) {
          $scope.navigation.push({
            title: () => 'Simulator',
            type: 'sandbox',
            states: [
              { id: 'isv.connector.edit.labDetails' },
              { id: 'isv.connector.edit.labApiLogs' },
              { id: 'isv.connector.edit.labServiceLogs' },
            ],
          });
        }

        if (conn.master) {
          $scope.navigation.push({
            states: [
              { id: 'isv.connector.edit.settings' },
            ],
          });
        }
      } else {
        $scope.navigation.push({
          type: 'integration',
          states: [
            { id: 'isv.connector.edit.profile' },
          ],
        });
      }

      $scope.showContent = true;
    }

    $scope.changeVersion = (version) => {
      const params = { connId: $stateParams.connId };
      if (!version.master) {
        params.version = version.release;
      }

      const conn = Connector.get(params);
      conn.$promise.then(updatedConn => ($scope.conn = updatedConn));
      $scope.conn.$promise = conn.$promise;

      $scope.versions.current = version;
      $scope.$broadcast('version:change', { id: version });
      generateMenu(version);

      return conn;
    };

    $scope.changeVersionHandler = (version) => {
      const conn = $scope.changeVersion(version);
      $scope.loadingIndicator(conn.$promise);
    };

    $scope.loadingIndicator = (promise) => {
      $scope.$broadcast('loadingIndicator:start');

      return $q.all([$timeout(_.noop, 500), promise])
        .then((res) => res[1])
        .finally(() => $scope.$broadcast('loadingIndicator:stop'));
    };

    $scope.conn = Connector.get({ connId: $stateParams.connId });

    $scope.parentPromise = $q.all({
      conn: $scope.conn.$promise,
      companies: Company.query().$promise,
      versions: Connector.getVersions({ connId: $stateParams.connId }).$promise,
    }).then((r) => {
      $scope.companies = r.companies;
      $scope.pageTitle = () => $scope.conn.title;

      $scope.versions = {
        list: _.concat(r.conn, _.orderBy(r.versions, ['release'], ['desc'])),
        current: _.assign({ name: 'Master' }, r.conn),
      };

      $scope.versions.list = _.transform($scope.versions.list, (result, val, key) => {
        let name = `Version ${val.release}`;
        if (val.master) {
          name = 'Master';
        }
        result[key] = _.assign({ name }, val);
      });

      generateMenu(r.conn);
    }).catch((error) => {
      $log.error(`Failed to get data: ${error.statusText}` +
        `Response code is ${error.status}`);
    });

    $scope._save = function _save(saver) {
      const toast = $mdToast
        .simple()
        .position('bottom right');

      const ok = _.throttle(_.noop, 2000, {
        leading: true,
        trailing: false,
      });

      const error = (e) => {
        const p = $mdToast.show(
            toast
              .action('Retry')
              .highlightAction(true)
              .content('Saving changes failed')
              .theme('customTheme')
          );

        p.then((response) => {
          if (response === 'ok') {
            saver().then(_.noop, error);
          }
        });

        $log.error(e);
      };

      return saver().then(ok, error);
    };

    $scope._delete = function _delete(deleter, undoer, contentGetter) {
      let toast;

      function del() {
        const data = deleter();
        const key = data.obj.id;

        deletedCache[key] = data;

        toast = $mdToast
          .simple()
          .content(contentGetter(data.obj))
          .action('Undo')
          .highlightAction(true)
          .position('bottom right')
          .theme('customTheme');

        data.delete(data.obj);
        delete deletedCache[key];

        return $mdToast.show(toast).then((response) => {
          if (response === 'ok') {
            return undoer(data.obj);
          }

          return $q.when();
        });
      }

      return del();
    };

    $scope.connSaver = () => {
      const saver = () => $scope.conn.$update({ connId: $scope.conn.id });

      return $scope._save(saver);
    };

    if ($state.current.name === 'isv.connector.edit') {
      $state.go('isv.connector.edit.guide');
    }

    $scope.hasUserService = () => _($scope.conn.resources)
      .filter({ unit: 'users' })
      .size() > 0;

    $scope.userProfiles = () => _($scope.conn.resources)
      .filter({ unit: 'users' })
      .map('title')
      .join(', ');

    $scope.getConfiguration = () => $scope.conn.getConfiguration();
    $scope.getSandboxStatus = () => Connector.getSandboxStatus({
      connId: $stateParams.connId,
    }).$promise;

    $scope.checkSandbox = () =>
      Connector.getSandboxStatus({ connId: $stateParams.connId }).$promise
        .then((response) => {
          $scope.sandbox = response;
          if (response.status !== $scope.conn.sandboxStatus.INSTALLING) {
            if (!_.isUndefined($scope.statusChecks.sandboxReady)
                && response.status === $scope.conn.sandboxStatus.INSTALLED) {
              $scope.conn.getConfiguration().then((conn) => {
                $scope.conn.configuration = conn;
                $scope.statusChecks.sandboxReady = true;
                $scope.$broadcast('connector:sandboxReady');
              });
            }

            polling.stop('sandbox');
          }

          return response;
        });

    $scope.getPreview = $scope.check.checkFactory('preview', ConnectorPreview, {
      limit: 1,
      order_by: '-created',
      types: $scope.preview.$types.VENDOR,
    });

    $scope.collectUsage = $scope.check.checkFactory('usage', ConnectorPreview, {
      limit: 1,
      order_by: '-created',
      types: $scope.preview.$types.VENDOR,
    }, 'usage_refresh');

    $scope.checkCollectUsageStatus = $scope.check.checkItemFactory('usage',
      $scope.usage.state.IN_PROGRESS,
      3000,
      'collectUsage'
    );

    $scope.deployVersion = $scope.check.checkFactory('versionDeployment',
      ConnectorPublication, {}, 'publication_status'
    );

    $scope.checkDeployVersion = $scope.check.checkItemFactory('versionDeployment',
      $scope.versionDeployment.state.IN_PROGRESS,
      5000,
      'deployVersion'
    );

    $scope.getPublication = $scope.check.checkFactory('publication', ConnectorPublication);

    $scope.checkPreviewStatus = $scope.check.checkItemFactory('preview',
      $scope.preview.state.IN_PROGRESS,
      5000,
      'getPreview'
    );
    $scope.checkPublicationStatus = $scope.check.checkItemFactory('publication',
      $scope.publication.state.IN_PROGRESS,
      5000,
      'getPublication'
    );
    $scope.checkSandboxStatus = $scope.check.checkItemFactory('sandbox',
      $scope.conn.sandboxStatus.INSTALLING,
      5000,
      'checkSandbox'
    );

    $scope.clearPollTimeouts = () => {
      polling.stop();
    };

    $scope.sandboxIsReady = $scope.check.statusFactory('sandbox',
      $scope.conn.sandboxStatus.INSTALLED);
    $scope.sandboxIsInstalling = $scope.check.statusFactory('sandbox',
      $scope.conn.sandboxStatus.INSTALLING);
    $scope.sandboxIsFailed = $scope.check.statusFactory('sandbox',
      $scope.conn.sandboxStatus.ERROR);

    $scope.stateSandboxStatus = () => {
      $scope.getSandboxStatus().then($scope.checkSandboxStatus);
    };

    mirror({
      generateMenu,
      uploadLogo,
    }, $scope);
  },
]);
