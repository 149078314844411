const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('asyncValidatorFactory', [
  '$q',
  '$timeout',
  function asyncValidatorFactory(
    $q,
    $timeout
  ) {
    return (obj) => {
      const defaults = {
        emptyOk: true,
        name: 'toBeOverriden',
        validate: () => $q.when(),
        delay: 0,
      };

      const control = _.extend({}, defaults, obj, {
        require: 'ngModel',
        link(scope, elm, attrs, ctrl) {
          ctrl.$asyncValidators[control.name] = (modelValue) => {
            if (control.emptyOk && ctrl.$isEmpty(modelValue)) {
              return $q.when();
            }

            if (!control.emptyOk && ctrl.$isEmpty(modelValue)) {
              return $q.reject();
            }

            return $timeout(_.noop, control.delay)
              .then(() => control.validate(modelValue, scope, ctrl));
          };
        },
      });

      return control;
    };
  },
]);
