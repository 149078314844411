const angular = require('angular');


const app = angular.module('sweeft-spa');


const _ = require('lodash');


app.controller('invitationController', [
  '$scope',
  '$rootScope',
  '$state',
  '$stateParams',
  '$log',
  '$mdToast',
  '$mdDialog',
  '$q',
  'Invitation',
  'Connector',
  'auth',
  function invitationController(
    $scope,
    $rootScope,
    $state,
    $stateParams,
    $log,
    $mdToast,
    $mdDialog,
    $q,
    Invitation,
    Connector,
    auth
  ) {
    const appId = $stateParams.app;
    const code = $stateParams.code;
    const checksCodeSucceed = (res) => {
      $scope.isValid = true;
      $scope.invitation.id = res.id;
      $scope.connector = res.connector;
    };
    const checksCodeFailed = (err) => {
      $log.error(err);
      $scope.isValid = false;
    };
    const checksCodeCompleted = () => {
      if ($scope.user.isProvider && !$scope.user.companies[0]) {
        $mdDialog
          .show(
            $mdDialog.alert()
              .theme('customTheme')
              .clickOutsideToClose(true)
              .title('You do not belong to a company')
              .htmlContent(
                `You will be now redirected to a page were you can create a company.
                  After that you will be able to use your invitation link once again.`
              )
              .ok('Ok')
          )
          .then(() => {
            $state.go('company.list');
          });
      }
    };
    const verifyInvitationCode = (invitationCode) => (
      Invitation.verify(invitationCode).$promise
        .then(checksCodeSucceed)
        .catch(checksCodeFailed)
        .finally(checksCodeCompleted)
    );

    $scope.user = auth.user;

    $scope.invitation = new Invitation();

    $scope.isValid = true;
    $scope.promise = Connector.query().$promise
      .then((conns) => {
        const hasConnector = _.find(conns, { id: appId });

        if ($scope.user.isProvider) {
          if (!hasConnector) {
            return verifyInvitationCode(code);
          }

          $mdToast.show(
            $mdToast
              .simple()
              .position('bottom right')
              .textContent('You are already assigned for this connector.')
              .hideDelay(5000)
              .theme('customTheme')
          );
          $state.go('provider.connector.edit.profile', { connId: appId });
        }

        if ($scope.user.isVendor) {
          if (hasConnector) {
            return verifyInvitationCode(code);
          }

          $scope.isValid = false;
        }

        return $q.when();
      });

    $scope.assign = () => {
      if (auth.user.isVendor) {
        $state.go('provider.connector.edit.profile', { connId: appId });

        return;
      }

      $scope.invitation.assign($scope.user.companies[0].resource_uri, code)
        .then(() => {
          $state.go('provider.connector.edit.profile', { connId: appId });
        })
        .catch((err) => {
          const message = `Acception caused error (${err.status})`;

          $log.error(err);

          $mdDialog.show(
            $mdDialog
              .alert()
              .theme('customTheme')
              .clickOutsideToClose(true)
              .content(message)
              .ok('OK')
          );
        });
    };

    $scope.ignore = () => {
      if (auth.user.isVendor) {
        $state.go('isv.connector.list');
      } else {
        $state.go('provider.connector.list');
      }
    };
  },
]);

