const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('changeDeleteAccountController', [
  '$scope',
  '$state',
  'helpers',
  'User',
  'auth',
  function changeDeleteAccountController(
    $scope,
    $state,
    helpers,
    User,
    auth
  ) {
    $scope.helper = helpers;

    const success = () => {
      $scope.helper.process = false;
      $scope.helper.hide();
      auth.clearCredentials();

      return auth.logout().then(() => {
        $state.go('login');
      });
    };

    const error = (user) => {
      $scope.helper.process = false;
      $scope.passwordIncorrect = user.data.error;
      $scope.deleteAccountForm.password.$setValidity('password', false);
    };

    $scope.passwordChange = () => {
      $scope.deleteAccountForm.password.$setValidity('password', true);
    };


    $scope.deleteAccount = () => {
      if ($scope.helper.process || $scope.deleteAccountForm.$invalid) {
        return;
      }

      $scope.helper.process = true;

      User
        .deleteAccount({ password: $scope.password })
        .$promise
        .then(success)
        .catch(error);
    };
  },
]);
