const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('visited', [
  function visited() {
    return {
      restrict: 'A',
      require: 'ngModel',
      link(scope, element, attrs, ngModel) {
        ngModel.$setDirty();
        ngModel.$setTouched();
      },
    };
  },
]);
