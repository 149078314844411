const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connEditModelController', [
  '$scope',
  '$q',
  '$resource',
  '$rootScope',
  'mirror',
  'UIResource',
  'ConnectorResource',
  function connEditModelController(
    $scope,
    $q,
    $resource,
    $rootScope,
    mirror,
    UIResource,
    ConnectorResource
  ) {
    $scope.addResource = () => {
      const resource = {
        type: 'COUNTER',
        unit: 'unit',
        title: '',
        application: $scope.conn.resource_uri,
      };

      return new ConnectorResource(resource);
    };

    const preview = $scope.getPreview();
    const publication = $scope.getPublication();
    const sandbox = $scope.getSandboxStatus();

    $scope.units = ConnectorResource.units();
    $scope.promise = $q.all([
      preview,
      publication,
      sandbox,
      $scope.units.$promise,
      $scope.conn.$promise,
    ]);

    sandbox.then($scope.checkSandboxStatus);
    publication.then($scope.checkPublicationStatus);
    preview.then($scope.checkPreviewStatus);

    $scope.canChange = (res) => {
      if (!res) {
        return $scope.conn.master;
      }

      return $scope.statusChecks.previewReady
        && $scope.statusChecks.publicationReady
        && !res.frozen && $scope.conn.master;
    };

    $scope.resourceSaver = (res) => $scope._save(() => {
      let resource;

      if (!_.isArray(res)) {
        resource = [res];
      }

      function saveOrUpdateResource(r) {
        if (r.id) {
          return r.$update({ id: r.id });
        }

        return r.$save();
      }

      return $q.all(_.map(resource, saveOrUpdateResource));
    });

    $scope.deleteResource = (r, after) => $scope._delete(() => ({
      obj: r,
      delete: () => {
        const resource = new ConnectorResource(r);

        if (!resource.id) {
          return $q.when(r);
        }

        return resource.$delete({ id: resource.id });
      },
    }),
    () => {
      const res = new ConnectorResource(r);

      if (!res.id || !res.isValid()) {
        return $q.when(r);
      }

      return res.$save()
        .then(() => UIResource.sort({ id: r.id, after }, {}).$promise);
    },
    (res) => {
      let title = '';

      if (_.isString(res.title) && res.title.length > 0) {
        title = `'${res.title}' `;
      }

      return `Resource ${title}deleted`;
    });

    $scope.resourceHandler = () => ({
      placeholder: 'RESOURCE_ID',
      type: 'Unit',
      label: 'Resource ID',
      modelOptions: $scope.modelOptions,
      filter: { type: '!SUBSCRIPTION_SERVICE' },
      add: $scope.addResource,
      units: $scope.units,
    });

    $scope.userServiceEnabled = () => ($scope.hasUserService() ? 'ON' : 'OFF');

    mirror({
      preview,
      publication,
      sandbox,
    }, $scope);
  },
]);
