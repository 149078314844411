const angular = require('angular');


const app = angular.module('sweeft-spa');


app.service('dialog', [
  '$mdDialog',
  function dialogService(
    $mdDialog
  ) {
    return {
      show(text, config = {}) {
        let alert = $mdDialog.alert();

        if (config.parent) {
          alert = alert.parent(config.parent);
        }

        alert = alert
          .theme(config.theme || 'customTheme')
          .clickOutsideToClose(true)
          .content(text)
          .ok(config.okText || 'Got it!');

        if (config.event) {
          alert = alert.targetEvent(config.event);
        }

        return $mdDialog.show(alert);
      },
      confirm(text, config = {}) {
        let confirm = $mdDialog.confirm();

        if (config.parent) {
          confirm = confirm.parent(config.parent);
        }

        confirm = confirm
          .theme(config.theme || 'customTheme')
          .clickOutsideToClose(true)
          .title(config.title || '')
          .textContent(text)
          .ok(config.yes || 'Yes')
          .cancel(config.no || 'No');

        return $mdDialog.show(confirm);
      },
    };
  },
]);
