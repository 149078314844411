const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connNewController', [
  '$q',
  '$scope',
  '$mdDialog',
  '$state',
  '$log',
  'auth',
  'Connector',
  function connNewController(
    $q,
    $scope,
    $mdDialog,
    $state,
    $log,
    auth,
    Connector
  ) {
    $scope.conn = {};
    $scope.connNamePattern = /^(?!\-)[A-z0-9 \-]+$/;
    $scope.modelOptions = {
      debounce: {
        default: 300,
        blur: 0,
      },
      updateOn: 'default blur',
    };

    function dialog(text, $event) {
      const parent = angular.element(document.querySelector('#newConnCard'));

      return $mdDialog.show(
        $mdDialog
        .alert()
        .parent(parent)
        .clickOutsideToClose(true)
        .theme('customTheme')
        .content(text)
        .ok('Got it!')
        .targetEvent($event)
      );
    }

    if (!auth.user.companies || !auth.user.companies.length) {
      $state.go('isv.connector.list');
    } else {
      $scope.companies = auth.user.companies;
    }

    $scope.idSuggestionProcessing = false;

    $scope.$on('connector:idSuggestionRequested', () => {
      $scope.idSuggestionProcessing = true;
    });

    $scope.$on('connector:idSuggestion', ($event, id) => {
      $scope.conn.id = id;
      $scope.idSuggestionProcessing = false;
    });

    $scope.nameIsInvalid = () => {
      const control = _.get($scope, 'connForm.connName');
      let error;

      if (!control) return false;

      if (control.$touched) {
        error = control.$error;
      } else {
        error = _.omit(control.$error, ['required']);
      }

      return !_.isEmpty(error) && _(error).values().every();
    };

    $scope.idAvailable = () => {
      if (!$scope.nameIsInvalid() && $scope.conn.id) {
        return true;
      }

      return false;
    };

    $scope.createNewConnector = ($event) => {
      if ($scope.processing || $scope.idSuggestionProcessing) {
        return;
      }

      if (!$scope.connForm.$valid) {
        $event.preventDefault();
        return;
      }

      if ($scope.companies.length === 1) {
        $scope.conn.company = $scope.companies[0].resource_uri;
      }

      $scope.processing = true;

      Connector.generate($scope.conn).$promise
        .then((data) => {
          $state.go('isv.connector.edit.guide', {
            connId: data.id,
          });
          $scope.cancel();
        })
        .catch((error) => {
          $log.error(error);
          const errorMessage = _.get(error, 'data.error', 'Sorry, something went wrong');
          dialog(errorMessage, $event).then($scope.cancel);
        })
        .finally(() => {
          $scope.processing = false;
        });
    };

    $scope.cancel = () => $mdDialog.cancel();

    $scope.continue = () => {
      $mdDialog.hide();
    };
  },
]);
