const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('labServiceResellerController', [
  '$scope',
  'Reseller',
  function labServiceResellerController(
    $scope,
    Reseller
  ) {
    $scope.promise = $scope.conn.$promise.then(() => {
      Reseller.setToken($scope.conn.sandbox.token);
      $scope.resellers = Reseller.query({});

      return $scope.resellers.$promise;
    }).then((res) => {
      $scope.total = _.reduce(res, (acc, reseller) => (
        {
          storage: {
            usage: acc.storage.usage + reseller.storage.usage,
            limit: acc.storage.limit + reseller.storage.limit,
          },
        }
      ), { storage: { usage: 0, limit: 0 } });
    });
  },
]);
