const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('stateResult', [
  function stateResultFactory() {
    const stateResult = _.memoize((data) => {
      const regexp = /([^{]*)\n*(\{(?:[\S\s]*)\})?(?:[\S\s]*)/g;
      const match = regexp.exec(data);
      let json = _.nth(match, 2);
      let text = _.nth(match, 1).trim();

      try {
        JSON.parse(json);
      } catch (e) {
        json = undefined;
        text = data;
      }
      const feedbackReplaceText = '__feedbackDirections__';
      const feedback = _.includes(text, feedbackReplaceText);
      text = text.replace(feedbackReplaceText, '');

      return { text, json, feedback };
    });

    stateResult.clearCache = () => stateResult.cache.clear();

    return stateResult;
  },
]);
