const angular = require('angular');


const app = angular.module('sweeft-spa');


app.factory('ErrorResponseHandler', [
  '$q',
  '$injector',
  '$location',
  'UnauthorizedHandler',
  'ForbiddenHandler',
  'NotFoundHandler',
  function ErrorResponseHandler(
    $q,
    $injector,
    $location,
    UnauthorizedHandler,
    ForbiddenHandler,
    NotFoundHandler
  ) {
    return {
      responseError(response) {
        function handle(handler) {
          return handler(response, $injector.get);
        }

        const handlers = {
          401: UnauthorizedHandler,
          403: ForbiddenHandler,
          404: NotFoundHandler,
        };

        if (angular.isFunction(handlers[response.status])) {
          handle(handlers[response.status]);
        }

        return $q.reject(response);
      },
    };
  },
]);
