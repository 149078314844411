const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('auth', [
  '$log',
  '$q',
  '$http',
  '$cookies',
  '$state',
  'polling',
  'config',
  'Authentication',
  'User',
  'Company',
  'Analytics',
  'mirror',
  function authFactory(
    $log,
    $q,
    $http,
    $cookies,
    $state,
    polling,
    config,
    Authentication,
    User,
    Company,
    Analytics,
    mirror
  ) {
    const user = {};

    function defaultUser(u) {
      u.isAuthenticated = false;
      u.isVendor = false;
      u.isProvider = false;
      u.name = '';
      u.email = '';
      u.type = '';
      u.api_key = '';
      u.user_id = '';
      u.companies = [];
    }

    defaultUser(user);

    const auth = { user };
    auth.pollConfig = {
      interval: 30 * 1000, // Milliseconds
      threshold: 15 * 60, // Seconds
      notify: null,
      cancelNotify: null,
      _notified: false,
      _sessionTimeLeft: 0,
    };
    const userTypes = {
      vendor: 'isv',
      provider: 'isp',
    };

    auth.init = (userData) => {
      angular.copy(userData, user);
    };

    auth.isAuthenticated = () => user.isAuthenticated;

    function abortAuthTimeout() {
      if (auth.timeoutStatus) {
        polling.stopGlobalPolls();
        auth.timeoutStatus = false;
      }
    }
    mirror({ abortAuthTimeout }, auth);

    function checkSession() {
      return User.checkSession({}).$promise
        .then((session) => {
          auth.pollConfig._sessionTimeLeft = session.left;

          if (session.left <= auth.pollConfig.threshold &&
            _.isFunction(auth.pollConfig.notify) && !auth.pollConfig._notified) {
            auth.pollConfig._notified = true;

            auth.pollConfig
              .notify(() => auth.pollConfig._sessionTimeLeft)
              .then(() => {
                User.get({ pk: user.email });
              })
              .catch(() => {
                auth.logout().then(() => {
                  $state.reload(true);
                });
              })
              .finally(() => {
                auth.pollConfig._notified = false;
              });
          }
        })
        .catch(() => {
          abortAuthTimeout();
        });
    }

    mirror({ checkSession }, auth);

    auth.isAuthenticatedPoll = () => {
      if (auth.timeoutStatus || user.email === '') {
        return;
      }
      auth.setHeaders();

      auth.timeoutStatus = true;
      polling.add(checkSession,
        auth.pollConfig.interval,
        { alias: 'check-session', nostate: true });
    };

    auth.isFMPartner = () => !_(user.companies)
      .filter((company) => !_.isEmpty(company.partners))
      .isEmpty();

    auth.setRoles = () => {
      user.isVendor = user.type === userTypes.vendor;
      user.isProvider = user.type === userTypes.provider;
    };

    auth.setHeaders = () => {
      $http.defaults.headers.common.Authorization = `ApiKey ${user.email}:${user.api_key}`;
    };

    auth.setCredentials = () => {
      auth.setRoles();
      $cookies.putObject('user', user);

      if (auth.isAuthenticated()) {
        if (user.user_id) {
          Analytics.set('userId', user.user_id);
        }

        auth.isAuthenticatedPoll();
      }
    };

    auth.clearCredentials = () => {
      auth.cancelSessionExpireNotification();
      abortAuthTimeout();
      $http.defaults.headers.common.Authorization = 'ApiKey';

      if (user.user_id) {
        Analytics.set('userId');
      }

      $cookies.remove('user');
      defaultUser(user);
    };

    auth.fetchCompanies = () => Company
      .query({ email: auth.user.email }).$promise
      .then((companies) => {
        user.companies = companies;
        auth.setCredentials();

        return companies;
      });

    auth.login = (credentials) => Authentication.login(credentials).$promise
      .then((data) => {
        angular.copy(data, user);
        user.isAuthenticated = true;

        auth.setHeaders();
        auth.setCredentials();

        return auth.fetchCompanies();
      });

    auth.loginAs = (authData) => {
      user.email = authData.email;
      user.api_key = authData.api_key;
      user.isAuthenticated = true;

      auth.setHeaders();

      User.fetchInfo().$promise
        .then((info) => {
          if (info.objects[0]) {
            user.name = info.objects[0].name;
            user.type = info.objects[0].type;
            auth.setCredentials();
          }
        })
        .then(() => auth.fetchCompanies());
    };

    auth.logout = () => {
      user.isAuthenticated = false;

      return Authentication.logout().$promise
        .then(auth.clearCredentials);
    };

    auth.sessionPollConfig = (cnf) => {
      _.extend(auth.pollConfig, cnf);
    };

    auth.cancelSessionExpireNotification = () => {
      if (_.isFunction(auth.pollConfig.cancelNotify)) {
        auth.pollConfig.cancelNotify();
      }
    };

    return auth;
  },
]);
