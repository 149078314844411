const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('companyChangeNameController', [
  '$scope',
  '$state',
  '$mdDialog',
  '$mdToast',
  'company',
  'Company',
  function companyChangeNameController(
    $scope,
    $state,
    $mdDialog,
    $mdToast,
    company,
    Company
  ) {
    $scope.companyNamePattern = /^[A-z0-9'"‘’“”\ \-_,\.]+$/;

    $scope.helper = {
      cancel: $mdDialog.cancel,
      hide: $mdDialog.hide,
      process: false,
    };
    $scope.title = company.title;

    const success = (c) => {
      company.title = c.title;
      $scope.helper.hide();
    };

    const error = () => {
      $scope.helper.hide();
      const toast = $mdToast
        .simple()
        .textContent('Sorry, something went wrong')
        .position('bottom right')
        .hideDelay(5000)
        .theme('customTheme');

      $mdToast.show(toast);
    };

    $scope.changeName = () => {
      if ($scope.helper.process || $scope.changeNameForm.$invalid) {
        return;
      }

      $scope.helper.process = true;

      Company.updateCompany(
        { id: company.id },
        { title: $scope.title }
      ).$promise
        .then(success)
        .catch(error)
        .finally(() => ($scope.helper.process = false));
    };
  },
]);
