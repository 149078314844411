const angular = require('angular');
const _ = require('lodash');
const urljoin = require('url-join');


const app = angular.module('sweeft-spa');


app.factory('FederatedApi', [
  '$resource',
  '$q',
  'config',
  'Upload',
  'BucketCreds',
  'BaseFederatedPackage',
  function FederatedApiModel(
    $resource,
    $q,
    config,
    Upload,
    BucketCreds,
    BaseFederatedPackage
  ) {
    class FederatedApi extends BaseFederatedPackage {
      /* istanbul ignore next */
      constructor() {
        super();

        this.url = '/api/v1/federated-connector/:id/';

        this.actions = _.extend({}, this.actions);

        return class extends this.factory() {
          savePackage() {
            if (this.$meta.package) {
              return BucketCreds.get({ id: 'connector' }).$promise.then((creds) =>
                Upload.http({
                  headers: {
                    Authorization: `Bearer ${creds.access_token}`,
                    'Content-Type': 'application/x-zip',
                  },
                  method: 'PUT',
                  url: urljoin(creds.host, this.package),
                  data: this.$meta.package,
                })
              );
            }

            return $q.when();
          }
        };
      }
    }

    return new FederatedApi();
  },
]);
