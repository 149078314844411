const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connProviderInstallationsEditController', [
  '$scope',
  '$stateParams',
  '$mdDialog',
  '$mdToast',
  '$state',
  'InstallationRequest',
  function connProviderController(
    $scope,
    $stateParams,
    $mdDialog,
    $mdToast,
    $state,
    InstallationRequest
  ) {
    const request = InstallationRequest.get({ id: $stateParams.requestId });
    $scope.request = request;

    $scope.promise = request.$promise.then((req) => {
      const connector = req.app;

      $scope.connectorVersion = connector.master
        ? `Version ${connector.release - 1} (Always latest)`
        : `Version ${connector.release}`;
    });

    $scope.deleteRequest = ($event) => {
      if ($scope.deleting) {
        return;
      }

      $scope.deleting = true;

      const confirm = $mdDialog
        .confirm()
        .targetEvent($event)
        .theme('customTheme')
        .clickOutsideToClose(true)
        .title('Delete request')
        .textContent('Please confirm the operation. The request will be deleted.')
        .ok('Delete')
        .cancel('Cancel');

      $mdDialog.show(confirm)
        .then(() => InstallationRequest.delete({ id: $stateParams.requestId }).$promise)
        .then(() => $state.go('provider.connector.edit.installations.list'))
        .catch((err) => {
          const toast = $mdToast
            .simple()
            .textContent('Sorry, something went wrong')
            .position('bottom right')
            .hideDelay(5000)
            .theme('customTheme');

          if (err) $mdToast.show(toast);
        })
        .finally(() => ($scope.deleting = false));
    };

    $scope.reqIsPending = () => $scope.request.isStatus($scope.request.statuses.PENDING);
    $scope.reqIsApproved = () => $scope.request.isStatus($scope.request.statuses.APPROVED);
    $scope.reqIsRejected = () => $scope.request.isStatus($scope.request.statuses.REJECTED);

    $scope.promise.then(() => {
      $scope.installBackendCommand = 'apsconnect install-backend '
        + `--name ${$scope.request.app.id} `
        + `--image ${$scope.request.configuration.image} `
        + `config_file ${$scope.request.configuration.healthcheck_path} `
        + `${$scope.request.configuration.root_path} `
        + '[--namespace NAMESPACE] [--replicas REPLICAS] [--force FORCE]';

      $scope.installFrontendCommand = `apsconnect install-frontend ${$scope.request.aps_package} `
        + `${$scope.request.configuration.oauth_key} `
        + `${$scope.request.configuration.oauth_secret} `
        + '--backend-url BACKEND_URL [--settings-file SETTINGS_FILE] [--network NETWORK]';
    });
  },
]);
