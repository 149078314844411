const angular = require('angular');


const app = angular.module('sweeft-spa');


app.factory('ConnectionProblemHandler', [
  '$q',
  '$injector',
  function ConnectionProblemHandler(
    $q,
    $injector
  ) {
    return {
      responseError: (response) => {
        const currentState = $injector.get('$state').current;

        // Some states can handle errors themselves
        if (currentState && currentState.data && currentState.data.disableNetworkErrorToast) {
          return $q.reject(response);
        }

        if (!navigator.onLine || (response.status === -1 && response.statusText === '')) {
          const $mdToast = $injector.get('$mdToast');
          const toast = $mdToast
            .simple()
            .position('bottom right')
            .content('Check your network connection')
            .theme('customTheme');

          $mdToast.show(toast);
        }

        return $q.reject(response);
      },
    };
  },
]);
