const angular = require('angular');


const app = angular.module('sweeft-spa');


app.factory('MutateHeaderInterceptor', [
  '$q',
  function MutateHeaderInterceptor(
    $q
  ) {
    return {
      request(request) {
        const staticFilePattern = /\.(html|svg)$/;
        const googleApiPattern = /storage.googleapis.com/;

        if (staticFilePattern.test(request.url)) {
          delete request.headers.Authorization;
        }

        if (googleApiPattern.test(request.url)) {
          request.withCredentials = false;
        }

        return $q.resolve(request);
      },
    };
  },
]);
