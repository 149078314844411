const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('sandboxRedirectController', [
  '$scope',
  '$stateParams',
  '$window',
  'ClientUser',
  function sandboxRedirectController(
    $scope,
    $stateParams,
    $window,
    ClientUser
  ) {
    ClientUser.setToken($stateParams.token);

    ClientUser.login({
      email: $stateParams.email,
      resellerName: $stateParams.resellerName,
      clientName: $stateParams.clientName,
    }).$promise.then((res) => {
      $window.open(res.link, '_self');
    });
  },
]);
