const angular = require('angular');


const app = angular.module('sweeft-spa');


app.directive('hasFocus', [
  function hasFocus() {
    return {
      restrict: 'A',
      scope: {
        hasFocus: '=',
      },
      link(scope, element) {
        if (scope.hasFocus) {
          element.focus();
        }
      },
    };
  },
]);
