const angular = require('angular');


const app = angular.module('sweeft-spa');


app.config([
  '$httpProvider',
  ($httpProvider) => {
    $httpProvider.interceptors.unshift('ErrorResponseHandler');
    $httpProvider.interceptors.unshift('ConnectionProblemHandler');
    $httpProvider.interceptors.push('MutateHeaderInterceptor');
  },
]);
