const angular = require('angular');

const app = angular.module('sweeft-spa');

app.controller('hubController', [
  '$scope',
  '$state',
  'auth',
  function hubController(
    $scope,
    $state,
    auth
  ) {
    if (auth.user.isVendor) {
      $state.go('isv.connector.list');
    }
  },
]);
