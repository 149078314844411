const angular = require('angular');


const app = angular.module('sweeft-spa');


app.controller('connEditProvidersControllerNew', [
  '$log',
  '$scope',
  '$stateParams',
  '$q',
  'Invitation',
  function connEditProvidersControllerNew(
    $log,
    $scope,
    $stateParams,
    $q,
    Invitation
  ) {
    const sandbox = $scope.getSandboxStatus()
      .then($scope.checkSandboxStatus);

    $scope.conn.$promise.then(() => {
      $scope.invitation = new Invitation({
        app: $scope.conn.resource_uri,
        company_name: '',
        message: '',
      });
    });

    $scope.promise = $q.all([sandbox, $scope.conn.$promise]);

    $scope.updateName = () => {
      if ($scope.invitation.id) {
        $scope._save(() => $scope.invitation.$update({ id: $scope.invitation.id }));
      }
    };

    $scope.createInvitation = () => {
      $scope.processing = true;
      $scope._save(() => $scope.invitation.$save()
        .then((invite) => {
          $scope.go('isv.connector.edit.providers.edit', {
            invitationId: invite.id,
          });
        })
        .catch((error) => {
          $log.error(`Failed to create invitation: ${error.statusText}` +
            `Response code is ${error.status}`);
        })
        .finally(() => {
          $scope.processing = false;
        })
      );
    };
  },
]);
