const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');

app.controller('connEditSettingsController', [
  '$scope',
  '$q',
  'Connector',
  '$mdDialog',
  '$state',
  'auth',
  'mirror',
  function connEditSettingsController(
    $scope,
    $q,
    Connector,
    $mdDialog,
    $state,
    auth,
    mirror
  ) {
    const sandbox = $scope.getSandboxStatus();

    $scope.promise = $q.all([
      sandbox,
    ]);

    sandbox.then($scope.checkSandboxStatus);

    $scope.deleteConnector = (onClose) => Connector.delete({ connId: $scope.conn.id })
      .$promise
      .then(() => {
        if (auth.user.isVendor) {
          $state.go('isv.connector.list');
        } else {
          $state.go('provider.connector.list');
        }
      })
      .finally(() => {
        onClose();
      });

    $scope.isPublished = false;

    $scope.parentPromise.then(() => {
      $scope.isPublished = _.get($scope, 'versions.list', []).length !== 1;
    });

    function dialogController(scope, dlg) {
      scope.removing = false;

      scope.delete = () => {
        scope.removing = true;
        $scope.deleteConnector(() => {
          scope.removing = false;
          dlg.hide();
        });
      };

      scope.cancel = () => {
        dlg.hide();
      };
    }

    mirror({ dialogController }, $scope);

    $scope.removeConnector = (event) => {
      $mdDialog.show({
        clickOutsideToClose: true,
        targetEvent: event,
        controller: [
          '$scope',
          '$mdDialog',
          dialogController,
        ],
        templateUrl: 'dist/templates/dialogs/removeConnector.html',
      });
    };
  }]);
