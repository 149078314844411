const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.factory('NotFoundHandler', [
  function NotFoundHandler() {
    return function errorHandler(response, inject) {
      const $state = inject('$state');
      const excludeUrls = ['/api/v1/user/', '/api/v1/invitation/verify/'];
      const checkUrl = (url) => response.config.url.indexOf(url) !== -1;

      if (response.status === 404 && !_.some(excludeUrls, checkUrl)) {
        $state.go('notFound');
      }
    };
  },
]);
