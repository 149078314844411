const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('hubAddController', [
  '$q',
  '$scope',
  '$timeout',
  '$state',
  '$mdStepper',
  'Hub',
  'HubClient',
  'auth',
  function hubAddController(
    $q,
    $scope,
    $timeout,
    $state,
    $mdStepper,
    Hub,
    HubClient,
    auth
  ) {
    $scope.hostnameRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}$/;

    $scope.stepInfo = {
      busy: false,
      customized: false,
    };

    const urls = [
      {
        label: 'UI URL',
        apiKey: 'url_ui',
        value: '',
        default: (hostname) => `http://${hostname}:8080`,
      },
      {
        label: 'REST URL',
        apiKey: 'url_rest',
        value: '',
        default: (hostname) => `https://${hostname}:6308`,
      },
      {
        label: 'OpenAPI URL',
        apiKey: 'url_api',
        value: '',
        default: (hostname) => `http://${hostname}:8440`,
      },
    ];
    $scope.urls = urls;
    $scope.hubInfo = {};
    $scope.modelOptions = {
      debounce: {
        default: 300,
        blur: 0,
      },
      updateOn: 'default blur',
    };


    $scope.steps = [
      {
        n: 0,
        completed: false,
        isError: false,
        optional: false,
        data: {
          hostname: '',
          urls,
        },
        label: 'Hub details',
        template: 'hubDetails.html',
        submit: () => {
          _.forEach(urls, (val) => {
            val.error = '';
          });

          if (!$scope.stepInfo.customized) {
            $scope.fillDefaults(false);
          }
        },
      },
      {
        n: 1,
        completed: false,
        isError: false,
        optional: false,
        data: {
          urls,
        },
        label: 'Review',
        template: 'review.html',
        nextLabel: 'Confirm',
        submit() {
          if (this.completed) {
            return $q.when();
          }

          const hubCfg = _.fromPairs(
            _.zip(
              _.map(urls, 'apiKey'),
              _.map(urls, 'value')
            )
          );

          hubCfg.company = auth.user.companies[0].resource_uri;

          return Hub.create(hubCfg).$promise
            .then((result) => {
              _.find($scope.steps, { n: 0 }).hubCreated = true;
              $scope.hubInfo = result;
              $scope.installLine = `curl -sSL '${result.link}' | bash -s`;
            })
            .catch((res) => {
              this.isError = true;
              _.forEach(res.data, (val, key) => {
                _.find(urls, { apiKey: key }).error = val;
              });

              return res.data;
            });
        },
      },
      {
        n: 2,
        completed: false,
        isError: false,
        optional: false,
        data: {},
        label: 'Deploy agent',
        template: 'deployAgent.html',
        nextLabel: 'Activate',
        submit() {
          return HubClient.get({ name: $scope.hubInfo.agent_id }).$promise
            .then((res) => {
              $scope.agentAuthData = res.credentials;
            })
            .catch((res) => {
              $scope.agentAuthData = res.data.error;

              return res.data;
            });
        },
      },
      {
        n: 3,
        completed: false,
        isError: false,
        optional: false,
        data: {},
        label: 'Activate agent',
        template: 'activateAgent.html',
        nextLabel: 'Finish',
        submit: () => $state.go('hub.list'),
      },
    ];

    $scope.templateUrl = (step) => `dist/templates/provider/hub/addSteps/${step.template}`;
    $scope.formValid = () => $scope.stepperForm.$valid;
    $scope.nextButtonLabel = (step) => _.get(step, 'nextLabel', 'Continue');

    function enableNextStep() {
      if (!$scope.formValid()) {
        return;
      }

      const steppers = $mdStepper('add-hub-steps');
      steppers.next();
    }

    $scope.stepBack = (step) => {
      step.isError = false;
      const steppers = $mdStepper('add-hub-steps');
      steppers.back();
    };

    $scope.submitStep = (step) => {
      $scope.stepInfo.busy = true;

      $q.when(step.submit())
        .then((result) => {
          $scope.stepInfo.busy = false;
          if (!result) {
            step.completed = true;
            enableNextStep();
          }
        });
    };

    $scope.busyText = () => 'Processing...';

    $scope.fillDefaults = (customize) => {
      if (customize) {
        return;
      }

      const step = _.find($scope.steps, {
        n: 0,
      });
      let hostname;

      if (step.data.hostname) {
        hostname = step.data.hostname;
      } else {
        hostname = 'example.com';
      }

      _.forEach(urls, (url) => {
        url.value = url.default(hostname);
      });
    };
  },
]);
