const angular = require('angular');
const _ = require('lodash');

const app = angular.module('sweeft-spa');


app.controller('activateController', [
  '$scope',
  '$state',
  '$mdDialog',
  'auth',
  '$stateParams',
  'User',
  function activateController(
    $scope,
    $state,
    $mdDialog,
    auth,
    $stateParams,
    User
  ) {
    const formData = {
      activation_code: $stateParams.code,
    };

    $scope.forms = {
      actForm: null,
    };

    $state.go($state.current.name, { code: null, email: null }, { notify: false });

    $scope.formData = formData;
    $scope.processing = false;

    if (formData.activation_code) {
      User.verify(formData).$promise.then((data) => {
        $scope.email = data.email;

        auth.logout();
      }, () => {
        $mdDialog.show(
          $mdDialog
            .alert()
            .parent(angular.element(document.querySelector('#activationApp')))
            .theme('customTheme')
            .clickOutsideToClose(true)
            .textContent('Activation code is incorrect')
            .ok('Ok')
        ).then(() => {
          $state.go('login');
        });
      });
    }

    $scope.activateUser = () => {
      $scope.processing = true;

      return User.activate(formData).$promise.then((userData) => {
        userData.isAuthenticated = true;
        auth.init(userData);
        auth.setRoles();
        auth.setCredentials();

        if (_.get(userData, 'type') === 'isv') {
          return $state.go('isv.connector.list');
        }

        return $state.go('provider.connector.list');
      })
      .catch((res) => {
        $mdDialog.show(
          $mdDialog
          .alert()
          .parent(angular.element(document.querySelector('#activationApp')))
          .theme('customTheme')
          .clickOutsideToClose(true)
          .textContent(res.data.error || 'Sorry, something went wrong')
          .ok('Ok')
        );
        $scope.processing = false;
      });
    };

    $scope.submit = ($event) => {
      if ($scope.processing) {
        return;
      }

      if (!$scope.forms.actForm.$valid) {
        $mdDialog.show(
          $mdDialog
          .alert()
          .parent(angular.element(document.querySelector('#activationApp')))
          .theme('customTheme')
          .clickOutsideToClose(true)
          .content('Please fill in valid values')
          .ok('Got it!')
          .targetEvent($event)
        );

        return;
      }

      $scope.activateUser();
    };
  },
]);
