const angular = require('angular');
const _ = require('lodash');


const app = angular.module('sweeft-spa');


app.controller('connProviderPreviewController', [
  '$q',
  '$scope',
  '$stateParams',
  'Connector',
  'ConnectorResource',
  'Localization',
  '$controller',
  'colors',
  'auth',
  'preview',
  function connProviderPreviewController(
    $q,
    $scope,
    $stateParams,
    Connector,
    ConnectorResource,
    Localization,
    $controller,
    colors,
    auth,
    preview
  ) {
    const parent = $controller('previewController', {
      $scope,
      $stateParams,
      colors,
      Connector,
      previewFunc: Connector.providerPreview,
    });

    angular.extend(this, parent);

    const connPromise = $scope.conn.$promise
      .then(() => ConnectorResource.units().$promise)
      .then((data) => {
        _.forEach(data, (unit) => {
          $scope.units[unit.unit] = unit.description;
        });

        _.forEach($scope.conn.resources, (res, i) => {
          res.limit = res.name === 'DEVICES' ? 'unlimited' : _.get(res, 'limit', i * 100);
          res.unit_description = _.get($scope.units, res.unit.id);
        });
      });
    const previewPromise = $scope.getPreview();
    const locales = Localization.query({ app: $stateParams.connId }).$promise;

    $scope.promise = $q.all([previewPromise, connPromise, locales]);

    locales.then(locale => ($scope.locales = locale));

    $scope.user = auth.user;
    $scope.isPreviewMode = () => preview.isPreviewMode();
    $scope.showPreview = () => true;

    const PATH_REDIRECT = 'provider.connector.previewRedirect';
    $scope.ccpLink = (v) => `${PATH_REDIRECT}({ connId: conn.id, version: ${v}, locale })`;

    previewPromise.then($scope.checkPreviewStatus)
      .then(() => {
        if ($scope.user.isVendor) {
          $scope.preview.states.length = 0;
          $scope.preview.status = -1;
        }
      });

    $scope.$preview = {
      isInProgress: () => !$scope.statusChecks.previewReady
        || $scope.statusIs($scope.preview.state.IN_PROGRESS, 'preview'),
      isFailed: () => $scope.statusIs($scope.preview.state.PREVIEW_FAIL, 'preview')
        || $scope.statusIs($scope.preview.state.INSTALL_FAIL, 'preview'),
      isOK: () => $scope.statusChecks.previewReady
        && $scope.statusIs($scope.preview.state.OK, 'preview'),
    };

    $scope.startOver = () => $scope.$preview.isInProgress() || !$scope.enabledYaml;
  },
]);
